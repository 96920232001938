// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Category */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get Categories by store id
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.CategoryDto[]>} OK
 */
export function getCategories(options?: GetCategoriesOptions): Promise<api.Response<api.CategoryDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getCategoriesOperation, parameters);
}

/**
 * Get categories and their products by store ID and filter
 * 
 * @param {number} storeId 
 * @param {string} filters Filters as a stringified JSON object
 * @param {object} options Optional options
 * @param {boolean} [options.withProducts] Get category products if true
 * @return {Promise<module:types.CategoryDto[]>} OK
 */
export function getCategoriesByFilter(storeId: number, filters: string, options?: GetCategoriesByFilterOptions): Promise<api.Response<api.CategoryDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      storeId
    },
    query: {
      filters,
      withProducts: options.withProducts
    }
  };
  return gateway.request(getCategoriesByFilterOperation, parameters);
}

/**
 * Create a new category with provided information
 * 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<module:types.CategoryDto>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.CategoryDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Get list of Categories from database.
 */
export function all(): Promise<api.Response<api.Category[]>> {
  return gateway.request(allOperation);
}

/**
 * Update category with provided information
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<module:types.CategoryDto>} OK
 */
export function update(id: number, options?: UpdateOptions): Promise<api.Response<api.CategoryDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOperation, parameters);
}

/**
 * Delete category from database and Emmi
 * 
 * @param {number} id 
 * @return {Promise<object>} OK
 */
export function destroy(id: number): Promise<api.Response<undefined>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

/**
 * Get Category details from database.
 * 
 * @param {number} id 
 * @return {Promise<module:types.Category>} OK
 */
export function one(id: number): Promise<api.Response<api.Category>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(oneOperation, parameters);
}

/**
 * Get Category model schema describing the database table.
 */
export function schema(): Promise<api.Response<api.Category>> {
  return gateway.request(schemaOperation);
}

export interface GetCategoriesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface GetCategoriesByFilterOptions {
  /**
   * Get category products if true
   */
  withProducts?: boolean;
}

export interface CreateOptions {
  body?: {
    slug?: string,
    status?: 'hidden'|'public',
    pim_id?: string,
    locale?: any,
    metadata?: any,
    path?: string,
    restrict_visibility?: boolean,
    user_groups?: number[],
    append_visibility_to_children?: boolean,
    parent?: string,
    store_id: number
  };
}

export interface UpdateOptions {
  body?: {
    slug?: string,
    status?: 'hidden'|'public',
    pim_id?: string,
    locale?: any,
    metadata?: any,
    path?: string,
    restrict_visibility?: boolean,
    user_groups?: number[],
    append_visibility_to_children?: boolean,
    parent?: string,
    store_id: number
  };
}

const getCategoriesOperation: api.OperationInfo = {
  path: '/category/by-store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getCategoriesByFilterOperation: api.OperationInfo = {
  path: '/category/by-store-and-filter/{storeId}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/category',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/category',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOperation: api.OperationInfo = {
  path: '/category/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/category/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/category/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const schemaOperation: api.OperationInfo = {
  path: '/category/schema',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
