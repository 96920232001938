import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getOrder as getOrderByToken } from '@app/api/action/Order';
import { RootReducer, Store } from '@app/app.reducers';
import {
	FetchedOrder,
	getFetchedOrder,
	resetCheckoutFlow,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { hasApprovalWorkflow } from '@app/product/utils/product-utils';
import { DataLayer } from '@app/shared/services/data-layer.service';
import { SeoService } from '@app/shared/services/seo.service';
import { UUID_REGEX } from '@app/shared/utils/util';
import { getUserPermissions } from '@app/user/reducers/user.reducer';
import { firstValueFrom } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';

/** Order confirmed page */
@Component({
	selector: 'g-order-confirmed',
	templateUrl: './order-confirmed.component.html',
	styleUrls: ['./order-confirmed.component.scss'],
})
export class OrderConfirmedComponent implements OnInit {
	// TODO: Refactor to RemoteData.
	readonly order$ = this.store.select(getFetchedOrder).pipe(
		filter((order): order is FetchedOrder => !!order?.content && !!order?.isSuccess),
		map((order) => order.content!),
		tap((order) => {
			this.store.dispatch(resetCheckoutFlow(undefined));
			this.dataLayer.addPurchase(order);
		}),
		shareReplay(1), // Do not repeat tap.
	);
	readonly showPrices$ = this.store
		.select(getUserPermissions)
		.pipe(map((permissions) => permissions?.show_prices ?? true));

	readonly needsApproval$ = this.order$.pipe(
		map((order) => order.products.some((item) => hasApprovalWorkflow(item.product!))),
	);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly dataLayer: DataLayer,
		private readonly seo: SeoService,
	) {}

	/** Initialize order confirmed component */
	ngOnInit(): void {
		this.seo.setDirectly({ title: $localize`:@@SeoTitleOrderConfirmed:Order Confirmed` });

		/** Get access token either from URL params or query params */
		const accessToken =
			(this.route.snapshot.params.token as string) ||
			(this.route.snapshot.queryParams['checkout-stamp'] as string);

		if (typeof accessToken === 'string' && UUID_REGEX.test(accessToken)) {
			this.store.dispatch(getOrderByToken(accessToken));
		} else {
			void this.router.navigate(['/']);
		}

		// Navigate to home if order has errored.
		void firstValueFrom(
			this.store
				.select(getFetchedOrder)
				.pipe(filter((order) => (!order?.content && !!order?.isSuccess) || !!order?.isError)),
		).then(() => this.router.navigate(['/']));
	}
}
