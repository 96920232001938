<div class="select-content">
	<div class="select" [ngClass]="{ disabled: isDisabled }">
		<span class="label">{{
			!selectModel && placeholder ? placeholder : (selectModel | selectOptionLabel: selectButtonState)
		}}</span>
		<select
			[attr.aria-label]="ariaLabel"
			class="select-element form-group"
			[(ngModel)]="selectModel"
			[ngModelOptions]="{ standalone: true }"
			(ngModelChange)="updateSelectedOption($event)"
			[compareWith]="compareOptions"
			[disabled]="isDisabled"
			#contentSelect
		>
			<option *ngIf="placeholder" [ngValue]="undefined" disabled selected>{{ placeholder }}</option>
			<option *ngFor="let item of items" [ngValue]="item | selectOptionValue: bindKey">
				{{ item | selectOptionLabel: selectOptionState }}
			</option>
		</select>
	</div>
</div>
