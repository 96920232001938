// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Content */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get all pages
 * 
 * @param {string} locale 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} OK
 */
export function fetchPages(locale: string, options?: FetchPagesOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale
    }
  };
  return gateway.request(fetchPagesOperation, parameters);
}

/**
 * Get multiple Content in one request
 * 
 * @param {string} locale 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {string} [options.keys] Content keys, comma separated
 * @return {Promise<object>} An object with content by keys
 */
export function fetchMultipleContent(locale: string, options?: FetchMultipleContentOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale
    },
    query: {
      keys: options.keys
    }
  };
  return gateway.request(fetchMultipleContentOperation, parameters);
}

/**
 * Get Content
 * 
 * @param {string} locale 
 * @param {string} key 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.Content>} OK
 */
export function fetchContent(locale: string, key: string, options?: FetchContentOptions): Promise<api.Response<api.Content>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale,
      key
    }
  };
  return gateway.request(fetchContentOperation, parameters);
}

/**
 * Update or Create Content
 * 
 * @param {string} locale 
 * @param {string} key 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {module:types.Content} [options.content] 
 * @return {Promise<module:types.Content>} OK
 */
export function updateContent(locale: string, key: string, options?: UpdateContentOptions): Promise<api.Response<api.Content>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale,
      key
    },
    body: {
      content: options.content
    }
  };
  return gateway.request(updateContentOperation, parameters);
}

/**
 * Delete Content from database
 * 
 * @param {string} locale 
 * @param {string} key 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} Content deleted.
 */
export function deleteContent(locale: string, key: string, options?: DeleteContentOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      locale,
      key
    }
  };
  return gateway.request(deleteContentOperation, parameters);
}

export interface FetchPagesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface FetchMultipleContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  /**
   * Content keys, comma separated
   */
  keys?: string;
}

export interface FetchContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface UpdateContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  content?: api.Content;
}

export interface DeleteContentOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

const fetchPagesOperation: api.OperationInfo = {
  path: '/content/{locale}/pages',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const fetchMultipleContentOperation: api.OperationInfo = {
  path: '/content/{locale}/multiple',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const fetchContentOperation: api.OperationInfo = {
  path: '/content/{locale}/{key}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateContentOperation: api.OperationInfo = {
  path: '/content/{locale}/{key}',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const deleteContentOperation: api.OperationInfo = {
  path: '/content/{locale}/{key}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
