import { Component, OnInit } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { DialogBase } from '@app/dialog/dialog-base';

/** Confirmation dialog */
@Component({
	selector: 'g-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends DialogBase implements OnInit {
	public static MAX_WIDTH = '522px';
	public accept?: () => unknown;
	public reject?: () => unknown;
	public title = $localize`:@@ConfirmDialogTitle:Are you sure you want to proceed?`;
	public acceptLabel = $localize`:@@ConfirmDialogContinueButton:Continue`;
	public rejectLabel = $localize`:@@ConfirmDialogCancelButton:Cancel`;

	constructor(protected store: Store<RootReducer.State>) {
		super(store);
	}

	/** Setup dialog data */
	ngOnInit(): void {
		this.dialogData$.subscribe((inputData) => {
			const data = inputData as Record<string, unknown>;
			if (!data || typeof data !== 'object') return;
			if (typeof data.title === 'string') this.title = data.title;
			if (typeof data.acceptLabel === 'string') this.acceptLabel = data.acceptLabel;
			if (typeof data.rejectLabel === 'string') this.rejectLabel = data.rejectLabel;
			if (typeof data.accept === 'function') this.accept = data.accept as () => unknown;
			if (typeof data.reject === 'function') this.reject = data.reject as () => unknown;
		});
	}

	/** Accept button handler */
	acceptHandler(): void {
		if (this.accept) this.accept();
		this.closeDialog();
	}

	/** Reject button handler */
	rejectHandler(): void {
		if (this.reject) this.reject();
		this.closeDialog();
	}
}
