<div class="info" [ngSwitch]="faultCode">
	<ng-container *ngSwitchCase="faultCodes.NotFound">
		<h1 i18n="@@FaultPageServiceNotFoundHeader">Service not found</h1>
		<p>
			<span i18n="@@FaultPageServiceNotFoundText">Check the service URL or contact support at </span>
			<a href="mailto:info@mygrano.fi">info&#64;mygrano.fi</a>.
		</p>
	</ng-container>

	<ng-container *ngSwitchCase="faultCodes.UnsupportedBrowser">
		<h1 i18n="@@FaultPageBrowserUnsupportedHeader">Browser not supported</h1>
		<p i18n="@@FaultPageBrowserUnsupportedText">Update your browser to the latest version.</p>
	</ng-container>

	<ng-container *ngSwitchCase="faultCodes.PunchOutError">
		<h1 i18n="@@FaultPagePunchOutErrorHeader">Punch out failed</h1>
		<p i18n="@@FaultPagePunchOutErrorText">An error occurred during Punch Out setup process.</p>
	</ng-container>

	<ng-container *ngSwitchDefault>
		<h1 i18n="@@FaultPageUnderMaintenanceHeader">Under maintenance</h1>
		<p>
			<span i18n="@@FaultPageUnderMaintenanceText">You can contact support at </span>
			<a href="mailto:info@mygrano.fi">info&#64;mygrano.fi</a>.
		</p>
	</ng-container>
</div>
