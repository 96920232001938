import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'g-loading-spinner',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
	@Input() fixed: boolean;
	@HostBinding('class.is-fixed') isFixed: boolean;

	ngOnInit(): void {
		if (this.fixed) this.isFixed = true;
	}
}
