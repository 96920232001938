import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { DialogBase } from '@app/dialog/dialog-base';
import { DialogService } from '@app/dialog/services/dialog.service';
import { popErrorToast } from '@app/shared/reducers/toast.reducer';
import { CognitoService } from '@app/shared/services/cognito.service';
import { hasProp } from '@app/shared/utils/util';
import {
	getRequestingToken,
	getRequestingTokenSuccess,
	setUsername,
	reset,
} from '@app/user/reducers/registration.reducer';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'g-password-request-dialog',
	templateUrl: './password-request-dialog.component.html',
	styleUrls: ['./password-request-dialog.component.scss'],
})
export class PasswordRequestDialogComponent extends DialogBase implements OnInit, OnDestroy {
	form: UntypedFormGroup;
	isLoginLinkHidden: boolean;

	isRequestingToken$: Observable<boolean>;
	isRequestingTokenSuccess$: Observable<boolean>;

	formErrors = {
		username: '',
	};

	validationMessages: Record<string, Record<string, string>> = {
		username: {
			required: $localize`:@@PasswordRequestEmailRequired:Email is required.`,
			email: $localize`:@@PasswordRequestEmailValidity:Must be a valid email.`,
		},
	};

	constructor(
		protected store: Store<RootReducer.State>,
		private readonly fb: UntypedFormBuilder,
		private readonly cognito: CognitoService,
		private readonly dialogService: DialogService,
		public router: Router,
	) {
		super(store);
	}

	async onSubmit(): Promise<void> {
		try {
			await this.cognito.requestPasswordReset(this.form.value.username.toLowerCase());
			this.closeDialog();
			await this.router.navigate(['/reset-password']);
		} catch (e) {
			this.store.dispatch(popErrorToast({ title: e.message }));
		}
	}

	setUsername(username: string): void {
		this.store.dispatch(setUsername(username));
	}

	backToLogin(): void {
		this.dialogService.open(LoginDialogComponent);
	}

	createForm(): void {
		this.form = this.fb.group({
			username: ['', [Validators.required, Validators.email]],
		});
		void this.form
			.get('username')
			?.valueChanges.pipe(distinctUntilChanged())
			.forEach((username) => this.setUsername(username));
		this.form.valueChanges.subscribe(() => this.onValueChanged());
		this.onValueChanged();
	}

	ngOnInit(): void {
		this.createForm();
		this.isRequestingToken$ = this.store.select(getRequestingToken);
		this.isRequestingTokenSuccess$ = this.store.select(getRequestingTokenSuccess);

		this.dialogData$.subscribe(
			(data) =>
				(this.isLoginLinkHidden = (hasProp(data, 'hideLoginLink') && !!data.hideLoginLink) ?? false),
		);
	}

	ngOnDestroy(): void {
		this.store.dispatch(reset());
	}

	onValueChanged(): void {
		Object.keys(this.formErrors).forEach((field) => {
			this.formErrors[field] = '';
			const control = this.form.get(field);
			if (control && control.dirty && !control.valid) {
				const messages = this.validationMessages[field];
				Object.keys(control.errors).forEach((key) => {
					this.formErrors[field] += `${messages[key]} `;
				});
			}
		});
	}
}
