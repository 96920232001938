<nav>
	<!-- Product category menu -->
	<button
		mat-button
		[matMenuTriggerFor]="productMenu"
		class="product-menu-trigger top-nav-link"
		data-cy="productDropdown"
	>
		<span [ngClass]="(isProductRoute$ | async) ? 'nav-active' : ''" i18n="@@NavigationProductMenuButton"
			>Products</span
		>
		<g-icon [icon]="icons.faCaretDown" size="md" />
	</button>
	<mat-menu #productMenu="matMenu" [class]="menuClass">
		<!-- Default Menu -->
		@if (useDefaultProductMenu && (rootCategories$ | async); as rootCategories) {
			<button mat-menu-item [routerLink]="['/products']" routerLinkActive="item-active">
				<span i18n="@@NavigationAllProducts" data-cy="navCategoryDropdownItemName">All Products</span>
			</button>
			@for (category of rootCategories; track $index) {
				<button
					mat-menu-item
					[ngClass]="{ 'is-hidden': category.status === 'hidden' }"
					[routerLink]="['category', category.id, category.slug]"
					routerLinkActive="item-active"
				>
					<span data-cy="navCategoryDropdownItemName">
						{{ category.locale?.name | getText }}
					</span>
					<span class="badge" i18n="@@NavigationCategoryHidden">Hidden</span>
				</button>
			}
		}
		<!-- Mega Menu -->
		@if (useDefaultProductMenu !== true && (megaMenuCategories$ | async); as megaMenuCategories) {
			<div class="dropdown-menu-mega" [attr.size]="megaMenuStyle">
				<div fxLayout="row wrap" fxLayoutGap="10px grid">
					@for (categoryArray of megaMenuCategories; track $index) {
						<div class="mega-category">
							@for (category of categoryArray; track $index) {
								<button
									mat-menu-item
									class="mega-item"
									[ngClass]="{ 'is-hidden': category.status === 'hidden' }"
									[routerLink]="['category', category.id, category.slug]"
									routerLinkActive="item-active"
								>
									{{ category.locale?.name | getText }}
									<span class="badge" i18n="@@NavigationCategoryHidden">Hidden</span>
								</button>
							}
						</div>
					}
				</div>
			</div>
		}
	</mat-menu>
	@if (mainMenuContent$ | async; as content) {
		<!-- Selected pages -->
		@for (page of content.pages; track $index) {
			<a
				mat-button
				class="top-nav-link"
				[routerLink]="['/page', page.key]"
				routerLinkActive="nav-active"
			>
				<span>{{ page.content.title }}</span>
			</a>
		}

		<!-- Custom links -->
		@for (link of content.links; track $index) {
			<span>
				<!-- External links -->
				@if (link | isExternalLink) {
					<a mat-button class="top-nav-link" [attr.href]="link.link">
						<span>{{ link.linkText }}</span>
					</a>
				}
				<!-- Internal links -->
				@if (link | isRouterLink) {
					<a mat-button class="top-nav-link" [routerLink]="[link.link]">
						<span routerLinkActive="nav-active">{{ link.linkText }}</span>
					</a>
				}
			</span>
		}
	}
</nav>
