import { SelectItem } from '../components/select/select.component';
import { Pipe, PipeTransform } from '@angular/core';

export interface SelectParams {
	bindLabel?: string;
	defaultLabel?: string;
	formatter?: (opts: { option: unknown }) => string;
}

@Pipe({ name: 'selectOptionValue', standalone: true })
export class SelectOptionValuePipe implements PipeTransform {
	transform(option: SelectItem, key = 'value'): unknown {
		if (typeof option === 'object' && key) return option[key];
		return option;
	}
}

@Pipe({ name: 'selectOptionLabel', standalone: true })
export class SelectOptionLabelPipe implements PipeTransform {
	transform(option: SelectItem, params: SelectParams): string {
		if (!params) return (option as unknown as string) ?? '';
		const { bindLabel, defaultLabel, formatter } = params;
		if (typeof formatter === 'function') return formatter({ option });
		if (typeof option === 'string') return option;
		if (typeof option === 'number') return option.toString();
		return (option?.[bindLabel] as string) || defaultLabel;
	}
}
