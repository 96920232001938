import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RootReducer, Store } from '@app/app.reducers';
import {
	getAddress,
	getUserInfo,
	getAreBillingAddressFieldsValid,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { CxmlService } from '@app/checkout/modules/checkout-shared/services/cxml.service';
import { AddressFormModel } from '@app/user/reducers/address-book.reducer';
import { of as observableOf, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

/** Represents the order for the address summary component. */
@Component({
	standalone: true,
	imports: [NgIf, AsyncPipe, FlexLayoutModule],
	selector: 'g-address-summary',
	templateUrl: './address-summary.component.html',
	styleUrls: ['./address-summary.component.scss'],
})
export class AddressSummaryComponent implements OnInit, OnChanges {
	@Input() readonly order?: api.OrderDto;
	@Input() readonly isConfirmedOrder: boolean = false;
	billingAddress$!: Observable<Partial<AddressFormModel | undefined>>;
	shippingAddress$!: Observable<Partial<AddressFormModel | undefined>>;
	userInfo$!: Observable<api.OrderDto['metadata']['user_info'] | undefined>;

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly cxmlService: CxmlService,
	) {}

	readonly isBillingAddressFullNameValid$ = this.store.select(
		getAreBillingAddressFieldsValid('first_name', 'last_name'),
	);
	readonly isBillingAddressCompanyValid$ = this.store.select(getAreBillingAddressFieldsValid('company'));
	readonly isBillingAddressEmailValid$ = this.store.select(getAreBillingAddressFieldsValid('email'));
	readonly isBillingAddressEdiNumberValid$ = this.store.select(
		getAreBillingAddressFieldsValid('edi_number'),
	);
	readonly isBillingAddressOperatorValid$ = this.store.select(
		getAreBillingAddressFieldsValid('operator'),
	);
	readonly isBillingAddressOperatorIdValid$ = this.store.select(
		getAreBillingAddressFieldsValid('operator_id'),
	);

	/** Handles the input changes. */
	ngOnChanges(changes: SimpleChanges): void {
		/* istanbul ignore if */
		if (!changes.order) return;
		this.billingAddress$ = observableOf(this.order?.addresses?.billing).pipe(map(this.cleanFields));
		this.shippingAddress$ = observableOf(this.order?.addresses?.shipping).pipe(map(this.cleanFields));
		this.userInfo$ = observableOf(this.order?.metadata?.user_info).pipe(map(this.cleanFields));
	}

	/** Initializes the component and retrieves data from the Store if not already provided in the order Input. */
	ngOnInit(): void {
		// Get data from Store unless we already have it in the order Input.
		if (this.isConfirmedOrder) return;
		this.billingAddress$ = this.store.select(getAddress('billing')).pipe(map(this.cleanFields));
		this.shippingAddress$ = this.store.select(getAddress('shipping')).pipe(map(this.cleanFields));

		this.userInfo$ = combineLatest([
			this.store.select(getUserInfo),
			this.cxmlService.punchOutUserInfo$,
		]).pipe(
			map(([user, cxmlUserInfo]) => {
				const userInfo = cxmlUserInfo || user;
				return {
					...userInfo,
					first_name: userInfo.firstName || '',
					last_name: userInfo.lastName || '',
					email: userInfo.email || '',
				};
			}),
			map(this.cleanFields),
		);
	}

	/** Cleans the fields by replacing hyphens with "non-breaking hyphens" so they won't cause line breaks. */
	private cleanFields<T>(this: void, fields: T): T {
		if (typeof fields !== 'object' || fields === null) return fields;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const newFields = {} as T;
		for (const key of Object.keys(fields)) {
			const value = fields[key as keyof T];
			// Replace hyphens with "non-breaking hyphens" so they won't cause line breaks.
			// See: http://www.fileformat.info/info/unicode/char/2011/index.htm
			newFields[key] = typeof value === 'string' ? value.replaceAll('-', '\u2011') : value;
		}
		return newFields;
	}
}
