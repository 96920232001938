import type { CartItem } from '../reducers/checkout.reducer';
import { isCheckoutAllowed, isPrintfileAvailable } from '../utils/checkout-utils';
import { Pipe, PipeTransform } from '@angular/core';
import { getProductQuantity, isVariableProduct } from '@app/product/utils/product-utils';
import { I18nService } from '@app/shared/utils/i18n.service';

/** @see isVariableProduct */
@Pipe({ name: 'isVariableProduct', standalone: true })
export class IsVariableProductPipe implements PipeTransform {
	/** @see isVariableProduct */
	transform(item: CartItem): boolean {
		return isVariableProduct(item.product);
	}
}

/** @see isPrintfileAvailable */
@Pipe({ name: 'isPrintfileAvailable', standalone: true })
export class IsPrintfileAvailablePipe implements PipeTransform {
	/** @see isPrintfileAvailable */
	transform(item: CartItem): boolean {
		return isPrintfileAvailable(item);
	}
}

/** A pipe to see if checkout is allowed. */
@Pipe({ name: 'isCheckoutAllowed', standalone: true })
export class IsCheckoutAllowedPipe implements PipeTransform {
	/** A pipe to see if checkout is allowed. */
	transform(item: CartItem): boolean {
		return isCheckoutAllowed(item);
	}
}

/** A pipe to get the product quantity. */
@Pipe({ name: 'getProductQuantity', standalone: true })
export class GetProductQuantityPipe implements PipeTransform {
	/** A pipe to get the product quantity. */
	transform(item: CartItem | api.OrderProductDto): number {
		return getProductQuantity(item);
	}
}

/** A pipe to get the quantity unit. */
@Pipe({ name: 'getQuantityUnit', standalone: true })
export class GetQuantityUnitPipe implements PipeTransform {
	constructor(private readonly i18n: I18nService) {}
	/** A pipe to get the quantity unit. */
	transform(item: CartItem | api.OrderProductDto): string {
		const unit = this.i18n.getText(item?.product?.metadata?.unit);
		return unit || $localize`:@@DefaultQuantityUnit:pcs`;
	}
}
