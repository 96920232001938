<div class="order-summary">
	@if (order && options?.showOrderDetails) {
		<div class="order-details">
			<h2 i18n="@@OrderSummaryTitle">Order</h2>
			<h2 data-cy="orderNumber">#{{ order.order_number }}</h2>
			<p>
				<span>{{ order.created_at | date: 'd.M.y' }}</span>
				@if (options?.showOrderStatus && order.status?.order; as status) {
					<span class="status">
						<span class="status-spacer">&mdash;</span>
						<g-order-status-switch [orderStatus]="status" />
					</span>
				}
			</p>
		</div>
	}
	<g-order-product-list
		[order]="order"
		[showCopyToBasket]="options?.showCopyToBasket ?? false"
		[showPrices]="options?.showPrices ?? false"
	/>
	@if (options?.showPrices) {
		<g-price-summary
			[isConfirmedOrder]="options?.isConfirmedOrder ?? false"
			[order]="order"
			[showPriceBreakdown]="true"
		/>
	}
	<g-address-summary [isConfirmedOrder]="options?.isConfirmedOrder ?? false" [order]="order" />
</div>

<div class="print">
	<a (click)="printReceipt()" (keypress)="$event.key === 'Enter' && printReceipt()" tabindex="0">
		<g-icon [icon]="icons.faPrint" fixedWidth />
		<span i18n="@@OrderSummaryPrintButton">Print receipt</span>
	</a>
</div>
