import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	Component,
	Input,
	forwardRef,
	Output,
	EventEmitter,
	OnInit,
	Renderer2,
	ViewChild,
	ElementRef,
	SimpleChanges,
	OnChanges,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	SelectOptionLabelPipe,
	SelectOptionValuePipe,
	SelectParams,
} from '@app/shared/pipes/select.pipe';
import { isPlainObjectEqual } from '@app/shared/utils/util';

export type SelectItem = string | number | { value?: unknown; label?: string } | Record<string, string>;

/** A component to display a select box. */
@Component({
	standalone: true,
	imports: [SelectOptionLabelPipe, SelectOptionValuePipe, FormsModule, NgClass, NgIf, NgFor],
	selector: 'g-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(/* istanbul ignore next */ () => SelectComponent),
		},
	],
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnChanges {
	@ViewChild('contentSelect', { static: true }) selectElement!: ElementRef;
	@Input('aria-label') ariaLabel: string;
	@Input() items: SelectItem[] = [];
	@Input() bindLabel = 'label';
	@Input() bindKey = 'value';
	@Input() placeholder = '';
	@Input() selectedOption: unknown;
	@Input() selectedLabelFormatter: (item: { option: unknown }) => string;
	@Input() optionLabelFormatter: (item: { option: unknown }) => string;
	@Output() readonly selectChange = new EventEmitter();
	selectButtonState!: SelectParams;
	selectOptionState!: SelectParams;
	// Can't use selectedOption directly as the model because it breaks validators.
	selectModel: unknown;
	isDisabled = false;
	private onChangeFn?: (value: any) => void;

	constructor(private readonly renderer: Renderer2) {}

	@Input('disabled') set disabled(isDisabled: boolean) {
		this.setDisabledState(isDisabled);
	}

	ngOnInit(): void {
		this.selectButtonState = {
			bindLabel: this.bindLabel,
			defaultLabel: this.placeholder,
			formatter: this.selectedLabelFormatter,
		};
		this.selectOptionState = {
			bindLabel: this.bindLabel,
			defaultLabel: '???',
			formatter: this.optionLabelFormatter,
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.selectedOption &&
			changes.selectedOption.currentValue !== changes.selectedOption.previousValue
		) {
			this.selectModel = this.selectedOption;
		}
	}

	writeValue(value: unknown): void {
		// If provided value is empty and placeholder is not used,
		// select the first item or native <select> acts weirdly.
		if (!value && !this.placeholder) {
			// Timeout needed to keep validators up to date.
			window.setTimeout(() => {
				const item = this.items?.[0];
				this.updateSelectedOption(item?.[this.bindKey] ?? item);
			});
			return;
		}
		this.selectedOption = value;
		this.selectModel = value;
	}

	registerOnChange(fn: (...args: unknown[]) => void): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(_fn: any): void {}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	updateSelectedOption(selectedOption: unknown): void {
		if (this.selectedOption === selectedOption) return;
		this.selectedOption = selectedOption;
		this.selectModel = selectedOption;
		this.selectChange.emit(this.selectedOption);
		if (typeof this.onChangeFn === 'function') {
			this.onChangeFn(this.selectedOption);
		}
	}

	compareOptions = (o1: unknown, o2: unknown): boolean => {
		const val1: unknown = o1?.[this.bindKey] ?? o1;
		const val2: unknown = o2?.[this.bindKey] ?? o2;
		return isPlainObjectEqual(val1, val2);
	};
}
