<div id="translatedStrings" aria-hidden="true">
	<span data-group-id="toast">
		<span data-string-id="productFormSuccess" i18n>Form submitted!</span>
		<span data-string-id="addressSaved" i18n>Address saved</span>
		<span data-string-id="emailVerified" i18n>Your email address has been verified.</span>
		<span data-string-id="userNotConfirmed" i18n>User account not confirmed!</span>
		<span data-string-id="emailNotVerified" i18n>Email address not verified!</span>
		<span data-string-id="verificationResent" i18n>Email verification code sent</span>
		<span data-string-id="confirmationResent" i18n>Confirmation code sent</span>
		<span data-string-id="copiedToShoppingCart" i18n>Product copied to shopping cart!</span>
		<span data-string-id="priceCalcFailed" i18n>Failed to calculate price for product!</span>
		<span data-string-id="downloadAssetError" i18n>Could not find downloadable asset</span>
	</span>
	<span data-group-id="confirmCode">
		<span data-string-id="codeRequired" i18n>Code is required.</span>
		<span data-string-id="codeMinLength" i18n>Code cannot be shorter than 6 characters</span>
		<span data-string-id="codeMaxLength" i18n>Code cannot be longer than 6 characters</span>
	</span>
	<span data-group-id="productForm">
		<span data-string-id="quantityRequired" i18n>Required</span>
		<span data-string-id="quantityFormat" i18n>Must be a number</span>
		<span data-string-id="quantityMaxLength" i18n>Value is too long</span>
		<span data-string-id="deliveryDateRequired" i18n>Required</span>
		<span data-string-id="deliveryDateFormat" i18n>Give date as dd.mm.yyyy</span>
	</span>
	<span data-group-id="productWorkflowSelect">
		<span data-group-id="productDesigner">
			<span data-string-id="title" i18n>Design it yourself</span>
			<span data-string-id="description" i18n>Create new design using our product designer.</span>
		</span>
		<span data-group-id="template">
			<span data-string-id="title" i18n>Edit an existing template</span>
			<span data-string-id="description" i18n>Create print material based on one of our design templates.</span>
		</span>
		<span data-group-id="upload">
			<span data-string-id="title" i18n>Upload ready print material</span>
			<span data-string-id="description" i18n>Upload print material made according to our guidelines.</span>
		</span>
		<span data-group-id="designService">
			<span data-string-id="title" i18n>Employ our design service</span>
			<span data-string-id="description" i18n>Our designers will create your print material according to your specifications</span>
		</span>
	</span>
	<span data-group-id="productDesignService">
		<span data-string-id="descriptionRequired" i18n>Description is required</span>
		<span data-string-id="emailRequired" i18n>Email is required.</span>
		<span data-string-id="emailMaxLength" i18n>Email cannot be over 255 characters long</span>
		<span data-string-id="emailFormat" i18n>Email has to be in right format</span>
	</span>
	<span data-group-id="checkout">
		<span data-string-id="backToOrderDetails" i18n>Back to order details</span>
		<span data-string-id="toPay" i18n>Continue to pay</span>
		<span data-string-id="toBilling" i18n>Continue to billing</span>
		<span data-string-id="backToShipping" i18n>Back to shipping</span>
	</span>
	<span data-group-id="productEditor">
		<span data-string-id="downloadPDFFailed" i18n>Downloading PDF failed</span>
	</span>
	<span data-group-id="addToList">
		<span data-string-id="nameRequired" i18n>Product name is required</span>
		<span data-string-id="nameMaxLength" i18n>Product name must be less than 100 characters</span>
		<span data-string-id="addedToList" i18n>Product has been added to My Products!</span>
	</span>
	<span data-group-id="cxml">
		<span data-string-id="sendCartFailed" i18n="@@SendCxmlCartFailed">Failed to send punch out cart!</span>
	</span>
</div>
