// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module User */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get user details from CAS based on request JWT token
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} Current authenticated user details
 */
export function me(options?: MeOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    emailVerified?: boolean,
    phoneNumberVerified?: boolean,
    enabled?: string,
    identifier?: string,
    isConfirmed?: boolean,
    isFederated?: boolean,
    userStatus?: string,
    groups?: {
      id?: number,
      name?: string
    }[],
    groupMetadata?: {
      defaultShowPricesWithTax?: boolean
    },
    permissions?: {
      access_approval_workflow_products: boolean,
      access_billing_step: boolean,
      access_shipping_step: boolean,
      access_storefront_admin: boolean,
      access_storefront_admin_prices: boolean,
      allow_billing_address_editing: boolean,
      allow_checkout: boolean,
      allow_download: boolean,
      allow_request_quote: boolean,
      allow_shipping_address_editing: boolean,
      show_internal_info: boolean,
      show_prices: boolean,
      show_staged_content: boolean,
      allow_my_products: boolean,
      allow_profile_editing: boolean
    }
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(meOperation, parameters);
}

/**
 * Get anonymous user and its permissions
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} OK
 */
export function anonymousUser(options?: AnonymousUserOptions): Promise<api.Response<{[key: string]: any}>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(anonymousUserOperation, parameters);
}

/**
 * Get user list by user IDs
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object[]>} OK
 */
export function byIdList(options?: ByIdListOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: any[],
    casCustomers?: any[],
    stores?: any[]
  }[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(byIdListOperation, parameters);
}

/**
 * Get user details from CAS
 * 
 * @param {string} id 
 * @param {number} customerId 
 * @return {Promise<object>} The requested user
 */
export function getByCustomerId(id: string, customerId: number): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    emailVerified?: boolean,
    phoneNumberVerified?: boolean,
    enabled?: string,
    identifier?: string,
    isConfirmed?: boolean,
    isFederated?: boolean,
    userStatus?: string,
    groups?: {
      id?: number,
      name?: string
    }[],
    stores?: any[],
    groupMetadata?: {
      defaultShowPricesWithTax?: boolean
    }
  }>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id,
      customerId
    }
  };
  return gateway.request(getByCustomerIdOperation, parameters);
}

/**
 * Get list of users from CAS.
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object[]>} OK
 */
export function all(options?: AllOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: any[],
    casCustomers?: any[],
    stores?: any[]
  }[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(allOperation, parameters);
}

/**
 * Create new user in Cognito and CAS.
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @param {boolean} [options.email=true] Set to false to suppress temporary password email.
 * @return {Promise<object>} The created user
 */
export function create(options?: CreateOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    },
    query: {
      email: options.email
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Register user to Cognito and CAS.
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} The registered user
 */
export function register(options?: RegisterOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(registerOperation, parameters);
}

/**
 * Create new user in Cognito and CAS to specified customer's user pool.
 * 
 * @param {number} customerId 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} The created user
 */
export function createToCustomerUserPool(customerId: number, options?: CreateToCustomerUserPoolOptions): Promise<api.Response<{
    id?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customer?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      customerId
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(createToCustomerUserPoolOperation, parameters);
}

/**
 * Delete user from CAS and Cognito
 * 
 * @param {string} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} User deleted successfully
 */
export function destroy(id: string, options?: DestroyOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

/**
 * Get user details from CAS
 * 
 * @param {string} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} The requested user
 */
export function one(id: string, options?: OneOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    emailVerified?: boolean,
    phoneNumberVerified?: boolean,
    enabled?: string,
    identifier?: string,
    isConfirmed?: boolean,
    isFederated?: boolean,
    userStatus?: string,
    groups?: {
      id?: number,
      name?: string
    }[],
    stores?: any[],
    groupMetadata?: {
      defaultShowPricesWithTax?: boolean
    }
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    }
  };
  return gateway.request(oneOperation, parameters);
}

/**
 * Delete user from CAS and Cognito
 * 
 * @param {string} id 
 * @param {number} customerId 
 * @return {Promise<object>} The deleted user
 */
export function destroyByCustomerId(id: string, customerId: number): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  }>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id,
      customerId
    }
  };
  return gateway.request(destroyByCustomerIdOperation, parameters);
}

/**
 * Lists all user pools associated with an AWS account
 * 
 * @param {number} limit 
 * @param {string} paginationToken 
 * @return {Promise<object>} 
 */
export function listUserPools(limit: number, paginationToken: string): Promise<api.Response<{
    userPools: any[],
    paginationToken?: string
  }>> {
  const parameters: api.OperationParamGroups = {
    query: {
      limit,
      paginationToken
    }
  };
  return gateway.request(listUserPoolsOperation, parameters);
}

/**
 * Find users where search term partially matches
 * 
 * @param {string} searchTerm 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object[]>} Users found with the search term
 */
export function searchUsers(searchTerm: string, options?: SearchUsersOptions): Promise<api.Response<{
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: any[],
    casCustomers?: any[],
    stores?: any[]
  }[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      searchTerm
    }
  };
  return gateway.request(searchUsersOperation, parameters);
}

/**
 * Create and/or update a batch of users to Cognito and CAS
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object[]} [options.body] 
 * @return {Promise<object[]>} The created and/or updated users
 */
export function batchImport(options?: BatchImportOptions): Promise<api.Response<{
    id?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  }[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(batchImportOperation, parameters);
}

/**
 * Resend temporary password for user whose Cognito account has expired
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function resendTemporaryPassword(options?: ResendTemporaryPasswordOptions): Promise<api.Response<{
    success?: boolean
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(resendTemporaryPasswordOperation, parameters);
}

/**
 * Resend temporary password for user whose Cognito account has expired
 * 
 * @param {number} customerId 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function resendCustomerTemporaryPassword(customerId: number, options?: ResendCustomerTemporaryPasswordOptions): Promise<api.Response<{
    success?: boolean
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    },
    path: {
      customerId
    }
  };
  return gateway.request(resendCustomerTemporaryPasswordOperation, parameters);
}

export interface MeOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface AnonymousUserOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface ByIdListOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: any;
}

export interface AllOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface CreateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    firstName?: string,
    lastName?: string,
    email: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale: string
  };
  /**
   * Set to false to suppress temporary password email.
   */
  email?: boolean;
}

export interface RegisterOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    companyName?: string,
    vatNumber?: string,
    locale: string,
    password: string
  };
}

export interface CreateToCustomerUserPoolOptions {
  body?: {
    firstName?: string,
    lastName?: string,
    email: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale: string
  };
}

export interface DestroyOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface OneOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface SearchUsersOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface BatchImportOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    firstName?: string,
    lastName?: string,
    email: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    addresses?: {
      id?: number,
      addressName?: string,
      firstName?: string,
      lastName?: string,
      streetName?: string,
      zipCode?: string,
      city?: string,
      company?: string,
      country?: string,
      ediNumber?: string,
      operatorId?: string,
      operator?: string,
      sonetNumber?: string,
      isShipping?: boolean,
      isBilling?: boolean,
      isUserDefaultShipping?: boolean,
      isUserDefaultBilling?: boolean
    }[]
  }[];
}

export interface ResendTemporaryPasswordOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    email: string
  };
}

export interface ResendCustomerTemporaryPasswordOptions {
  body?: {
    email: string
  };
}

const meOperation: api.OperationInfo = {
  path: '/user/me',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const anonymousUserOperation: api.OperationInfo = {
  path: '/user/anonymous-user',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const byIdListOperation: api.OperationInfo = {
  path: '/user/by-id-list',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getByCustomerIdOperation: api.OperationInfo = {
  path: '/user/{id}/by-customer-id/{customerId}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/user',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/user',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const registerOperation: api.OperationInfo = {
  path: '/user/register',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createToCustomerUserPoolOperation: api.OperationInfo = {
  path: '/user/create-to-customer-user-pool/{customerId}',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/user/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/user/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyByCustomerIdOperation: api.OperationInfo = {
  path: '/user/{id}/{customerId}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const listUserPoolsOperation: api.OperationInfo = {
  path: '/user/list-userpools',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const searchUsersOperation: api.OperationInfo = {
  path: '/user/search-users/{searchTerm}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const batchImportOperation: api.OperationInfo = {
  path: '/user/batch-import',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const resendTemporaryPasswordOperation: api.OperationInfo = {
  path: '/user/resend-temporary-password',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const resendCustomerTemporaryPasswordOperation: api.OperationInfo = {
  path: '/user/resend-temporary-password/{customerId}',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
