// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/ApprovalWorkflow */
// Auto-generated, edits will be overwritten
import * as ApprovalWorkflow from '../ApprovalWorkflow';

export const LIST_BY_STORE_START = 's/ApprovalWorkflow/LIST_BY_STORE_START';
export const LIST_BY_STORE = 's/ApprovalWorkflow/LIST_BY_STORE';
export type LIST_BY_STORE = api.ApprovalWorkflowDto[];

export function listByStore(options?: ApprovalWorkflow.ListByStoreOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: LIST_BY_STORE_START, meta: { info, params: { options } } });
    return ApprovalWorkflow.listByStore(options)
      .then(response => dispatch({
        type: LIST_BY_STORE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const LIST_MY_WORKFLOW_ORDERS_START = 's/ApprovalWorkflow/LIST_MY_WORKFLOW_ORDERS_START';
export const LIST_MY_WORKFLOW_ORDERS = 's/ApprovalWorkflow/LIST_MY_WORKFLOW_ORDERS';
export type LIST_MY_WORKFLOW_ORDERS = api.OrderDto[];

export function listMyWorkflowOrders(options?: ApprovalWorkflow.ListMyWorkflowOrdersOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: LIST_MY_WORKFLOW_ORDERS_START, meta: { info, params: { options } } });
    return ApprovalWorkflow.listMyWorkflowOrders(options)
      .then(response => dispatch({
        type: LIST_MY_WORKFLOW_ORDERS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const REVIEW_START = 's/ApprovalWorkflow/REVIEW_START';
export const REVIEW = 's/ApprovalWorkflow/REVIEW';
export type REVIEW = api.SuccessDto;

export function review(orderId: number, body: api.OrderReviewDto[], options?: ApprovalWorkflow.ReviewOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: REVIEW_START, meta: { info, params: { orderId, body, options } } });
    return ApprovalWorkflow.review(orderId, body, options)
      .then(response => dispatch({
        type: REVIEW,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const LIST_APPROVAL_WORKFLOWS_START = 's/ApprovalWorkflow/LIST_APPROVAL_WORKFLOWS_START';
export const LIST_APPROVAL_WORKFLOWS = 's/ApprovalWorkflow/LIST_APPROVAL_WORKFLOWS';
export type LIST_APPROVAL_WORKFLOWS = api.ApprovalWorkflowDto[];

export function listApprovalWorkflows(options?: ApprovalWorkflow.ListApprovalWorkflowsOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: LIST_APPROVAL_WORKFLOWS_START, meta: { info, params: { options } } });
    return ApprovalWorkflow.listApprovalWorkflows(options)
      .then(response => dispatch({
        type: LIST_APPROVAL_WORKFLOWS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_APPROVAL_WORKFLOW_START = 's/ApprovalWorkflow/CREATE_APPROVAL_WORKFLOW_START';
export const CREATE_APPROVAL_WORKFLOW = 's/ApprovalWorkflow/CREATE_APPROVAL_WORKFLOW';
export type CREATE_APPROVAL_WORKFLOW = api.ApprovalWorkflowDto;

export function createApprovalWorkflow(body: api.ApprovalWorkflowBodyDto, options?: ApprovalWorkflow.CreateApprovalWorkflowOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_APPROVAL_WORKFLOW_START, meta: { info, params: { body, options } } });
    return ApprovalWorkflow.createApprovalWorkflow(body, options)
      .then(response => dispatch({
        type: CREATE_APPROVAL_WORKFLOW,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_APPROVAL_WORKFLOW_START = 's/ApprovalWorkflow/GET_APPROVAL_WORKFLOW_START';
export const GET_APPROVAL_WORKFLOW = 's/ApprovalWorkflow/GET_APPROVAL_WORKFLOW';
export type GET_APPROVAL_WORKFLOW = api.ApprovalWorkflowDto;

export function getApprovalWorkflow(id: number, options?: ApprovalWorkflow.GetApprovalWorkflowOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_APPROVAL_WORKFLOW_START, meta: { info, params: { id, options } } });
    return ApprovalWorkflow.getApprovalWorkflow(id, options)
      .then(response => dispatch({
        type: GET_APPROVAL_WORKFLOW,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_APPROVAL_WORKFLOW_START = 's/ApprovalWorkflow/UPDATE_APPROVAL_WORKFLOW_START';
export const UPDATE_APPROVAL_WORKFLOW = 's/ApprovalWorkflow/UPDATE_APPROVAL_WORKFLOW';
export type UPDATE_APPROVAL_WORKFLOW = api.ApprovalWorkflowDto;

export function updateApprovalWorkflow(id: number, body: api.ApprovalWorkflowBodyDto, options?: ApprovalWorkflow.UpdateApprovalWorkflowOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_APPROVAL_WORKFLOW_START, meta: { info, params: { id, body, options } } });
    return ApprovalWorkflow.updateApprovalWorkflow(id, body, options)
      .then(response => dispatch({
        type: UPDATE_APPROVAL_WORKFLOW,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DELETE_APPROVAL_WORKFLOW_START = 's/ApprovalWorkflow/DELETE_APPROVAL_WORKFLOW_START';
export const DELETE_APPROVAL_WORKFLOW = 's/ApprovalWorkflow/DELETE_APPROVAL_WORKFLOW';
export type DELETE_APPROVAL_WORKFLOW = undefined;

export function deleteApprovalWorkflow(id: number, options?: ApprovalWorkflow.DeleteApprovalWorkflowOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DELETE_APPROVAL_WORKFLOW_START, meta: { info, params: { id, options } } });
    return ApprovalWorkflow.deleteApprovalWorkflow(id, options)
      .then(response => dispatch({
        type: DELETE_APPROVAL_WORKFLOW,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
