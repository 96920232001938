// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/ProductList */
// Auto-generated, edits will be overwritten
import * as ProductList from '../ProductList';

export const MY_LISTS_START = 's/ProductList/MY_LISTS_START';
export const MY_LISTS = 's/ProductList/MY_LISTS';
export type MY_LISTS = api.ProductListWithProducts[];

export function myLists(options?: ProductList.MyListsOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: MY_LISTS_START, meta: { info, params: { options } } });
    return ProductList.myLists(options)
      .then(response => dispatch({
        type: MY_LISTS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/ProductList/CREATE_START';
export const CREATE = 's/ProductList/CREATE';
export type CREATE = api.ProductListWithProducts;

export function create(options?: ProductList.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return ProductList.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_START = 's/ProductList/UPDATE_START';
export const UPDATE = 's/ProductList/UPDATE';
export type UPDATE = api.ProductListWithProducts;

export function update(id: number, options?: ProductList.UpdateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_START, meta: { info, params: { id, options } } });
    return ProductList.update(id, options)
      .then(response => dispatch({
        type: UPDATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/ProductList/DESTROY_START';
export const DESTROY = 's/ProductList/DESTROY';
export type DESTROY = api.ProductList;

export function destroy(id: number, options?: ProductList.DestroyOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { id, options } } });
    return ProductList.destroy(id, options)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
