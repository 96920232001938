import { firstTruthy, getDebug } from '../utils/util';
import { Injectable } from '@angular/core';
import { Router, UrlTree, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { CognitoService } from '@app/shared/services/cognito.service';
import { getInitAuthState } from '@app/user/reducers/auth.reducer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Service for handling authentication for routes. */
@Injectable({ providedIn: 'root' })
export class AuthGuardService {
	private readonly debug = getDebug('AuthGuardService');

	constructor(
		private readonly cognito: CognitoService,
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
	) {}

	/**
	 * Check if user is authenticated.
	 * If user is not loaded, fetch the user first.
	 * If user is not authenticated, redirect to login page.
	 */
	canActivate(
		_route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> {
		return this.store.select(getInitAuthState).pipe(
			map((isAuthenticated) => {
				// If user info is not fetched, fetch the user first and wait.
				if (this.userNotFetched(isAuthenticated)) {
					this.debug('Fetching user info');
					void this.cognito.dispatchCurrentUser();
					return;
				}
				// If user is not logged in, redirect to login page.
				if (this.userNotLoggedIn(isAuthenticated)) {
					const redirectTo = encodeURIComponent(state.url);
					this.debug('redirect to login page', { redirectTo });
					return this.router.createUrlTree(['user', 'login'], {
						queryParams: { 'redirect-to': redirectTo },
					});
				}
				// All good, user is logged in.
				this.debug('user authenticated');
				return true;
			}),
			firstTruthy, // Wait until user info has been fetched.
		);
	}

	/** Check if user info is not fetched. */
	private userNotFetched(isAuthenticated: boolean | undefined): boolean {
		return isAuthenticated === undefined;
	}

	/** Check if user is not logged in. */
	private userNotLoggedIn(isAuthenticated: boolean | undefined): boolean {
		return isAuthenticated === false;
	}
}
