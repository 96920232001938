<div class="content-container">
	<div class="unpublished-banner" *ngIf="(content$ | async) && (content$ | async)?.status !== 'public'">
		<span i18n>This page has not been published</span>
	</div>
	<div
		*ngIf="!(isLoading$ | async) && (content$ | async)"
		[ngClass]="{ 'capitalize-headers': i18n.locale === 'en' }"
		[innerHTML]="getHtml(content$ | async)"
	>
	</div>
	<div *ngIf="!(isLoading$ | async) && (showPageNotFound$ | async)" class="page-not-found">
		<h1 i18n>Page not found</h1>
		<p i18n>It either doesn't exist or it has been disabled.</p>
	</div>
	<g-loading-spinner *ngIf="isLoading$ | async"></g-loading-spinner>
</div>
