import { StorePersistService } from './checkout/modules/checkout-shared/services/store-persist.service';
import { DialogService } from './dialog/services/dialog.service';
import { AuthService } from './shared/services/auth.service';
import { FaultCodeService, FaultCode } from './shared/services/fault-code.service';
import { SeoService } from './shared/services/seo.service';
import { ViewportService } from './shared/services/viewport.service';
import { setCssColors, darkenColor, lightenColor, firstTruthy } from './shared/utils/util';
import { getUser, isLoggedUser } from './user/reducers/user.reducer';
import { trigger, transition, style, animate, query } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RootReducer, getShowContent, getShowGlobalSpinner, Store } from '@app/app.reducers';
import {
	LOGIN_URL,
	CONFIRM_URL,
	RESET_PASSWORD_URL,
	REGISTRATION_STANDALONE,
} from '@app/app.router.urls';
import { ProductListPersistService } from '@app/checkout/modules/checkout-shared/services/product-list-persist.service';
import { getStoreColors, StoreColors } from '@app/shared/reducers/storefront.reducer';
import { ToastService } from '@app/shared/services/toast.service';
import { doScrolling } from '@app/shared/utils/scroll-to-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, of, timer, EMPTY } from 'rxjs';
import { filter, map, debounce, shareReplay, switchMap } from 'rxjs/operators';

export const WAIT_BEFORE_LOADING_SPINNER = 1000;
export const USER_LOGGED_IN_CLASS_NAME = 'is-user-logged-in';

const fadeIn = [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))];
const fadeOut = [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))];

/** App root component */
@UntilDestroy()
@Component({
	selector: 'g-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('spinnerFade', [transition(':enter', fadeIn), transition(':leave', fadeOut)]),
		trigger('routeFadeAnimation', [
			transition('* => *', [
				query(':enter', [style({ opacity: 0 })], { optional: true }),
				query(':enter', fadeIn, { optional: true }),
			]),
		]),
	],
})
export class AppComponent implements OnInit {
	readonly showContent$: Observable<boolean>;
	readonly showHeader$: Observable<boolean>;
	readonly showVignetteHeader$: Observable<boolean>;
	readonly showFooter$: Observable<boolean>;
	readonly showNotificationBanner$: Observable<boolean>;
	readonly showCustomHtml$: Observable<boolean>;
	readonly faultCode$: Observable<FaultCode>;
	readonly onDeactivate = doScrolling.bind(undefined, 0, 100);
	readonly showSpinner$!: Observable<boolean>;

	constructor(
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
		private readonly route: ActivatedRoute,
		private readonly toastService: ToastService,
		private readonly dialogService: DialogService,
		private readonly storePersistService: StorePersistService,
		private readonly productListPersistService: ProductListPersistService,
		private readonly authService: AuthService,
		private readonly faultCodeService: FaultCodeService,
		private readonly renderer: Renderer2,
		readonly seoService: SeoService,
		public readonly viewport: ViewportService,
	) {
		this.dialogService.init();
		this.toastService.init();
		this.authService.init();
		seoService.setDirectly({}); // Set default SEO values.
		// TODO: Move to checkout module.
		void this.storePersistService.init();
		this.productListPersistService.init();
		this.showContent$ = this.store.select(getShowContent);

		const getBoolProp$ = (prop: string, defaultTo = false) =>
			this.router.events.pipe(
				// ActivatedRoute is not correct for AppComponent,
				// we need to wait for the navigation to finish and return the child route.
				filter((event) => event instanceof NavigationEnd),
				switchMap(() => {
					let route = this.route;
					while (route.firstChild) route = route.firstChild;
					return route.data;
				}),
				map<{ [prop: string]: boolean }, boolean>(({ [prop]: val }) => val ?? defaultTo),
				filter<boolean>((val) => val === undefined || typeof val === 'boolean'),
			);

		this.showNotificationBanner$ = getBoolProp$('showNotificationBanner');
		this.showCustomHtml$ = getBoolProp$('showCustomHtml');
		this.showVignetteHeader$ = getBoolProp$('showVignetteHeader', true);
		this.showHeader$ = getBoolProp$('showHeader', true);
		this.showFooter$ = getBoolProp$('showFooter', true);

		this.faultCode$ = this.faultCodeService.getFaultCode$();
	}

	/** Initialize component */
	ngOnInit(): void {
		const routerUrls$ = this.router.events.pipe(
			filter((event): event is NavigationEnd => event instanceof NavigationEnd),
			map((val) => val?.url),
			shareReplay(1),
		);
		// Show loading spinner until content has been fetched
		// TODO: Handle using router resolve somehow?
		// @ts-expect-error -- readonly
		this.showSpinner$ = combineLatest([this.store.select(getShowGlobalSpinner), routerUrls$]).pipe(
			map(
				([showGlobalSpinner, url]) =>
					showGlobalSpinner &&
					!url.startsWith(LOGIN_URL) &&
					!url.startsWith(CONFIRM_URL) &&
					!url.startsWith(REGISTRATION_STANDALONE) &&
					!url.startsWith(RESET_PASSWORD_URL),
			),
			// Only show spinner if loading takes long.
			debounce((showSpinner) => (showSpinner ? timer(WAIT_BEFORE_LOADING_SPINNER) : of(EMPTY))),
		);

		// Set primary color custom css properties
		this.store
			.select(getStoreColors)
			.pipe(firstTruthy)
			.subscribe((colors) => this.setStoreCustomColors(colors));

		// Remove initial loading spinner to stop it from animating forever with opacity 0.
		const spinner = document.querySelector('body > .loading');
		// istanbul ignore if
		if (spinner) setTimeout(spinner.remove.bind(spinner), 1000);

		this.store
			.select(getUser)
			.pipe(map(isLoggedUser), untilDestroyed(this))
			.subscribe((isUserLoggedIn) => this.setBodyTagClasses(isUserLoggedIn));
	}

	/** Set primary color custom css properties */
	private setStoreCustomColors(colors: StoreColors) {
		setCssColors({
			primary: colors.primary,
			'primary-text': colors.primaryText,
			'primary-highlight': `#${darkenColor(colors.primary, 0.08)}`,
			'primary-lighter': `#${lightenColor(colors.primary, 0.5)}`,
			'info-box': `#${lightenColor(colors.primary, 0.15)}`,
		});
	}

	/** Set body tag classes */
	private setBodyTagClasses(isUserLoggedIn: boolean) {
		if (isUserLoggedIn) {
			this.renderer.addClass(document.body, USER_LOGGED_IN_CLASS_NAME);
		} else if (document.body.classList.contains(USER_LOGGED_IN_CLASS_NAME)) {
			this.renderer.removeClass(document.body, USER_LOGGED_IN_CLASS_NAME);
		}
	}
}
