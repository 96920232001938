<div class="reset-password-content">
	<h3 i18n>Reset password</h3>
	<p i18n>A verification code for resetting your password has been sent to your email.</p>
	<form id="password-reset" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
		<div class="form-group form-group-primary">
			<label for="email" i18n>Email</label>
			<input
				trim="blur"
				class="form-control form-control-primary"
				type="email"
				formControlName="email"
				autocomplete="work email"
			/>
			<div class="validation-error">{{ formErrors$ | async | validationField: 'email' }}</div>
		</div>
		<div class="form-group form-group-primary">
			<label for="code" i18n>Code</label>
			<input
				trim="blur"
				class="form-control form-control-primary"
				type="text"
				formControlName="code"
				autocomplete="one-time-code"
			/>
			<div class="validation-error">{{ formErrors$ | async | validationField: 'code' }}</div>
		</div>
		<div class="form-group form-group-primary">
			<label for="password" i18n>Password</label>
			<input
				trim="blur"
				class="form-control form-control-primary"
				type="password"
				formControlName="password"
				autocomplete="new-password"
			/>
			<g-password-strength
				[password]="form.get('password').value"
				[validatorControl]="form.get('passwordScore')"
			>
			</g-password-strength>
			<div class="validation-error">
				<p>{{ formErrors$ | async | validationField: 'password' }}</p>
				<p>{{ formErrors$ | async | validationField: 'passwordScore' }}</p>
			</div>
		</div>
		<div class="form-group form-group-primary">
			<label for="passwordRetype" i18n>Retype password</label>
			<input
				trim="blur"
				class="form-control form-control-primary"
				type="password"
				formControlName="passwordRetype"
				autocomplete="new-password"
			/>
			<div class="validation-error">{{ formErrors$ | async | validationField: 'passwordRetype' }}</div>
		</div>
		<button type="submit" class="btn btn-primary" [disabled]="form.invalid" i18n>Submit</button>
	</form>
</div>
