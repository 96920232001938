<div fxLayout="row">
	<div class="header" fxFlex="100" fxLayout="row">
		<h3 class="header-dialog" fxFlex="80" i18n="@@ResetPassword">Reset password</h3>
		<g-icon
			[icon]="icons.faXmark"
			class="close-dialog"
			fxFlex="20"
			(click)="closeDialog()"
			role="button"
			i18n-title
			title="Close"
		>
		</g-icon>
	</div>
	<div
		fxFlex="60"
		fxFlex.lt-sm="95"
		class="container"
		*ngIf="!(isRequestingToken$ | async) && !(isRequestingTokenSuccess$ | async)"
	>
		<p i18n="@@ForgottenPasswordInstructions"
			>Forgot your password? No worries, that happens! Type in your email below and we'll email you the
			reset instructions.</p
		>

		<form id="password-forgot" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
			<div class="form-group form-group-primary">
				<label for="email" i18n="@@EmailInput">Email</label>
				<input
					type="email"
					trim="blur"
					class="form-control form-control-primary"
					formControlName="username"
					gAutofocus
					autocomplete="work email"
				/>
				<div class="validation-error"> {{ formErrors.username }} </div>
				<button
					type="submit"
					class="btn btn-primary dialog-submit"
					[disabled]="form.invalid"
					i18n="@@SubmitForgottenPasswordInfo"
					>Submit</button
				>
			</div>
		</form>

		<button
			type="button"
			*ngIf="!isLoginLinkHidden"
			(click)="backToLogin()"
			class="btn btn-tertiary"
			i18n="@@BackToLoginButton"
			>Back to login</button
		>
	</div>

	<div
		fxFlex="60"
		fxFlex.lt-sm="95"
		class="container"
		*ngIf="(isRequestingToken$ | async) && !(isRequestingTokenSuccess$ | async)"
	>
		<p i18n="@ResettingPasswordText">Resetting your password... Please wait.</p>
	</div>

	<div
		fxFlex="60"
		fxFlex.lt-sm="95"
		class="container"
		*ngIf="!(isRequestingToken$ | async) && (isRequestingTokenSuccess$ | async)"
	>
		<p i18n="@@PasswordInstructionsBeenSent"
			>Password reset details have been sent. Please check your email for further instructions.</p
		>

		<button (click)="closeDialog()" class="btn btn-primary dialog-back">Close</button>
	</div>
</div>
