import { RootReducer, Store } from '@app/app.reducers';
import { close as closeDialog, getActiveDialogData } from '@app/dialog/reducers/dialog.reducer';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export class DialogBase {
	static MAX_WIDTH = '700px';
	dialogData$: Observable<unknown>;
	dialogData: unknown;
	icons: { [key: string]: IconDefinition } = { faXmark };

	constructor(protected store: Store<RootReducer.State>) {
		this.dialogData$ = this.store.select(getActiveDialogData).pipe(first());
	}

	public closeDialog() {
		this.store.dispatch(closeDialog());
	}
}
