import { Component } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { DialogBase } from '@app/dialog/dialog-base';

@Component({
	selector: 'g-terms-dialog',
	templateUrl: './terms-dialog.component.html',
	styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogComponent extends DialogBase {
	static MAX_WIDTH = '800px';
	constructor(protected store: Store<RootReducer.State>) {
		super(store);
	}
}
