import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { getProductCount } from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { StorePersistService } from '@app/checkout/modules/checkout-shared/services/store-persist.service';
import { LoginDialogComponent } from '@app/dialog/components/login-dialog/login-dialog.component';
import { DialogService } from '@app/dialog/services/dialog.service';
import { IconComponent } from '@app/shared/components/icon/icon.component';
import { getStorefront } from '@app/shared/reducers/storefront.reducer';
import { CognitoService } from '@app/shared/services/cognito.service';
import { filterTruthy } from '@app/shared/utils/util';
import { getCxmlToken } from '@app/user/reducers/auth.reducer';
import {
	getIsUserChecked,
	getUser,
	getUserPermissions,
	isLoggedUser,
} from '@app/user/reducers/user.reducer';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCartShopping } from '@fortawesome/pro-solid-svg-icons/faCartShopping';
import { faPersonDolly } from '@fortawesome/pro-solid-svg-icons/faPersonDolly';
import { faPersonDollyEmpty } from '@fortawesome/pro-solid-svg-icons/faPersonDollyEmpty';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '@src/environments/environment';
import { combineLatest } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

/** Navigation actions component */
@UntilDestroy()
@Component({
	standalone: true,
	imports: [AsyncPipe, NgClass, IconComponent, RouterLink, MatMenuModule, MatButton],
	selector: 'g-nav-actions',
	templateUrl: './nav-actions.component.html',
	styleUrls: ['./nav-actions.component.scss'],
})
export class NavActionsComponent {
	/**
	 * Mode of the component
	 * - full: User actions and cart icon
	 * - user: Only user actions
	 * - cart: Only cart icon
	 */
	@Input() mode: 'full' | 'user' | 'cart' = 'full';
	readonly icons = { faUser, faCartShopping, faPersonDollyEmpty, faPersonDolly, faCaretDown };
	readonly productCount$ = this.store.select(getProductCount).pipe(distinctUntilChanged());
	readonly isPunchout$ = this.store.select(getCxmlToken).pipe(filterTruthy);
	readonly isNewLayout = this.storePersistService.getFlag('enableNewLayout');
	readonly userPermissions$ = this.store.select(getUserPermissions);
	readonly isUserLoaded$ = this.store.select(getIsUserChecked);
	readonly user$ = this.store
		.select(getUser)
		.pipe(map((user) => (isLoggedUser(user) ? user : undefined)));
	readonly isApprovalAdmin$ = this.user$.pipe(
		map((user) => user?.permissions?.access_approval_workflow_products ?? false),
	);
	readonly adminUrl$ = combineLatest([
		this.store.select(getUser),
		this.store.select(getStorefront),
	]).pipe(
		map(([user, store]) => {
			if (user?.isSuperAdmin || user?.permissions?.access_storefront_admin)
				return environment.adminUrl
					.replaceAll('{{CUSTOMER_ID}}', `${store?.customer_id}`)
					.replaceAll('{{STORE_ID}}', `${store?.id}`)
					.replaceAll('{{BASE_URL}}', `${store?.base_url}`);
		}),
	);
	readonly openLoginDialog = (): void => {
		this.dialogService.open(LoginDialogComponent);
	};

	constructor(
		public readonly cognito: CognitoService,
		private readonly store: Store<RootReducer.State>,
		private readonly storePersistService: StorePersistService,
		private readonly dialogService: DialogService,
	) {}
}
