import { ProductProofImages, ProofImageService } from '../services/proof-image.service';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { FALLBACK_PRODUCT_IMAGE_SRC } from '@app/shared/common';

export type ProductSource = 'shopping-cart' | 'my-products';

export const PROOF_IMAGE_PATTERN = /.*\/GetProofImage\/(.*)\/.*\/.*/;

@Directive({ selector: '[gFallbackProductImage]' })
export class FallbackProductImageDirective {
	@HostBinding('src') @Input() src: string;
	@HostBinding('fallbackStateId') @Input() fallbackStateId?: string;
	@HostBinding('fallbackProductId') @Input() fallbackProductId?: string;
	@HostBinding('fallbackProductSource') @Input() fallbackProductSource?: ProductSource;

	constructor(private readonly proofImageService: ProofImageService) {}

	@HostListener('error')
	updateImageSrc(): void {
		// If this is an expired proof image, regenerate it
		if (this.fallbackStateId && PROOF_IMAGE_PATTERN.test(this.src)) {
			const [, userId] = PROOF_IMAGE_PATTERN.exec(this.src);
			const proofImages$ =
				this.fallbackProductSource === 'shopping-cart'
					? this.proofImageService.regenerateCartItemProofImages(
							this.fallbackProductId,
							this.fallbackStateId,
							userId,
					  )
					: this.proofImageService.regenerateMyProductProofImages(
							this.fallbackProductId,
							this.fallbackStateId,
							userId,
					  );
			proofImages$.subscribe(
				(images: ProductProofImages) => (this.src = images.proofImageThumbnail),
				// Default to fallback image on errors
				() => (this.src = FALLBACK_PRODUCT_IMAGE_SRC),
			);
			return;
		}

		this.src = FALLBACK_PRODUCT_IMAGE_SRC;
	}
}
