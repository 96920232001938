// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module AddressBook */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get AddressBooks by multiple user id
 * 
 * @param {object} body 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.AddressBook[]>} OK
 */
export function getAddressesByUserIds(body: {
    userIds?: string[]
  }, options?: GetAddressesByUserIdsOptions): Promise<api.Response<api.AddressBook[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body
    }
  };
  return gateway.request(getAddressesByUserIdsOperation, parameters);
}

/**
 * Get AddressBooks by store
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.AddressBook[]>} OK
 */
export function getAddresses(options?: GetAddressesOptions): Promise<api.Response<api.AddressBook[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getAddressesOperation, parameters);
}

/**
 * Get AddressBooks by user id
 * 
 * @param {string} userId 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.AddressBook[]>} OK
 */
export function getAddressesByUser(userId: string, options?: GetAddressesByUserOptions): Promise<api.Response<api.AddressBook[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      userId
    }
  };
  return gateway.request(getAddressesByUserOperation, parameters);
}

/**
 * Create address
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.AddressBook>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.AddressBook>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Get list of AddressBooks from database.
 */
export function all(): Promise<api.Response<api.AddressBook[]>> {
  return gateway.request(allOperation);
}

/**
 * Update address
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<module:types.AddressBook>} OK
 */
export function update(id: number, options?: UpdateOptions): Promise<api.Response<api.AddressBook>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOperation, parameters);
}

/**
 * Delete address
 * 
 * @param {number} id 
 * @return {Promise<module:types.AddressBook>} OK
 */
export function destroy(id: number): Promise<api.Response<api.AddressBook>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

/**
 * Get a single address
 * 
 * @param {number} id 
 * @return {Promise<module:types.AddressBook>} OK
 */
export function one(id: number): Promise<api.Response<api.AddressBook>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(oneOperation, parameters);
}

/**
 * Set default shipping and/or billing addresses for given store. Default addresses must be common addresses (is_common)
 * 
 * @param {number} storeId 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function setDefault(storeId: number, options?: SetDefaultOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      storeId
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(setDefaultOperation, parameters);
}

/**
 * Get AddressBook model schema describing the database table.
 */
export function schema(): Promise<api.Response<api.AddressBook>> {
  return gateway.request(schemaOperation);
}

export interface GetAddressesByUserIdsOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface GetAddressesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface GetAddressesByUserOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface CreateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    address?: {
      address_id?: number,
      address_name?: string,
      first_name?: string,
      last_name?: string,
      street_name?: string,
      second_address_line?: string,
      zip_code?: string,
      city?: string,
      company?: string,
      country?: string,
      country_code?: string,
      email?: string,
      phone?: string,
      edi_number?: string,
      operator_id?: string,
      operator?: string,
      sonet_number?: string,
      vat_number?: string
    },
    store_id?: number,
    user_id?: string,
    is_shipping?: boolean,
    is_billing?: boolean,
    is_user_default_shipping?: boolean,
    is_user_default_billing?: boolean,
    is_common?: boolean
  };
}

export interface UpdateOptions {
  body?: {
    address?: {
      address_id?: number,
      address_name?: string,
      first_name?: string,
      last_name?: string,
      street_name?: string,
      second_address_line?: string,
      zip_code?: string,
      city?: string,
      company?: string,
      country?: string,
      country_code?: string,
      email?: string,
      phone?: string,
      edi_number?: string,
      operator_id?: string,
      operator?: string,
      sonet_number?: string,
      vat_number?: string
    },
    store_id?: number,
    user_id?: string,
    is_shipping?: boolean,
    is_billing?: boolean,
    is_user_default_shipping?: boolean,
    is_user_default_billing?: boolean,
    is_common?: boolean
  };
}

export interface SetDefaultOptions {
  body?: {
    default_billing_address_id?: number,
    default_shipping_address_id?: number
  };
}

const getAddressesByUserIdsOperation: api.OperationInfo = {
  path: '/address-book/by-user-ids',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getAddressesOperation: api.OperationInfo = {
  path: '/address-book/by-store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getAddressesByUserOperation: api.OperationInfo = {
  path: '/address-book/by-user/{userId}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/address-book',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/address-book',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOperation: api.OperationInfo = {
  path: '/address-book/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/address-book/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/address-book/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const setDefaultOperation: api.OperationInfo = {
  path: '/address-book/set-default/{storeId}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const schemaOperation: api.OperationInfo = {
  path: '/address-book/schema',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
