// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Price */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Price is calculated depending on the product options
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.ProductPriceDto>} OK
 */
export function getPriceForProduct(id: number, options?: GetPriceForProductOptions): Promise<api.Response<api.ProductPriceDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(getPriceForProductOperation, parameters);
}

/**
 * Price is calculated depending on the products and options
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.OrderPriceDto>} OK
 */
export function getPriceForOrder(options?: GetPriceForOrderOptions): Promise<api.Response<api.OrderPriceDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(getPriceForOrderOperation, parameters);
}

/**
 * Get prices for different billing options & other fees.
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} Ok
 */
export function getPrices(options?: GetPricesOptions): Promise<api.Response<{
    billing?: api.BillingPrice[],
    additional?: api.AdditionalPrice[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getPricesOperation, parameters);
}

/**
 * Get prices for different shipping methods based on order weight
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function getShippingPrices(options?: GetShippingPricesOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(getShippingPricesOperation, parameters);
}

/**
 * Get all price data for a specific store needed to seed a database
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} OK
 */
export function getPriceDataForSeeds(options?: GetPriceDataForSeedsOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getPriceDataForSeedsOperation, parameters);
}

/**
 * List prices of type
 * 
 * @param {string} type Enum: product, product_price_group, material, billing, shipping, size, layout, finishing, additional, printing, starting, packaging, product_options, tax. 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {number} [options.limit] 
 * @param {number} [options.offset] 
 * @param {string} [options.sort=id] 
 * @param {string} [options.order=ASC] Enum: ASC, DESC. 
 * @param {string} [options.sortLang=fi] 
 * @return {Promise<object>} List of prices of the chosen type
 */
export function all(type: 'product'|'product_price_group'|'material'|'billing'|'shipping'|'size'|'layout'|'finishing'|'additional'|'printing'|'starting'|'packaging'|'product_options'|'tax', options?: AllOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      type
    },
    query: {
      limit: options.limit,
      offset: options.offset,
      sort: options.sort,
      order: options.order,
      sortLang: options.sortLang
    }
  };
  return gateway.request(allOperation, parameters);
}

/**
 * Automatically updates or creates price
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} The updated/created price
 */
export function setPrice(options?: SetPriceOptions): Promise<api.Response<api.ProductPrice | api.ProductPriceGroup | api.MaterialPrice | api.BillingPrice | api.ShippingPrice | api.SheetSize | api.SheetLayout | api.FinishingPrice | api.AdditionalPrice | api.PrintingPrice | api.StartingPrice | api.PackagingPrice | api.ProductOptionsPrice | api.TaxRate>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(setPriceOperation, parameters);
}

/**
 * Updates or creates prices
 * 
 * @param {string} type Enum: product, product_price_group, material, billing, shipping, size, layout, finishing, additional, printing, starting, packaging, product_options, tax. 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object[]} [options.body] 
 * @return {Promise<object[]>} The updated/created prices
 */
export function setPrices(type: 'product'|'product_price_group'|'material'|'billing'|'shipping'|'size'|'layout'|'finishing'|'additional'|'printing'|'starting'|'packaging'|'product_options'|'tax', options?: SetPricesOptions): Promise<api.Response<(api.ProductPrice | api.ProductPriceGroup | api.MaterialPrice | api.BillingPrice | api.ShippingPrice | api.SheetSize | api.SheetLayout | api.FinishingPrice | api.AdditionalPrice | api.PrintingPrice | api.StartingPrice | api.PackagingPrice | api.ProductOptionsPrice | api.TaxRate)[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      type
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(setPricesOperation, parameters);
}

/**
 * Delete a price from the database
 * 
 * @param {string} type Enum: product, product_price_group, material, billing, shipping, size, layout, finishing, additional, printing, starting, packaging, product_options, tax. 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} The price was deleted
 */
export function destroy(type: 'product'|'product_price_group'|'material'|'billing'|'shipping'|'size'|'layout'|'finishing'|'additional'|'printing'|'starting'|'packaging'|'product_options'|'tax', id: number, options?: DestroyOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      type,
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

export interface GetPriceForProductOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: any;
}

export interface GetPriceForOrderOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    items?: {
      id?: number,
      uuid?: string,
      title?: string,
      work_title?: string,
      mylly_id?: string,
      mylly_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      logia_wms_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      cxml_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      printfile?: {
        id: number,
        key: string,
        filename: string,
        url: string
      },
      productSource?: 'new-product'|'my-product'|'previous-order',
      addedToOrderAt?: number,
      price?: {
        starting_price?: string,
        packaging_price?: string,
        material_price?: string,
        printing_price?: string,
        finishing_price?: string,
        price_without_margin?: string,
        material_consumption?: string,
        discounted_price_without_tax: string,
        discounted_price: string,
        price_with_margin: string,
        tax_multiplier: string,
        price_with_tax: string,
        price_without_tax: string,
        tax_amount: string,
        additional_fee?: string,
        size?: {
          width: number,
          height: number
        }
      },
      options?: {
        printfile_key?: string,
        selected_options?: any
      },
      product_flow_data?: {
        steps?: string[],
        selected_workflow?: {
          key?: string,
          steps?: string[]
        },
        scaled_proof_images?: string[],
        proof_image_thumbnail?: string,
        design_service?: any,
        is_design_validated?: boolean
      },
      product_storage?: {
        storage_type?: string,
        stock_level?: number
      },
      product?: api.Product,
      approval_comment?: string,
      approval_status?: 'Pending'|'Approved'|'Rejected',
      reviewed_at?: string,
      reviewed_by?: string
    }[],
    metadata?: {
      shipping_method?: string,
      billing_method?: string,
      total_price?: any
    },
    discount_code?: string
  };
}

export interface GetPricesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface GetShippingPricesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    items?: {
      id?: number,
      uuid?: string,
      title?: string,
      work_title?: string,
      mylly_id?: string,
      mylly_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      logia_wms_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      cxml_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      printfile?: {
        id: number,
        key: string,
        filename: string,
        url: string
      },
      productSource?: 'new-product'|'my-product'|'previous-order',
      addedToOrderAt?: number,
      price?: {
        starting_price?: string,
        packaging_price?: string,
        material_price?: string,
        printing_price?: string,
        finishing_price?: string,
        price_without_margin?: string,
        material_consumption?: string,
        discounted_price_without_tax: string,
        discounted_price: string,
        price_with_margin: string,
        tax_multiplier: string,
        price_with_tax: string,
        price_without_tax: string,
        tax_amount: string,
        additional_fee?: string,
        size?: {
          width: number,
          height: number
        }
      },
      options?: {
        printfile_key?: string,
        selected_options?: any
      },
      product_flow_data?: {
        steps?: string[],
        selected_workflow?: {
          key?: string,
          steps?: string[]
        },
        scaled_proof_images?: string[],
        proof_image_thumbnail?: string,
        design_service?: any,
        is_design_validated?: boolean
      },
      product_storage?: {
        storage_type?: string,
        stock_level?: number
      },
      product?: api.Product,
      approval_comment?: string,
      approval_status?: 'Pending'|'Approved'|'Rejected',
      reviewed_at?: string,
      reviewed_by?: string
    }[]
  };
}

export interface GetPriceDataForSeedsOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface AllOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  order?: 'ASC'|'DESC';
  sortLang?: string;
}

export interface SetPriceOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    priceType: 'product',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    range: string,
    product_id: string,
    product?: api.Product,
    starting_price: string,
    price: string,
    created_at?: string,
    updated_at?: string
  } | {
    priceType: 'product_price_group',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    range: string,
    starting_price: string,
    product_count?: number
  } | {
    priceType: 'material',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    price_unit: 'Sheet'|'m2',
    weight: number,
    sheet_width?: number,
    sheet_height?: number
  } | {
    priceType: 'billing',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    locale?: {
      name?: {
        fi?: string,
        en?: string
      }
    }
  } | {
    priceType: 'shipping',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    range?: string,
    shipping_method?: api.ShippingMethod
  } | {
    priceType: 'size',
    id?: number,
    name: string,
    width: number,
    height: number,
    created_at?: string,
    updated_at?: string
  } | {
    priceType: 'layout',
    id?: number,
    name: string,
    amount: number,
    size: string,
    created_at?: string,
    updated_at?: string
  } | {
    priceType: 'finishing',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    range: string,
    base: string,
    start: string
  } | {
    priceType: 'additional',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    type: 'premedia'|'handling'
  } | {
    priceType: 'printing',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string,
    type: 'm2'|'Sheet'
  } | {
    priceType: 'starting',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string
  } | {
    priceType: 'packaging',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    name: string,
    price: string,
    created_at?: string,
    updated_at?: string
  } | {
    priceType: 'product_options',
    id?: number,
    store?: api.Store,
    store_id?: number,
    customer_id?: number,
    product_id?: string,
    product?: api.Product,
    field: string,
    value: string[],
    range: string,
    starting_price: string,
    price: string,
    created_at?: string,
    updated_at?: string
  } | {
    priceType: 'tax',
    id?: number,
    name: string,
    rate: string,
    created_at?: string,
    updated_at?: string
  };
}

export interface SetPricesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: any[];
}

export interface DestroyOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

const getPriceForProductOperation: api.OperationInfo = {
  path: '/price/product/{id}',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getPriceForOrderOperation: api.OperationInfo = {
  path: '/price/order',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getPricesOperation: api.OperationInfo = {
  path: '/price/prices',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getShippingPricesOperation: api.OperationInfo = {
  path: '/price/shipping-prices',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getPriceDataForSeedsOperation: api.OperationInfo = {
  path: '/price/for-seeds',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/price/{type}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const setPriceOperation: api.OperationInfo = {
  path: '/price',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const setPricesOperation: api.OperationInfo = {
  path: '/price/multi/{type}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/price/{type}/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
