import { AddToWishlistDialogComponent } from './components/add-to-wishlist-dialog/add-to-wishlist-dialog.component';
import { ImageViewerDialogComponent } from './components/image-viewer-dialog/image-viewer-dialog.component';
import { DialogService } from './services/dialog.service';
import { NgModule } from '@angular/core';
import { ChangePasswordDialogComponent } from '@app/dialog/components/change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from '@app/dialog/components/confirm-dialog/confirm-dialog.component';
import { LoginDialogComponent } from '@app/dialog/components/login-dialog/login-dialog.component';
import { PasswordRequestDialogComponent } from '@app/dialog/components/password-request-dialog/password-request-dialog.component';
import { TermsDialogComponent } from '@app/dialog/components/terms-dialog/terms-dialog.component';
import { IconComponent } from '@app/shared/components/icon/icon.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

// TODO: Move to the module where they are used.
const DIALOGS = [
	LoginDialogComponent,
	TermsDialogComponent,
	ChangePasswordDialogComponent,
	PasswordRequestDialogComponent,
	ConfirmDialogComponent,
	ImageViewerDialogComponent,
	AddToWishlistDialogComponent,
];

@NgModule({
	imports: [SharedModule, NgbModalModule, IconComponent],
	declarations: DIALOGS,
	providers: [DialogService],
})
export class DialogModule {}
