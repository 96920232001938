// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module ShippingMethod */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get all shipping methods for a specific store
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.ShippingMethod[]>} OK
 */
export function byStore(options?: ByStoreOptions): Promise<api.Response<api.ShippingMethod[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(byStoreOperation, parameters);
}

/**
 * Get allowed shipping methods for a specific store
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.ShippingMethod[]>} OK
 */
export function allowedShippingMethods(options?: AllowedShippingMethodsOptions): Promise<api.Response<api.ShippingMethod[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(allowedShippingMethodsOperation, parameters);
}

/**
 * Check if provided date is a valid business day for a specific shipping method
 * 
 * @param {string} date 
 * @param {number} minDeliveryTime 
 * @return {Promise<object>} OK
 */
export function isValidDeliveryDate(date: string, minDeliveryTime: number): Promise<api.Response<{
    result?: boolean,
    date?: string,
    minDeliveryTime?: number
  }>> {
  const parameters: api.OperationParamGroups = {
    path: {
      date
    },
    query: {
      minDeliveryTime
    }
  };
  return gateway.request(isValidDeliveryDateOperation, parameters);
}

/**
 * Get all possible delivery dates for available shipping methods
 * 
 * @param {string} products 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object>} OK
 */
export function getDeliveryDates(products: string, options?: GetDeliveryDatesOptions): Promise<api.Response<{
    
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      products
    }
  };
  return gateway.request(getDeliveryDatesOperation, parameters);
}

/**
 * Get list of ShippingMethods from database.
 */
export function all(): Promise<api.Response<api.ShippingMethod[]>> {
  return gateway.request(allOperation);
}

/**
 * Create new ShippingMethod in database.
 * 
 * @param {object} options Optional options
 * @param {module:types.ShippingMethod} [options.body] 
 * @return {Promise<module:types.ShippingMethod>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.ShippingMethod>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Get ShippingMethod model schema describing the database table.
 */
export function schema(): Promise<api.Response<api.ShippingMethod>> {
  return gateway.request(schemaOperation);
}

/**
 * Get ShippingMethod details from database.
 * 
 * @param {number} id 
 * @return {Promise<module:types.ShippingMethod>} OK
 */
export function one(id: number): Promise<api.Response<api.ShippingMethod>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(oneOperation, parameters);
}

/**
 * Destroy ShippingMethods from database.
 * 
 * @param {number} id 
 * @return {Promise<object>} OK
 */
export function destroy(id: number): Promise<api.Response<undefined>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

/**
 * Update ShippingMethod in database
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {module:types.ShippingMethod} [options.body] 
 * @return {Promise<module:types.ShippingMethod>} OK
 */
export function update(id: number, options?: UpdateOptions): Promise<api.Response<api.ShippingMethod>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOperation, parameters);
}

export interface ByStoreOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface AllowedShippingMethodsOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface GetDeliveryDatesOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface CreateOptions {
  body?: api.ShippingMethod;
}

export interface UpdateOptions {
  body?: api.ShippingMethod;
}

const byStoreOperation: api.OperationInfo = {
  path: '/shipping-method/by-store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allowedShippingMethodsOperation: api.OperationInfo = {
  path: '/shipping-method/allowed',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const isValidDeliveryDateOperation: api.OperationInfo = {
  path: '/shipping-method/is-valid/{date}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getDeliveryDatesOperation: api.OperationInfo = {
  path: '/shipping-method/delivery-dates',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/shipping-method',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/shipping-method',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const schemaOperation: api.OperationInfo = {
  path: '/shipping-method/schema',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/shipping-method/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/shipping-method/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOperation: api.OperationInfo = {
  path: '/shipping-method/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
