<p class="title">{{ title }}</p>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="14px">
	<button
		class="btn btn-secondary"
		(click)="rejectHandler()"
		fxFlex="50%"
		gAutofocus
		data-cy="confirmDialogReject"
		>{{ rejectLabel }}</button
	>
	<button class="btn btn-primary" (click)="acceptHandler()" fxFlex="50%" data-cy="confirmDialogAccept"
		>{{ acceptLabel }}
	</button>
</div>
