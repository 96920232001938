import { RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

export const SET_COGNITO_USER = 'mygrano/auth/SET_COGNITO_USER';
export const setCognitoUser = (user: Record<string, string>) =>
	({
		type: SET_COGNITO_USER,
		payload: user,
	}) as const;

export const LOGOUT = 'mygrano/auth/LOGOUT';
export const logout = () => ({ type: LOGOUT }) as const;

export const SET_IS_AUTHENTICATING = 'mygrano/auth/SET_IS_AUTHENTICATING';
export const setIsAuthenticating = (isAuthenticating: boolean) =>
	({
		type: SET_IS_AUTHENTICATING,
		payload: { isAuthenticating },
	}) as const;

export const SET_IS_MAINTENANCE_MODE = 'mygrano/auth/SET_IS_MAINTENANCE_MODE';
export const setIsMaintenanceMode = (isMaintenanceMode: boolean) =>
	({
		type: SET_IS_MAINTENANCE_MODE,
		payload: { isMaintenanceMode },
	}) as const;

export const SET_CRITICAL_COGNITO_ERROR = 'mygrano/auth/SET_CRITICAL_COGNITO_ERROR';
export const setCriticalCognitoError = (error: Error | undefined) =>
	({
		type: SET_CRITICAL_COGNITO_ERROR,
		payload: { error },
	}) as const;

export const SET_CXML_TOKEN = 'mygrano/auth/SET_CXML_TOKEN';
export const setCxmlToken = (cxmlToken: string) =>
	({
		type: SET_CXML_TOKEN,
		payload: { cxmlToken },
	}) as const;

export const RESET_LOGOUT = 'mygrano/auth/RESET_LOGOUT';
export const resetLogout = () => ({ type: RESET_LOGOUT }) as const;

export interface State {
	cognitoUser?: Record<string, string>;
	isInitialized?: boolean;
	isAuthenticating?: boolean;
	hasLoggedOut?: boolean;
	isMaintenanceMode?: boolean;
	cxmlToken?: string;
	criticalCognitoError?: Error;
}

export const initialState: State = {
	cxmlToken: sessionStorage.getItem('cxml_token') || null,
	hasLoggedOut: false,
};

type AuthAction = ReturnType<
	| typeof setIsAuthenticating
	| typeof setIsMaintenanceMode
	| typeof setCriticalCognitoError
	| typeof setCxmlToken
	| typeof setCognitoUser
	| typeof resetLogout
	| typeof logout
>;

export function reducer(state = initialState, action: AuthAction): State {
	switch (action.type) {
		case SET_COGNITO_USER: {
			const cognitoUser = action.payload?.id ? action.payload : undefined;
			return { ...state, isInitialized: !!cognitoUser, cognitoUser };
		}
		case LOGOUT:
			// Keep session storage up to date.
			sessionStorage.removeItem('cxml_token');
			return { ...initialState, cxmlToken: null, hasLoggedOut: true };

		case RESET_LOGOUT:
			// Reset logout state after logout actions are complete
			return { ...initialState, cxmlToken: null, hasLoggedOut: false };

		case SET_IS_AUTHENTICATING:
			return { ...state, isAuthenticating: action.payload.isAuthenticating };

		case SET_IS_MAINTENANCE_MODE:
			return { ...state, isMaintenanceMode: action.payload.isMaintenanceMode };

		case SET_CRITICAL_COGNITO_ERROR:
			return { ...state, criticalCognitoError: action.payload.error };

		case SET_CXML_TOKEN:
			// Keep session storage up to date.
			if (!action.payload.cxmlToken) sessionStorage.removeItem('cxml_token');
			else sessionStorage.setItem('cxml_token', `${action.payload.cxmlToken}`);
			return { ...state, cxmlToken: action.payload.cxmlToken };

		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.auth;
export const getAuthState = setName(
	'getAuthState',
	createSelector(getState, (state) => state.cognitoUser !== undefined),
);
export const getInitAuthState = setName(
	'getInitAuthState',
	createSelector(getState, (state) => state.isInitialized),
);
export const getCognitoUser = setName(
	'getCognitoUser',
	createSelector(getState, (state) => state.cognitoUser),
);
export const getIsAuthenticating = setName(
	'getIsAuthenticating',
	createSelector(getState, (state) => state.isAuthenticating),
);
export const getIsMaintenanceMode = setName(
	'getIsMaintenanceMode',
	createSelector(getState, (state) => state.isMaintenanceMode),
);
export const getCriticalCognitoError = setName(
	'getCriticalCognitoError',
	createSelector(getState, (state) => state.criticalCognitoError),
);
export const getCxmlToken = setName(
	'getCxmlToken',
	createSelector(getState, (state) => state.cxmlToken),
);

export const hasLoggedOut = setName(
	'hasLoggedOut',
	createSelector(getState, (state) => state.hasLoggedOut),
);
