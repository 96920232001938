// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/ContactRequest */
// Auto-generated, edits will be overwritten
import * as ContactRequest from '../ContactRequest';

export const SEND_START = 's/ContactRequest/SEND_START';
export const SEND = 's/ContactRequest/SEND';
export type SEND = any;

export function send(options?: ContactRequest.SendOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SEND_START, meta: { info, params: { options } } });
    return ContactRequest.send(options)
      .then(response => dispatch({
        type: SEND,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
