<span data-cy="orderStatus" [ngSwitch]="orderStatus">
	<span *ngSwitchCase="'In Process'" i18n>Processing</span>
	<span *ngSwitchCase="'In Delivery'" i18n>In Delivery</span>
	<span *ngSwitchCase="'Completed'" i18n>Complete</span>
	<span *ngSwitchCase="'Cancelled'" i18n>Cancelled</span>
	<span *ngSwitchCase="'Waiting Payment'" i18n>Waiting for Payment</span>
	<span *ngSwitchCase="'Waiting Sonet'" i18n>Processing</span>
	<span *ngSwitchCase="'Waiting Approval'" i18n>Waiting for Approval</span>

	<span *ngSwitchDefault>{{ defaultSwitch || orderStatus }}</span>
</span>
