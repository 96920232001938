import { Component, Input } from '@angular/core';
import { FaultCode } from '@app/shared/services/fault-code.service';

@Component({
	selector: 'g-fault-page',
	templateUrl: './fault-page.component.html',
	styleUrls: ['./fault-page.component.scss'],
})
export class FaultPageComponent {
	@Input() faultCode: FaultCode;
	faultCodes = FaultCode;
}
