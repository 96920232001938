// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Users */
// Auto-generated, edits will be overwritten
import * as Users from '../Users';

export const UPDATE_START = 's/Users/UPDATE_START';
export const UPDATE = 's/Users/UPDATE';
export type UPDATE = api.UserDto;

export function update(id: string, userUpdateRequest: api.UserUpdateRequestDto, options?: Users.UpdateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_START, meta: { info, params: { id, userUpdateRequest, options } } });
    return Users.update(id, userUpdateRequest, options)
      .then(response => dispatch({
        type: UPDATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_BY_CUSTOMER_ID_START = 's/Users/UPDATE_BY_CUSTOMER_ID_START';
export const UPDATE_BY_CUSTOMER_ID = 's/Users/UPDATE_BY_CUSTOMER_ID';
export type UPDATE_BY_CUSTOMER_ID = api.UserDto;

export function updateByCustomerId(customerId: number, id: string, body: api.UserUpdateRequestDto, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_BY_CUSTOMER_ID_START, meta: { info, params: { customerId, id, body } } });
    return Users.updateByCustomerId(customerId, id, body)
      .then(response => dispatch({
        type: UPDATE_BY_CUSTOMER_ID,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SEARCH_IN_CUSTOMER_OR_SERVICE_START = 's/Users/SEARCH_IN_CUSTOMER_OR_SERVICE_START';
export const SEARCH_IN_CUSTOMER_OR_SERVICE = 's/Users/SEARCH_IN_CUSTOMER_OR_SERVICE';
export type SEARCH_IN_CUSTOMER_OR_SERVICE = api.UserDto[];

export function searchInCustomerOrService(searchTerm: string, options?: Users.SearchInCustomerOrServiceOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SEARCH_IN_CUSTOMER_OR_SERVICE_START, meta: { info, params: { searchTerm, options } } });
    return Users.searchInCustomerOrService(searchTerm, options)
      .then(response => dispatch({
        type: SEARCH_IN_CUSTOMER_OR_SERVICE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GRANT_SUPER_ADMIN_START = 's/Users/GRANT_SUPER_ADMIN_START';
export const GRANT_SUPER_ADMIN = 's/Users/GRANT_SUPER_ADMIN';
export type GRANT_SUPER_ADMIN = api.UserCasGroupDto[];

export function grantSuperAdmin(userId: string, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GRANT_SUPER_ADMIN_START, meta: { info, params: { userId } } });
    return Users.grantSuperAdmin(userId)
      .then(response => dispatch({
        type: GRANT_SUPER_ADMIN,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const REVOKE_SUPER_ADMIN_START = 's/Users/REVOKE_SUPER_ADMIN_START';
export const REVOKE_SUPER_ADMIN = 's/Users/REVOKE_SUPER_ADMIN';
export type REVOKE_SUPER_ADMIN = api.UserCasGroupDto[];

export function revokeSuperAdmin(userId: string, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: REVOKE_SUPER_ADMIN_START, meta: { info, params: { userId } } });
    return Users.revokeSuperAdmin(userId)
      .then(response => dispatch({
        type: REVOKE_SUPER_ADMIN,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
