import { type ResponsiveImage } from '../directives/responsive-image.directive';
import type { StoreWithDetails } from '../reducers/storefront.reducer';
import { Injectable } from '@angular/core';

/**
 * This service gives synchronous access to the store data.
 * For example in a pipe, you can inject this service and use it to get the store data.
 * The data itself is set in the appInitializer.
 */
@Injectable({ providedIn: 'root' })
export class StoreDataService {
	public readonly storeData!: StoreWithDetails;

	/** The store id. */
	get storeId(): number {
		return this.storeData.id;
	}

	/** Returns the mobile logo with src and srcset attributes. */
	get mobileLogo(): ResponsiveImage | undefined {
		const images = this.storeData?.images;
		return images
			? {
					src: images.mobileLogo,
					srcSet: `${images.mobileLogo}, ${images.mobileLogo128} 2x, ${images.mobileLogo256} 3x`,
				}
			: undefined;
	}

	/** Returns the desktop logo with src and srcset attributes. */
	get logo(): ResponsiveImage | undefined {
		const images = this.storeData?.images;
		return images
			? {
					src: images.logo,
					srcSet: `${images.logo}, ${images.logo300} 2x, ${images.logo600} 3x`,
				}
			: undefined;
	}
}
