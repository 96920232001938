// spell-checker: enableCompoundWords
import { GET_CATEGORIES } from '@app/api/action/Category';
import { ApiAction, RootReducer } from '@app/app.reducers';
import { I18nService } from '@app/shared/utils/i18n.service';
import { setName } from '@app/shared/utils/name-helper';
import { sortCategories } from '@app/shared/utils/order-category';
import { transformSlugs } from '@app/shared/utils/util';
import Debug from 'debug';
import { createSelector } from 'reselect';

const debug = Debug('ecom:category');

const IGNORED_CATEGORIES = new Set(['assets']);

const RESET_CATEGORY_STATE = 'mygrano/category/RESET_CATEGORY_STATE';
export const resetCategoryState = () => ({ type: RESET_CATEGORY_STATE }) as const;

const SET_SELECTED_CATEGORY = 'mygrano/category/SET_SELECTED_CATEGORY';
export const setSelectedCategory = (category?: api.CategoryDto) =>
	({ type: SET_SELECTED_CATEGORY, payload: category }) as const;

export interface State {
	categories?: api.CategoryDto[];
	categoryTree?: api.CategoryDto[];
	selectedCategory?: api.CategoryDto;
	newCategoryProducts?: api.ProductDto[];
	campaignProducts?: api.ProductDto[];
}

export const initialState: State = {
	categories: undefined,
	categoryTree: undefined,
	selectedCategory: undefined,
	newCategoryProducts: undefined,
	campaignProducts: undefined,
};

/**
 * Build tree structure out of category paths
 * @param categories Categories to build tree from
 * @returns Categories as a tree structure
 */
export function buildCategoryTree(categories: api.CategoryDto[]): api.CategoryDto[] {
	// Let's clone the categories to avoid modifying the original objects.
	categories = structuredClone(categories);
	const tree: api.CategoryDto[] = [];
	const pathMap = new Map<string, api.CategoryDto>(categories.map((cat) => [cat.path ?? '', cat]));
	for (const cat of categories) {
		const pathParts = cat.path?.split('.') ?? [];
		const parentPath = pathParts.slice(0, -1).join('.');
		const parent = pathMap.get(parentPath);
		const isRoot = pathParts.length <= 2;
		if (isRoot) {
			tree.push(cat);
			continue;
		}
		if (!parent) {
			debug(`No parent for "${cat.locale?.name?.en}" at ${cat.path}, parent visibility restricted?`);
			continue;
		}
		// Update the children of the parent.
		if (!parent?.children) parent.children = [];
		parent.children.push(cat);
	}
	return tree;
}

type Action =
	| ReturnType<typeof resetCategoryState>
	| ReturnType<typeof setSelectedCategory>
	| ApiAction<typeof GET_CATEGORIES, api.CategoryDto[]>;

/** Category reducer */
export function reducer(state = initialState, action: Action) {
	switch (action.type) {
		case RESET_CATEGORY_STATE:
			return initialState;

		case GET_CATEGORIES: {
			if (action.error === true) return { ...state, categories: undefined, categoryTree: undefined };
			const categories = transformSlugs(action.payload);
			return {
				...state,
				categories,
				categoryTree: buildCategoryTree(categories),
			};
		}

		case SET_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload };

		/* istanbul ignore next */
		default:
			return state;
	}
}

export const getState = setName('getState', (state: RootReducer.State) => state.category);
export const getCategories = setName(
	'getCategories',
	createSelector(getState, (state) => state.categories),
);
export const getCategoryTree = setName(
	'getCategoryTree',
	createSelector(getState, (state) => state.categoryTree),
);

export const getSelectedCategory = setName(
	'getSelectedCategory',
	createSelector(getState, (state) => state.selectedCategory),
);

/** Get generic categories ordered asc. */
export const getGenericCategories = (i18n: I18nService) =>
	setName(
		'getGenericCategories',
		createSelector(getState, (state) => {
			const filteredCategories = state.categories?.filter?.(
				(category: api.CategoryDto) =>
					!category.metadata?.categorytype && !IGNORED_CATEGORIES.has(category.slug!),
			);
			return filteredCategories && sortCategories(filteredCategories, i18n);
		}),
	);

export const getMainCategories = (i18n: I18nService) =>
	setName(
		'getMainCategories',
		createSelector(
			getGenericCategories(i18n),
			(categories: api.CategoryDto[] = []): api.CategoryDto[] => {
				return categories?.filter((category) => category.path?.split('.').length === 2) ?? [];
			},
		),
	);

/** Get generic categoryTree ordered asc. */
export const getGenericCategoryTree = (i18n: I18nService) =>
	setName(
		'getGenericCategoryTree',
		createSelector(getState, (state) => {
			const filteredCategories = state.categoryTree?.filter?.(
				(category: api.CategoryDto) =>
					!category.metadata.categorytype && !IGNORED_CATEGORIES.has(category.slug!),
			);
			return filteredCategories && sortCategories(filteredCategories, i18n);
		}),
	);

export const getGenericCategoryForProduct = (product: api.ProductDto, i18n: I18nService) =>
	setName(
		'getGenericCategoryForProduct',
		createSelector(
			getGenericCategories(i18n),
			(categories: api.CategoryDto[] = []): api.CategoryDto | undefined => {
				return product
					? categories?.find((category: api.CategoryDto) =>
							product?.categories?.map((c) => c.path)?.includes?.(category.path),
						)
					: undefined;
			},
		),
	);

export const getCategoryBySlug = (slug: string) =>
	setName(
		'getCategoryBySlug',
		createSelector(getCategories, (categories?: api.CategoryDto[]) =>
			categories?.find?.((category) => category.slug === slug),
		),
	);
