import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fetchContent } from '@app/api/action/Content';
import { RootReducer, Store } from '@app/app.reducers';
import { getContentByKey, getIsLoading, PageContent } from '@app/core/reducers/content.reducer';
import { SeoService } from '@app/shared/services/seo.service';
import { I18nService } from '@app/shared/utils/i18n.service';
import { isSuccess } from '@app/shared/utils/remote-data';
import { filterTruthy, firstTruthy } from '@app/shared/utils/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'g-content-page',
	templateUrl: './content-page.component.html',
	styleUrls: ['./content-page.component.scss'],
})
export class ContentPageComponent implements OnInit {
	@Input() key?: string;
	content$?: Observable<PageContent | undefined>;
	showPageNotFound$!: Observable<boolean>;
	isLoading$!: Observable<boolean>;

	constructor(
		public readonly i18n: I18nService,
		private readonly store: Store<RootReducer.State>,
		private readonly route: ActivatedRoute,
		private readonly sanitizer: DomSanitizer,
		private readonly metaService: Meta,
		private readonly seo: SeoService,
	) {}

	ngOnInit(): void {
		if (this.key) this.getContentByFetching();
		else this.getContentFromRouteResolve();

		this.content$!.pipe(firstTruthy).subscribe((content) => this.seo.deduceProperties(content));
		this.showPageNotFound$ = this.content$!.pipe(
			delay(500), // prevent blinking "not found" message
			map((content) => !content),
		);
		this.isLoading$ = this.store.select(getIsLoading);

		// Tell search engines not to index this page if it's a 404
		this.showPageNotFound$
			.pipe(untilDestroyed(this), filterTruthy)
			.subscribe(() => this.metaService.addTag({ name: 'robots', content: 'noindex, follow' }));
	}

	getHtml(content: PageContent): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content?.html || '');
	}

	private getContentByFetching() {
		this.content$ = this.store
			.select(getContentByKey<PageContent>(this.key!))
			.pipe(map((content) => (isSuccess(content) ? content.data : undefined)));
		this.store.dispatch(fetchContent(this.i18n.locale, this.key!));
	}

	private getContentFromRouteResolve() {
		this.content$ = this.route.data.pipe(
			filterTruthy,
			map((data) => data.content as PageContent),
		);
	}
}
