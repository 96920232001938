import { ApiAction, ApiError, isApiError } from './api-utils';
import { castArray, constant } from './util';
import { Pipe, PipeTransform } from '@angular/core';
import {
	combineLatest,
	firstValueFrom,
	from,
	identity,
	merge,
	Observable,
	ObservableInput,
	ObservableInputTuple,
	of,
	OperatorFunction,
	pipe,
} from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';

export type RemoteData<E = never, D = never> = Initialized | Pending | Failure<E> | Success<D>;

export enum Kinds {
	Initialized = 'Initialized',
	Pending = 'Pending',
	Failure = 'Failure',
	Success = 'Success',
}

/** A RemoteData object that represents a state of being initialized. */
export class Initialized {
	readonly kind = Kinds.Initialized;
}

/** A RemoteData object that represents a state of being pending. */
export class Pending {
	readonly kind = Kinds.Pending;
}

/** A RemoteData object that represents a state of failure. */
export class Failure<E> {
	readonly kind = Kinds.Failure;
	constructor(public error: E) {
		if (error === null || error === undefined) throw new TypeError('Parameter "error" is required');
	}
}

/** A RemoteData object that represents a state of success. */
export class Success<D> {
	readonly kind = Kinds.Success;
	constructor(public data: D) {
		if (data === null || data === undefined) throw new TypeError('Parameter "data" is required');
	}
}

/** A RemoteData error that represents an unknown state. */
class NeverError extends Error {
	constructor(value: never) {
		super(`Unknown RemoteData state: ${value as string}`);
	}
}

/**
 * Takes a `RemoteData` object and callbacks for all possible states and returns the result of
 * the corresponding callback.
 */
export function fold<T, E, D>(
	initialized: () => T,
	pending: () => T,
	failure: (error: E) => T,
	success: (data: D) => T,
): (state: RemoteData<E, D>) => T {
	return (state: RemoteData<E, D>) => {
		switch (state?.kind) {
			case Kinds.Initialized:
				return initialized();
			case Kinds.Pending:
				return pending();
			case Kinds.Failure:
				return failure(state.error);
			case Kinds.Success:
				return success(state.data);
			default:
				throw new NeverError(state);
		}
	};
}

export const isInitialized = (obj: unknown): obj is Initialized => obj instanceof Initialized;
export const isPending = (obj: unknown): obj is Pending => obj instanceof Pending;
export const isSuccess = <D = unknown>(obj: unknown): obj is Success<D> => obj instanceof Success;
export const isFailure = <E = unknown>(obj: unknown): obj is Failure<E> => obj instanceof Failure;
export const isRemoteData = <E = unknown, D = unknown>(obj: unknown): obj is RemoteData<E, D> =>
	isInitialized(obj) || isPending(obj) || isSuccess(obj) || isFailure(obj);
export const isResolved = <E, D>(obj: RemoteData<E, D>): boolean => isFailure(obj) || isSuccess(obj);

/** Like `fold`, but returns `undefined` for all states except `Success` which returns the value. */
export function foldSuccess<T>(state: RemoteData<unknown, T>): T | undefined {
	return fold<T | undefined, unknown, unknown>(
		constant(undefined),
		constant(undefined),
		constant(undefined),
		(x) => x as T,
	)(state);
}

// Helpers for the API state //
//////////////////////////////

export { type ApiError } from './api-utils';
export type ApiState<T> = Failure<ApiError> | Success<T> | Pending | Initialized;
export type ApiSelector<T> = Observable<RemoteData<ApiError, T>>;

/** Convert an API Action into a RemoteData object. */
export function handleApiAction<
	TPayload,
	TMapper extends (payload: TPayload) => unknown = (payload: TPayload) => TPayload,
>(
	action: ApiAction<unknown, TPayload>,
	payloadMapper: TMapper = ((payload) => payload) as TMapper,
): ApiState<ReturnType<TMapper>> {
	return action.error === true
		? new Failure(action.payload)
		: new Success(payloadMapper(action.payload) as ReturnType<TMapper>);
}

/** Convert an API Response into a RemoteData object */
export function convertToRemoteData<T>(
	res: api.Response<T> | api.Response<ApiError>,
): Failure<string> | Success<T> {
	return isApiError(res)
		? new Failure(res.data?.message || 'Unknown API error')
		: new Success(res.data!);
}

// Helpers for RxJS //
/////////////////////

/** Filter an observable for `Success` values and emit the bare value */
export const pluckSuccessData = <T>(s: Observable<RemoteData<unknown, T>>): Observable<T> =>
	pipe(
		filter(isSuccess),
		map((s) => s.data),
	)(s);

/** Creates an observable that emits `Initialized` first. */
export function initRemoteData$<E, D>(
	source: Observable<RemoteData<E, D>>,
): Observable<RemoteData<E, D>> {
	return merge(of(new Initialized()), source);
}

/** like `switchMap` but converts api operations to remote data. */
export function switchMapRemoteData<T, U>(
	cb: (value: T) => ObservableInput<api.Response<U>>,
): OperatorFunction<T, Pending | Failure<string> | Success<U>> {
	return switchMap((value) =>
		merge(
			of(new Pending()),
			from(cb(value)).pipe(
				map(convertToRemoteData),
				catchError((e: Error) => of(new Failure(e.message))),
			),
		),
	);
}

/** Fetch remote data from the API based on inputs. */
export function fetchRemoteData$<D, P extends readonly unknown[]>(
	input: readonly [...ObservableInputTuple<P>],
	operation: (...p: P) => ObservableInput<api.Response<D>>,
	{ debounce = 500 }: { debounce?: number } = {},
): Observable<RemoteData<string, D>> {
	return initRemoteData$(
		combineLatest<P>(input).pipe(
			debounce ? debounceTime(debounce) : identity,
			switchMapRemoteData((params) => operation(...params)),
		),
	);
}

/** Like RxJS `firstValueFrom` but for RemoteData Success values. */
export async function firstSuccessFrom<T>(s: Observable<RemoteData<unknown, T>>): Promise<T> {
	return firstValueFrom(s.pipe(pluckSuccessData));
}

// Helpers for the templates //
//////////////////////////////

/** Return the value of a RemoteData object if it is in a `Success` state otherwise undefined. */
@Pipe({ name: 'foldSuccess', standalone: true })
export class FoldSuccessPipe implements PipeTransform {
	/** Return the value of a RemoteData object if it is in a `Success` state otherwise undefined. */
	transform<T>(data: RemoteData<unknown, T>): T | undefined {
		return foldSuccess(data);
	}
}

/** Check if all given RemoteData object(s) are in a `resolved` state. */
@Pipe({ name: 'isResolved', standalone: true })
export class IsResolvedPipe implements PipeTransform {
	/** Check if all given RemoteData object(s) are in a `resolved` state. */
	transform(source: OneOrArray<RemoteData<unknown, unknown>>): boolean {
		return castArray(source).every(isResolved);
	}
}

/** Check if all given RemoteData object(s) are in a `Success` state. */
@Pipe({ name: 'isSuccess', standalone: true })
export class IsSuccessPipe implements PipeTransform {
	/** Check if all given RemoteData object(s) are in a `Success` state. */
	transform(source: OneOrArray<RemoteData<unknown, unknown>>): boolean {
		return castArray(source).every(isSuccess);
	}
}
