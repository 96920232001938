import { I18nService } from '../utils/i18n.service';
import { fold } from '../utils/remote-data';
import { firstTruthy, getDebug, pick } from '../utils/util';
import { NewRelic } from './newrelic.service';
import { StoreDataService } from './store-data.service';
import { Injectable } from '@angular/core';
import { me as getCurrentCasUser, anonymousUser } from '@app/api/action/User';
import { RootReducer, Store } from '@app/app.reducers';
import { Dialog, open } from '@app/dialog/reducers/dialog.reducer';
import { getStore, StoreWithDetails } from '@app/shared/reducers/storefront.reducer';
import {
	logout,
	getInitAuthState,
	getCognitoUser,
	setIsAuthenticating,
	setCriticalCognitoError,
	setCxmlToken,
	setCognitoUser,
} from '@app/user/reducers/auth.reducer';
import { initEmptyUser, getUser, getUserError } from '@app/user/reducers/user.reducer';
import type {
	MyGranoAuth,
	AuthSettings,
	ICognitoUser,
	ServiceCognitoConfig,
	CognitoUserSession,
	CognitoUserAttribute as UserAttribute,
} from '@grano/mygrano-auth';
import type { IAuthenticationCallback } from '@grano/mygrano-auth/lib-esm/amazon-cognito-identity';
import { environment } from '@src/environments/environment';
import { combineLatest, BehaviorSubject, firstValueFrom } from 'rxjs';
import { first, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

/** Service for handling Cognito authentication. */
@Injectable({ providedIn: 'root' })
export class CognitoService {
	auth!: MyGranoAuth;
	readonly hasAuthInitialized$ = new BehaviorSubject<boolean>(false);
	readonly authReady = firstValueFrom(this.hasAuthInitialized$.pipe(firstTruthy));
	private readonly debug = getDebug('CognitoService');

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly newRelic: NewRelic,
		private readonly i18n: I18nService,
		private readonly storeData: StoreDataService,
	) {
		this.initialize();
	}

	async loginWithCxmlToken(token: string): Promise<void> {
		const { jwtDecode } = await import('jwt-decode');

		await this.authReady;
		const { CognitoUser, AuthenticationDetails } = await import(
			'@grano/mygrano-auth/lib-esm/amazon-cognito-identity'
		);
		this.store.dispatch(setIsAuthenticating(true));
		// Figure out the user's email address from the JWT and create the Cognito user.
		const claims = jwtDecode<{ email: string }>(token);
		const cognitoUser = new CognitoUser({
			Username: claims.email,
			Pool: this.auth.primaryUserPool,
			Storage: this.auth.tokenStorage,
		});
		this.auth.activeUserPool = this.auth.primaryUserPool;
		// Use custom authentication flow without password.
		cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
		const authDetails = new AuthenticationDetails({ Username: claims.email });
		// Initiate the auth attempt.
		await new Promise<void>((resolve, reject) => {
			// Handle auth responses.
			const authCallbacks: IAuthenticationCallback = {
				onSuccess: () => {
					this.store.dispatch(setCxmlToken(token));
					void this.dispatchCurrentUser();
					this.store.dispatch(setIsAuthenticating(false));
					void this.auth.setCommonAuthTokens();
					resolve();
				},
				onFailure: (error) => {
					this.store.dispatch(setIsAuthenticating(false));
					reject(error);
				},
			};
			cognitoUser.initiateAuth(authDetails, {
				...authCallbacks,
				customChallenge: () => {
					// Custom auth challenge received, send the JWT.
					cognitoUser.sendCustomChallengeAnswer(token, authCallbacks);
				},
			});
		});
	}

	async getFederatedSession(): Promise<CognitoUserSession | void> {
		await this.authReady;
		const cognitoSession = await this.auth.getFederatedSession();
		if (!cognitoSession) return;
		await this.dispatchCurrentUser();
		return cognitoSession;
	}

	async requestPasswordReset(email: string): Promise<unknown> {
		await this.authReady;
		return this.auth.requestPasswordReset(email);
	}

	async resetPassword(email: string, code: string, newPassword: string): Promise<unknown> {
		await this.authReady;
		return this.auth.resetPassword(email, code, newPassword);
	}

	async changePassword(oldPassword: string, newPassword: string): Promise<void> {
		await this.authReady;
		await this.auth.changePassword(oldPassword, newPassword);
		void this.dispatchCurrentUser();
	}

	async confirmRegistration(details: { email: string; code: string }): Promise<boolean> {
		await this.authReady;
		return this.auth.confirmRegistration(details);
	}

	async resendConfirmationCode(email: string): Promise<unknown> {
		await this.authReady;
		return this.auth.resendConfirmationCode(email);
	}

	async getAttributeVerificationCode(attribute: string): Promise<void> {
		await this.authReady;
		await this.auth.getAttributeVerificationCode(attribute);
	}

	async getUserAttributes(): Promise<UserAttribute[]> {
		await this.authReady;
		return this.auth.getUserAttributes();
	}

	async dispatchCurrentUser(fetchCasUser = true): Promise<void> {
		await this.authReady;
		this.debug('dispatchCurrentUser', { fetchCasUser });
		try {
			if (!this.auth) throw new Error('Authentication unavailable');
			this.debug('fetching Cognito user');
			const attributes = await this.auth.getUserAttributes();
			const user = attributes.reduce(
				(sum, attr) => {
					sum[attr.Name.replace('custom:', '')] = attr.Value;
					if (attr.Name === 'sub') {
						sum.id = attr.Value;
						sum.sso_id = attr.Value;
					}
					return sum;
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{} as Record<string, string>,
			);
			// Save Cognito user data to store
			this.store.dispatch(setCognitoUser(user));
			// Wait until Cognito user has been received
			const cognitoUser = await firstValueFrom(
				this.store.select(getInitAuthState).pipe(
					first((isInitialized) => isInitialized !== undefined),
					switchMap(() => this.store.select(getCognitoUser)),
					first(),
				),
			);
			// If user ID was received from Cognito, fetch CAS user data from API when applicable.
			// Otherwise initialize state with an empty user.
			const isValidUser = !!cognitoUser && typeof cognitoUser.id === 'string' && !!cognitoUser.id;

			if (fetchCasUser) {
				if (isValidUser) {
					this.debug('fetching CAS user');
					this.store.dispatch(getCurrentCasUser());
					return;
				} else if (this.storeData.storeData.storefront?.allow_anonymous_browsing) {
					this.debug('fetching anonymous user');
					this.store.dispatch(anonymousUser());
					return;
				}
			}
			this.debug('init empty user');
			this.store.dispatch(initEmptyUser());
		} catch (err: unknown) {
			this.debug('dispatchCurrentUser error', err);
			// Something went wrong with the Cognito request so log user out to prevent them from
			// getting stuck in the loading spinner
			await this.logout();
			this.store.dispatch(initEmptyUser());
			this.store.dispatch(setCriticalCognitoError(err as Error));
			throw err;
		}
	}

	getCurrentUser(): ICognitoUser {
		return this.auth?.getCurrentUser?.();
	}

	async getSession(): Promise<CognitoUserSession> {
		await this.authReady;
		return this.auth.getSession();
	}

	async updateAttributes(details: Record<string, string>): Promise<unknown> {
		await this.authReady;
		return this.auth.updateAttributes(details);
	}

	async verifyAttribute(attribute: string, verificationCode: string): Promise<boolean> {
		await this.authReady;
		return this.auth.verifyAttribute(attribute, verificationCode);
	}

	async logout(): Promise<void> {
		if (this.auth) await this.auth.logout();
		this.store.dispatch(logout());
	}

	getAuthorization: api.ServiceOptions['getAuthorization'] = async (operationSecurity) => {
		// Currently we only support apiKeyAuth using the Cognito JWT.
		if (operationSecurity?.id === 'apiKeyAuth' && this.auth) {
			const session = await this.getSession().catch((err) => {
				this.newRelic.noticeError(err);
				throw err;
			});
			if (session) {
				const apiKey = `JWT ${session.getIdToken().getJwtToken()}`;
				return { apiKeyAuth: { apiKey } };
			}
		}
	};

	async login(
		details: { email: string; password: string },
		fetchCasUser = true,
		redirectTo?: string,
	): Promise<{ isNewUser: boolean }> {
		this.store.dispatch(setIsAuthenticating(true));
		await this.authReady;
		return this.auth
			.login(details.email, details.password)
			.then(async (cognitoData) => {
				if (cognitoData.isNewUser) {
					this.store.dispatch(open(Dialog.CHANGE_PASSWORD, { redirectTo }));
					return cognitoData;
				}
				void this.dispatchCurrentUser(fetchCasUser);
				if (!fetchCasUser) return cognitoData;

				const [userError] = await firstValueFrom(
					combineLatest([this.store.select(getUserError), this.store.select(getUser)]).pipe(
						distinctUntilChanged(),
						first(([error, userData]) => !!userData || !!error),
					),
				);
				if (userError) throw new Error(userError.message);
				if (!cognitoData.isEmailVerified) throw new Error('Email not verified');
				return cognitoData;
			})
			.then((cognitoData) => {
				this.store.dispatch(setIsAuthenticating(false));
				return cognitoData;
			})
			.catch((err: unknown) => {
				this.store.dispatch(setIsAuthenticating(false));
				throw err;
			});
	}

	private initialize() {
		const authSettings: AuthSettings = {
			authUrl: environment.authUrl,
			locale: this.i18n.locale,
		};
		this.store
			.select(getStore)
			.pipe(
				map(
					fold<ServiceCognitoConfig | undefined, unknown, StoreWithDetails>(
						// istanbul ignore next
						() => undefined,
						// istanbul ignore next
						() => undefined,
						// If store/cognito config cannot be loaded, fallback to default user pool.
						() => ({
							user_pool_id: environment.cognito.userPoolId,
							client_id: environment.cognito.clientId,
							region: environment.awsRegion,
							use_shared_auth: false,
						}),
						(storeDetails) => ({
							...pick(storeDetails.cognito_config!, [
								'user_pool_id',
								'client_id',
								'region',
								'fallbacks',
								'federation',
							]),
							use_shared_auth: storeDetails.use_shared_auth,
						}),
					),
				),
				firstTruthy,
				switchMap(async (cognitoConfig) => ({
					cognitoConfig,
					myGranoAuthModule: await import('@grano/mygrano-auth'),
				})),
			)
			.subscribe(({ cognitoConfig, myGranoAuthModule }) => {
				this.auth = new myGranoAuthModule.MyGranoAuth({ ...authSettings, cognitoConfig });
				void this.auth.hasInitialized.then(() => {
					this.hasAuthInitialized$.next(true);
					void this.dispatchCurrentUser();
				});
			});
	}
}
