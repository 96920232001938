import { ErrorHandler, Injectable } from '@angular/core';
import { NewRelic } from '@app/shared/services/newrelic.service';
import { environment } from '@src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggingErrorHandler extends ErrorHandler {
	constructor(private readonly newRelic: NewRelic) {
		super();
	}

	handleError(error: unknown): void {
		// Log errors through New Relic when applicable
		if (environment.logErrors) this.newRelic.noticeError(error);
		super.handleError(error); // Let default error handler do the rest.
	}
}
