@if (order$ | async; as order) {
	<div class="container" fxLayout="column" fxFlex="690px" fxFlex.lt-md="90%">
		<h2 i18n="@@OrderConfirmationThankYou" data-cy="orderConfirmation">Thank you for your order!</h2>
		<p i18n="@@OrderConfirmationEmailSent">An email with your order details has been sent.</p>
		@if (needsApproval$ | async) {
			<p i18n="@@OrderConfirmationNeedsApproval" data-cy="needApproval"
				>Your order contains products that must be approved. You will receive an email once your order
				has been handled.</p
			>
		}
		<button class="btn btn-primary" [routerLink]="['']" i18n="@@OrderConfirmationHomepageButton"
			>Homepage</button
		>
		<g-order-summary
			[order]="order"
			[options]="{
				isConfirmedOrder: true,
				showOrderDetails: true,
				showPrices: (showPrices$ | async) ?? true,
			}"
		/>
	</div>
}
