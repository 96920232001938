// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/ShippingMethod */
// Auto-generated, edits will be overwritten
import * as ShippingMethod from '../ShippingMethod';

export const BY_STORE_START = 's/ShippingMethod/BY_STORE_START';
export const BY_STORE = 's/ShippingMethod/BY_STORE';
export type BY_STORE = api.ShippingMethod[];

export function byStore(options?: ShippingMethod.ByStoreOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: BY_STORE_START, meta: { info, params: { options } } });
    return ShippingMethod.byStore(options)
      .then(response => dispatch({
        type: BY_STORE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALLOWED_SHIPPING_METHODS_START = 's/ShippingMethod/ALLOWED_SHIPPING_METHODS_START';
export const ALLOWED_SHIPPING_METHODS = 's/ShippingMethod/ALLOWED_SHIPPING_METHODS';
export type ALLOWED_SHIPPING_METHODS = api.ShippingMethod[];

export function allowedShippingMethods(options?: ShippingMethod.AllowedShippingMethodsOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALLOWED_SHIPPING_METHODS_START, meta: { info, params: { options } } });
    return ShippingMethod.allowedShippingMethods(options)
      .then(response => dispatch({
        type: ALLOWED_SHIPPING_METHODS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const IS_VALID_DELIVERY_DATE_START = 's/ShippingMethod/IS_VALID_DELIVERY_DATE_START';
export const IS_VALID_DELIVERY_DATE = 's/ShippingMethod/IS_VALID_DELIVERY_DATE';
export type IS_VALID_DELIVERY_DATE = {
    result?: boolean,
    date?: string,
    minDeliveryTime?: number
  };

export function isValidDeliveryDate(date: string, minDeliveryTime: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: IS_VALID_DELIVERY_DATE_START, meta: { info, params: { date, minDeliveryTime } } });
    return ShippingMethod.isValidDeliveryDate(date, minDeliveryTime)
      .then(response => dispatch({
        type: IS_VALID_DELIVERY_DATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_DELIVERY_DATES_START = 's/ShippingMethod/GET_DELIVERY_DATES_START';
export const GET_DELIVERY_DATES = 's/ShippingMethod/GET_DELIVERY_DATES';
export type GET_DELIVERY_DATES = {
    
  };

export function getDeliveryDates(products: string, options?: ShippingMethod.GetDeliveryDatesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_DELIVERY_DATES_START, meta: { info, params: { products, options } } });
    return ShippingMethod.getDeliveryDates(products, options)
      .then(response => dispatch({
        type: GET_DELIVERY_DATES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/ShippingMethod/ALL_START';
export const ALL = 's/ShippingMethod/ALL';
export type ALL = api.ShippingMethod[];

export function all(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: {  } } });
    return ShippingMethod.all()
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/ShippingMethod/CREATE_START';
export const CREATE = 's/ShippingMethod/CREATE';
export type CREATE = api.ShippingMethod;

export function create(options?: ShippingMethod.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return ShippingMethod.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SCHEMA_START = 's/ShippingMethod/SCHEMA_START';
export const SCHEMA = 's/ShippingMethod/SCHEMA';
export type SCHEMA = api.ShippingMethod;

export function schema(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SCHEMA_START, meta: { info, params: {  } } });
    return ShippingMethod.schema()
      .then(response => dispatch({
        type: SCHEMA,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ONE_START = 's/ShippingMethod/ONE_START';
export const ONE = 's/ShippingMethod/ONE';
export type ONE = api.ShippingMethod;

export function one(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ONE_START, meta: { info, params: { id } } });
    return ShippingMethod.one(id)
      .then(response => dispatch({
        type: ONE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/ShippingMethod/DESTROY_START';
export const DESTROY = 's/ShippingMethod/DESTROY';
export type DESTROY = undefined;

export function destroy(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { id } } });
    return ShippingMethod.destroy(id)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_START = 's/ShippingMethod/UPDATE_START';
export const UPDATE = 's/ShippingMethod/UPDATE';
export type UPDATE = api.ShippingMethod;

export function update(id: number, options?: ShippingMethod.UpdateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_START, meta: { info, params: { id, options } } });
    return ShippingMethod.update(id, options)
      .then(response => dispatch({
        type: UPDATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
