<div class="mobile-navigation-overlay">
	<div class="mobile-navigation-content">
		<div class="inner-content" fxLayout="row">
			<div class="menu-content" fxFlex="300px" fxFlex.xs="80%">
				<div class="scrollable-container">
					<section
						class="top-section"
						fxLayout="row"
						fxLayoutAlign="space-between center"
						matAutocompleteOrigin
						#searchMenuTarget="matAutocompleteOrigin"
					>
						<div class="logo" fxLayoutAlign="start">
							<button mat-button [routerLink]="['/']">
								<img
									[gResponsiveSrc]="mobileLogo"
									width="64"
									height="45"
									i18n-alt="@@StoreLogoAlt"
									alt="Store logo"
									loading="lazy"
								/>
							</button>
						</div>

						<div class="search" fxLayoutAlign="end">
							<g-search-field [autocompleteOrigin]="searchMenuTarget" />
						</div>
					</section>

					<section class="middle-section">
						<ul class="menu-list main-menu">
							<li><a [routerLink]="['/products']" i18n="@@MobileNavAllProductsLink">All products</a></li>

							<!-- Selected pages -->
							@if (mainMenu$ | async; as mainMenu) {
								@for (page of mainMenuPages$ | async; track $index) {
									<li>
										<a [routerLink]="['/page', page.key]" routerLinkActive="nav-active">{{
											page.content.title
										}}</a>
									</li>
								}
								<!-- Custom links -->
								@for (link of mainMenu?.links; track $index) {
									<li>
										@if (link | isExternalLink) {
											<a [attr.href]="link.link">{{ link.linkText }}</a>
										}
										@if (link | isRouterLink) {
											<a [routerLink]="[link.link]" routerLinkActive="nav-active">{{ link.linkText }}</a>
										}
									</li>
								}
							}

							@if (vignette$ | async; as vignette) {
								<!-- Selected vignette pages -->
								@for (page of vignettePages$ | async; track $index) {
									<li>
										<a [routerLink]="['/page', page.key]" routerLinkActive="nav-active">{{
											page.content.title
										}}</a>
									</li>
								}
								<!-- Custom vignette links -->
								@for (link of vignette?.links; track $index) {
									<li>
										<a [attr.href]="link.link">{{ link.linkText }}</a>
									</li>
								}
							}
						</ul>
					</section>

					<section class="bottom-section">
						<hr />
						<g-nav-actions mode="user" />
						<hr />
						<ul class="language-picker">
							<li class="language-label" i18n="@@MobileNavLanguagePicker">Language:</li>
							@for (languageCode of languageCodes; track $index) {
								<li [ngClass]="{ selected: languageCode === currentLocale }">
									<button mat-button (click)="changeLanguage(languageCode)">{{
										languageCode | uppercase
									}}</button>
								</li>
							}
						</ul>
					</section>
				</div>
			</div>

			<div class="overflow-content" fxFlex="75px" fxFlex.xs="20%">
				<button
					mat-icon-button
					class="close-menu"
					i18n-aria-label="@@CloseMobileMenuIconAriaLabel"
					aria-label="Close menu"
					(click)="closeMenu()"
				>
					<g-icon [icon]="icons.faXmark" size="4x" />
				</button>
			</div>
		</div>
	</div>
</div>
