import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { IconDefinition, IconPathData } from '@fortawesome/fontawesome-common-types';

/** @see https://fontawesome.com/icons/notdef?s=solid */
export const ICON_MISSING = {
	name: 'notdef',
	width: 384,
	height: 512,
	// eslint-disable-next-line max-len
	path: 'M336 0h-288C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48v-416C384 21.49 362.5 0 336 0zM281.5 64L192 198.3L102.5 64H281.5zM64 121.7L153.5 256L64 390.3V121.7zM102.5 448L192 313.7L281.5 448H102.5zM320 390.3L230.5 256L320 121.7V390.3z',
};

/** A component that displays an icon. */
@Component({
	standalone: true,
	imports: [NgClass],
	selector: 'g-icon',
	styleUrls: ['./icon.component.scss'],
	template: `
		<svg
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-hidden="true"
			focusable="false"
			[attr.viewBox]="viewBox"
			[ngClass]="iconName"
		>
			<g transform-origin="center" [attr.transform]="transform">
				<path fill="currentColor" [attr.d]="svgPath" />
			</g>
		</svg>
	`,
})
export class IconComponent {
	@Input({ required: true }) icon!: IconDefinition;
	/**
	 * Specify a title for the icon.
	 * This text will be displayed in a tooltip on hover and presented to the
	 * screen readers.
	 */
	@HostBinding('attr.title') @Input() title?: string;
	/** Add this attribute to animate a spinner. */
	@HostBinding('attr.pulse') @Input() pulse?: string | boolean;
	/** Inverse the icon color (make it white) */
	@HostBinding('attr.inverse') @Input() inverse?: string | boolean;
	/** Make the icon into a square block */
	@HostBinding('attr.fixedWidth') @Input() fixedWidth?: string | boolean;
	/** lg, 2x or 4x to make the icon bigger. @note Do not use as a property binding. */
	@Input() size?: 'sm' | 'md' | 'lg' | '2x' | '4x';
	/**
	 * SVG transform expression
	 * @see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform
	 */
	@Input() readonly transform?: string;
	/** Returns the SVG viewBox for the icon. */
	get viewBox(): string {
		const { width, height } = this.parsedIcon;
		return `0 0 ${width} ${height}`;
	}
	/** Returns the SVG path for the icon. */
	get svgPath(): IconPathData {
		return this.parsedIcon.path;
	}
	/** Returns the icon name. */
	get iconName(): string {
		return `fa-${this.parsedIcon.name}`;
	}
	/** Get the icon definition. */
	private get parsedIcon() {
		const data = this.icon;
		if (!Array.isArray(data?.icon)) return ICON_MISSING;
		const [width = ICON_MISSING.width, height = ICON_MISSING.height, , , path] = data.icon;
		return { name: data.iconName, width, height, path };
	}
}
