<section class="modal-body">
	<section class="image-viewer-grid">
		<div class="dummy"></div>
		<div class="counter">{{ shownImageIndex + 1 }} / {{ images.length }}</div>
		<g-icon
			[icon]="icons.faXmark"
			class="close-dialog"
			(click)="closeDialog()"
			role="button"
			i18n-title
			title="Close"
		>
		</g-icon>
		<div class="advance-image previous" [ngClass]="{ disabled: !canAdvanceImage(direction.Previous) }">
			<g-icon [icon]="icons.faChevronLeft" (click)="selectAdjacentImage(direction.Previous)"></g-icon>
		</div>
		<div class="shown-image">
			<g-icon
				*ngIf="isLoading"
				[icon]="icons.faSpinner"
				size="4x"
				pulse
				fixedWidth
				class="image-load-spinner"
			>
			</g-icon>
			@if (!isLoading && !shownImageUrl) {
				<p i18n="@@ImageViewerDialogError" class="image-load-error">Error loading image</p>
			} @else if (shownImageUrl) {
				<img
					gFallbackProductImage
					[alt]="imageAlt"
					[src]="shownImageUrl"
					(load)="isLoading = false"
					(error)="isLoading = false"
					[hidden]="isLoading || !shownImageUrl"
				/>
			}
		</div>
		<div class="advance-image next" [ngClass]="{ disabled: !canAdvanceImage(direction.Next) }">
			<g-icon [icon]="icons.faChevronRight" (click)="selectAdjacentImage(direction.Next)"></g-icon>
		</div>
	</section>
</section>
