// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Search */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Search a store's products using advanced Elasticsearch queries
 * 
 * @param {string} query The natural language search query
 * @param {object} options Optional options
 * @param {number} [options.limit] Limit the amount of results returned
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ProductSearchResponseDto>} Search results from Elasticsearch
 */
export function searchProducts(query: string, options?: SearchProductsOptions): Promise<api.Response<api.ProductSearchResponseDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    query: {
      query,
      limit: options.limit
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(searchProductsOperation, parameters);
}

export interface SearchProductsOptions {
  /**
   * Limit the amount of results returned
   */
  limit?: number;
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

const searchProductsOperation: api.OperationInfo = {
  path: '/v2/search/products',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
