// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module ProductList */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Fetch all product lists for authenticated user
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.ProductListWithProducts[]>} OK
 */
export function myLists(options?: MyListsOptions): Promise<api.Response<api.ProductListWithProducts[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(myListsOperation, parameters);
}

/**
 * Create a product list for authenticated user in active store
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.ProductListWithProducts>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.ProductListWithProducts>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Update product list for authenticated user in active store
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.ProductListWithProducts>} OK
 */
export function update(id: number, options?: UpdateOptions): Promise<api.Response<api.ProductListWithProducts>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOperation, parameters);
}

/**
 * Delete product list for authenticated user in active store
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.ProductList>} OK
 */
export function destroy(id: number, options?: DestroyOptions): Promise<api.Response<api.ProductList>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

export interface MyListsOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface CreateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    type: 'persisted-shopping-cart'|'my-products'|'cxml'|'custom',
    products: {
      id: string,
      options: any,
      price?: any,
      productFlowData?: any,
      product: {
        id?: number,
        updated_at?: string,
        locale?: any,
        images?: {
          master?: {
            fitIn_80x80?: string,
            fitIn_270x270?: string,
            fitIn_470x470?: string,
            fitIn_670x670?: string,
            fitIn_1200x630?: string,
            fitIn_2000x2000?: string
          }
        }
      }
    }[],
    metadata: {
      title: string
    }
  };
}

export interface UpdateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    products: {
      id: string,
      options: any,
      price?: any,
      productFlowData?: any,
      product: {
        id?: number,
        updated_at?: string,
        locale?: any,
        images?: {
          master?: {
            fitIn_80x80?: string,
            fitIn_270x270?: string,
            fitIn_470x470?: string,
            fitIn_670x670?: string,
            fitIn_1200x630?: string,
            fitIn_2000x2000?: string
          }
        }
      }
    }[],
    metadata: {
      title: string
    }
  };
}

export interface DestroyOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

const myListsOperation: api.OperationInfo = {
  path: '/product-list/my-lists',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/product-list',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOperation: api.OperationInfo = {
  path: '/product-list/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/product-list/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
