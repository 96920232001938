// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module ApprovalWorkflow */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get all approval workflows for a specific store (super admins only)
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ApprovalWorkflowDto[]>} List of approval workflows
 */
export function listByStore(options?: ListByStoreOptions): Promise<api.Response<api.ApprovalWorkflowDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(listByStoreOperation, parameters);
}

/**
 * Get all orders from current store that have products with approval workflow that are accessible for current user
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.OrderDto[]>} List of orders
 */
export function listMyWorkflowOrders(options?: ListMyWorkflowOrdersOptions): Promise<api.Response<api.OrderDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(listMyWorkflowOrdersOperation, parameters);
}

/**
 * Set approval status of provided products to "Approved" or "Rejected" in specific order
 * 
 * @param {number} orderId ID of order which will be updated
 * @param {module:types.OrderReviewDto[]} body Array of objects that include product UUID, approval status and optionally a comment
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.SuccessDto>} 
 */
export function review(orderId: number, body: api.OrderReviewDto[], options?: ReviewOptions): Promise<api.Response<api.SuccessDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body
    }
  };
  return gateway.request(reviewOperation, parameters);
}

/**
 * @deprecated Do not use
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ApprovalWorkflowDto[]>} The requested ApprovalWorkflows
 */
export function listApprovalWorkflows(options?: ListApprovalWorkflowsOptions): Promise<api.Response<api.ApprovalWorkflowDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(listApprovalWorkflowsOperation, parameters);
}

/**
 * @deprecated Do not use
 * 
 * @param {module:types.ApprovalWorkflowBodyDto} body The ApprovalWorkflow object
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ApprovalWorkflowDto>} The created ApprovalWorkflow
 */
export function createApprovalWorkflow(body: api.ApprovalWorkflowBodyDto, options?: CreateApprovalWorkflowOptions): Promise<api.Response<api.ApprovalWorkflowDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body
    }
  };
  return gateway.request(createApprovalWorkflowOperation, parameters);
}

/**
 * @deprecated Do not use
 * 
 * @param {number} id Identifier of the ApprovalWorkflow to fetch
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ApprovalWorkflowDto>} The requested ApprovalWorkflow
 */
export function getApprovalWorkflow(id: number, options?: GetApprovalWorkflowOptions): Promise<api.Response<api.ApprovalWorkflowDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    }
  };
  return gateway.request(getApprovalWorkflowOperation, parameters);
}

/**
 * @deprecated Do not use
 * 
 * @param {number} id Identifier of the ApprovalWorkflow to update
 * @param {module:types.ApprovalWorkflowBodyDto} body The ApprovalWorkflow object
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ApprovalWorkflowDto>} The updated ApprovalWorkflow
 */
export function updateApprovalWorkflow(id: number, body: api.ApprovalWorkflowBodyDto, options?: UpdateApprovalWorkflowOptions): Promise<api.Response<api.ApprovalWorkflowDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    },
    body: {
      body
    }
  };
  return gateway.request(updateApprovalWorkflowOperation, parameters);
}

/**
 * @deprecated Do not use
 * 
 * @param {number} id Identifier of the ApprovalWorkflow to delete
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<object>} The ApprovalWorkflow was successfully deleted
 */
export function deleteApprovalWorkflow(id: number, options?: DeleteApprovalWorkflowOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    }
  };
  return gateway.request(deleteApprovalWorkflowOperation, parameters);
}

export interface ListByStoreOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface ListMyWorkflowOrdersOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface ReviewOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface ListApprovalWorkflowsOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface CreateApprovalWorkflowOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GetApprovalWorkflowOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface UpdateApprovalWorkflowOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface DeleteApprovalWorkflowOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

const listByStoreOperation: api.OperationInfo = {
  path: '/v2/approval-workflow/by-store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const listMyWorkflowOrdersOperation: api.OperationInfo = {
  path: '/v2/approval-workflow/my-workflow-orders',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const reviewOperation: api.OperationInfo = {
  path: '/v2/approval-workflow/review/{orderId}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const listApprovalWorkflowsOperation: api.OperationInfo = {
  path: '/v2/approval-workflow',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createApprovalWorkflowOperation: api.OperationInfo = {
  path: '/v2/approval-workflow',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getApprovalWorkflowOperation: api.OperationInfo = {
  path: '/v2/approval-workflow/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateApprovalWorkflowOperation: api.OperationInfo = {
  path: '/v2/approval-workflow/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const deleteApprovalWorkflowOperation: api.OperationInfo = {
  path: '/v2/approval-workflow/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
