import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RootReducer, Store } from '@app/app.reducers';
import { getMainCategories } from '@app/category/reducers/category.reducer';
import {
	getFooterContent,
	getPages,
	FooterContent,
	PageResponse,
} from '@app/core/reducers/content.reducer';
import { getLogoUrl } from '@app/shared/reducers/storefront.reducer';
import { ViewportService } from '@app/shared/services/viewport.service';
import { I18nService } from '@app/shared/utils/i18n.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons/faSquareFacebook';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons/faSquareInstagram';
import { faSquarePinterest } from '@fortawesome/free-brands-svg-icons/faSquarePinterest';
import { faSquareTwitter } from '@fortawesome/free-brands-svg-icons/faSquareTwitter';
import { faSquareYoutube } from '@fortawesome/free-brands-svg-icons/faSquareYoutube';

/** Footer component */
@Component({
	selector: 'g-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	// Content is fetched in content access guard.
	readonly content$ = this.store.select(getFooterContent);
	readonly pages$ = this.store.select(getPages);
	readonly logoUrl$ = this.store.select(getLogoUrl);
	readonly categories$ = this.store.select(getMainCategories(this.i18n));
	readonly icons: Record<string, IconDefinition> = {
		'fa-instagram': faSquareInstagram,
		'fa-facebook': faSquareFacebook,
		'fa-twitter': faSquareTwitter,
		'fa-youtube': faSquareYoutube,
		'fa-pinterest': faSquarePinterest,
		'fa-linkedin': faLinkedin,
	};

	constructor(
		private readonly sanitized: DomSanitizer,
		private readonly store: Store<RootReducer.State>,
		private readonly i18n: I18nService,
		public readonly viewport: ViewportService,
	) {}

	parseDescription(description: string): SafeHtml {
		if (typeof description !== 'string') return '';
		description = description.replace(/\r\n?|\n/g, '<br>');
		return this.sanitized.bypassSecurityTrustHtml(description);
	}

	pagesAlphabetically(footer: FooterContent, pages: PageResponse[]): PageResponse[] {
		if (!footer?.pages?.length) return [];
		if (!pages?.length) return [];
		const selectedPages = pages.filter((page) => footer.pages.includes(page.key));
		return selectedPages.sort((a, b) => a.content.title.localeCompare(b.content.title));
	}

	getCustomContent(html: string): SafeHtml {
		return this.sanitized.bypassSecurityTrustHtml(html);
	}
}
