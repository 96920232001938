import { RootReducer } from '@app/app.reducers';
import {
	DetailsData,
	ProductEditorData,
	ProductFlowDesignServiceData,
	TemplateSelectorData,
	UploadMaterialData,
	ValidateDesignData,
	WorkflowSelectorData,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { setName } from '@app/shared/utils/name-helper';
import { omit } from '@app/shared/utils/util';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { createSelector } from 'reselect';

/**
 * Keys for possible pages shown when ordering product
 */
export enum ProductFlowPage {
	ProductDetails = 'details',
	ProductDesigner = 'designer',
	Workflow = 'workflow',
	ValidateDesign = 'validateDesign',
	Upload = 'upload',
	SelectTemplate = 'template',
	DesignService = 'designService',
}

export interface Workflow {
	key: ProductFlowPage; // This is really workflow key but the same keys are reused
	steps: ProductFlowPage[]; // Pages that will be shown when workflow is selected
	icon: IconDefinition;
	iconActive: IconDefinition;
	// TODO: Use $localize
	title: `productWorkflowSelect.${string}`;
	description: `productWorkflowSelect.${string}`;
	showDescription: boolean;
}

// Step data is in productId: { ProductFlowPage: data } format
export interface State {
	currentStepKey: ProductFlowPage;
	replaceStepState: boolean;
	isStepStateValid: boolean;
	stepData: Record<
		string,
		{
			[ProductFlowPage.ProductDetails]?: DetailsData;
			[ProductFlowPage.ProductDesigner]?: ProductEditorData;
			[ProductFlowPage.Workflow]?: WorkflowSelectorData;
			[ProductFlowPage.ValidateDesign]?: ValidateDesignData;
			[ProductFlowPage.Upload]?: UploadMaterialData;
			[ProductFlowPage.SelectTemplate]?: TemplateSelectorData;
			[ProductFlowPage.DesignService]?: ProductFlowDesignServiceData;
		}
	>;
}

export const initialState: State = {
	currentStepKey: undefined,
	replaceStepState: false,
	isStepStateValid: true,
	stepData: {},
};

const SET_STEP = 'mygrano/product_flow/SET_STEP';
export const setStep = (key: ProductFlowPage, replaceStepState = false) =>
	({
		type: SET_STEP,
		payload: { key, replaceStepState },
	}) as const;

const RESET_STEP = 'mygrano/product_flow/RESET_STEP';
export const resetStep = () =>
	({
		type: RESET_STEP,
	}) as const;

const SET_STEP_DATA = 'mygrano/product_flow/SET_STEP_DATA';
export const setStepData = <T extends ProductFlowPage>(
	cartItemId: string,
	key: T,
	data: State['stepData'][string][T],
) =>
	({
		type: SET_STEP_DATA,
		payload: { cartItemId, key, data },
	}) as const;

const CLEAR_STEP_DATA = 'mygrano/product_flow/CLEAR_STEP_DATA';
export const clearStepData = (cartItemId: string, key: ProductFlowPage) =>
	({
		type: CLEAR_STEP_DATA,
		payload: { cartItemId, key },
	}) as const;

const RESET_STEP_DATA = 'mygrano/product_flow/RESET_STEP_DATA';
export const resetStepData = (cartItemId: string) =>
	({
		type: RESET_STEP_DATA,
		payload: { cartItemId },
	}) as const;

const SET_STEP_STATE_VALID = 'mygrano/product_flow/SET_STEP_STATE_VALID';
export const setStepStateValid = (isValid: boolean) =>
	({
		type: SET_STEP_STATE_VALID,
		payload: isValid,
	}) as const;

type Action = ReturnType<
	| typeof setStep
	| typeof resetStep
	| typeof setStepData
	| typeof clearStepData
	| typeof resetStepData
	| typeof setStepStateValid
>;

export function reducer(state: State = initialState, action: Action): State {
	switch (action.type) {
		case SET_STEP:
			return {
				...state,
				currentStepKey: action.payload.key,
				replaceStepState: action.payload.replaceStepState,
			};
		case RESET_STEP:
			return { ...state, currentStepKey: undefined };
		case SET_STEP_DATA: {
			return {
				...state,
				stepData: {
					...state.stepData,
					[action.payload.cartItemId]: {
						...(state.stepData[action.payload.cartItemId] || {}),
						[action.payload.key]: action.payload.data,
					},
				},
			};
		}
		case CLEAR_STEP_DATA:
			return {
				...state,
				stepData: {
					...state.stepData,
					[action.payload.cartItemId]: omit(state.stepData[action.payload.cartItemId], [
						action.payload.key,
					]),
				},
			};
		case RESET_STEP_DATA:
			return {
				...state,
				stepData: omit(state.stepData, [action.payload.cartItemId]),
			};
		case SET_STEP_STATE_VALID:
			return { ...state, isStepStateValid: action.payload };
		default:
			return state;
	}
}

export const getState = setName('getState', (state: RootReducer.State): State => state.productFlow);

export const getStepData = <T extends ProductFlowPage>(cartItemId: string, key: T) =>
	setName(
		'getStepData',
		createSelector(getState, (state) => state.stepData?.[cartItemId]?.[key]),
	);

export const getAllStepData = (cartItemId: string) =>
	setName(
		'getAllStepData',
		createSelector(getState, (state) => state.stepData?.[cartItemId]),
	);

export const getCurrentStep = setName(
	'getCurrentStep',
	createSelector(getState, (state) => ({
		key: state.currentStepKey,
		replaceStepState: state.replaceStepState,
	})),
);

export const isStepStateValid = setName(
	'isStepStateValid',
	createSelector(getState, (state) => state.isStepStateValid),
);
