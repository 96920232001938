import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { resendTemporaryPassword } from '@app/api/action/User';
import { RootReducer, Store } from '@app/app.reducers';
import { closeMobileMenu } from '@app/core/reducers/mobile-menu.reducer';
import { Dialog, open } from '@app/dialog/reducers/dialog.reducer';
import { getStorefront } from '@app/shared/reducers/storefront.reducer';
import { popErrorToast, popSuccessToast } from '@app/shared/reducers/toast.reducer';
import { AuthService } from '@app/shared/services/auth.service';
import { CognitoService } from '@app/shared/services/cognito.service';
import { FormErrors, formValidationErrors$ } from '@app/shared/utils/form-helper';
import { I18nService } from '@app/shared/utils/i18n.service';
import { firstNotEmpty } from '@app/shared/utils/util';
import { getUser, isLoggedUser } from '@app/user/reducers/user.reducer';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, startWith, withLatestFrom } from 'rxjs/operators';

interface FederatedLoginURL {
	name: string;
	url: string;
}

/** Login form component */
@UntilDestroy()
@Component({
	selector: 'g-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
	@Input() readonly isInvertedStyle: boolean = false;
	@Input() readonly isDialog: boolean = false;
	@Input() readonly navigateToAfterLogin?: string;
	@Input() readonly submitText = $localize`:@@LoginFormSubmit:Login`;
	@Input() readonly submitIcon?: IconDefinition;
	@Input() readonly submitDisabled: boolean = false;
	@Input() readonly submitTooltip: string = '';
	@Output() readonly loggedIn = new EventEmitter<{ isNewUser: boolean }>();

	loginForm!: UntypedFormGroup;
	formErrors$!: FormErrors;
	submitTooltip$!: Observable<string>;
	isRegistrationAllowed$!: Observable<boolean>;
	isTemporaryPasswordResent = false;
	showLoginForm = true;
	federatedLoginUrls: FederatedLoginURL[] = [];
	readonly isAuthenticating$ = new BehaviorSubject<boolean>(false);
	readonly showSsoAuthLoading$ = new BehaviorSubject<boolean>(false);
	readonly ssoStateIcon$ = new BehaviorSubject<IconDefinition | undefined>(undefined);
	readonly ssoAuthenticationError$ = new BehaviorSubject<string | undefined>(undefined);
	readonly icons = { faSpinner };

	private readonly validationMessages = {
		email: {
			required: $localize`:@@ContactInfoEmailRequired:Email is required.`,
			email: $localize`:@@ContactInfoEmailFormat:Email has to be in right format`,
			maxlength: $localize`:@@ContactInfoEmailMaxLength:Email cannot be over 255 characters long`,
		},
		password: {
			required: $localize`:@@ChangePasswordPasswordRequired:Password is required.`,
			minlength: $localize`:@@ChangePasswordPasswordMinLength:Password must be at least 10 characters long.`,
			maxlength: $localize`:@@ChangePasswordPasswordMaxLength:Password cannot be over 255 characters long.`,
		},
	};

	constructor(
		private readonly cognito: CognitoService,
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
		private readonly fb: UntypedFormBuilder,
		private readonly authService: AuthService,
		private readonly i18n: I18nService,
	) {}

	/** Initialize component */
	ngOnInit(): void {
		this.buildForm();
		this.store
			.select(getStorefront)
			.pipe(
				firstNotEmpty,
				map((store) => {
					const federatedLoginUrls = (store?.cognito_config?.federation?.login_urls ?? [])
						.map((link) => ({
							name: this.i18n.getText(link?.name),
							url: this.i18n.getText(link?.url),
						}))
						.filter(({ name, url }) => !!name && !!url);
					const isFederationDefaultLoginMethod =
						federatedLoginUrls.length > 0 &&
						store?.cognito_config?.federation?.is_default_login_method === true;
					return { federatedLoginUrls, isFederationDefaultLoginMethod };
				}),
			)
			.subscribe(({ federatedLoginUrls, isFederationDefaultLoginMethod }) => {
				this.federatedLoginUrls = federatedLoginUrls;
				this.showLoginForm = !isFederationDefaultLoginMethod;
			});

		combineLatest([
			this.authService.isAuthenticatingSso$.pipe(withLatestFrom(this.showSsoAuthLoading$)),
			this.authService.ssoAuthenticationError$,
		])
			.pipe(untilDestroyed(this))
			.subscribe(([[ssoAuthenticating, isAuthInProgress], ssoAuthError]) => {
				if (ssoAuthenticating) {
					this.ssoStateIcon$.next(faSpinner);
					this.showSsoAuthLoading$.next(true);
					this.ssoAuthenticationError$.next(undefined);
				} else if (ssoAuthError) {
					this.ssoStateIcon$.next(faCircleXmark);
					this.ssoAuthenticationError$.next(ssoAuthError);
					setTimeout(() => {
						this.ssoStateIcon$.next(undefined);
						this.showSsoAuthLoading$.next(false);
					}, 2000);
				} else if (isAuthInProgress) {
					// Login was successful if SSO auth was completed with no errors but we're still displaying it as in progress.
					this.ssoStateIcon$.next(faCircleCheck);
				} else {
					this.showSsoAuthLoading$.next(false);
				}
			});

		this.isRegistrationAllowed$ = this.store.select(getStorefront).pipe(
			firstNotEmpty,
			map((store) => store?.storefront?.allow_registration === true),
		);

		// Redirect after login
		this.store
			.select(getUser)
			.pipe(first((user) => isLoggedUser(user)))
			// This handles both onSubmit and federated login navigations
			.subscribe(() => this.navigateAfterLogin());
	}

	/** Login user */
	async onSubmit(): Promise<void> {
		if (this.isAuthenticating$.value) return;
		this.isAuthenticating$.next(true);
		this.isTemporaryPasswordResent = false;
		const details = {
			email: (this.loginForm.get('email')?.value as string).toLowerCase() || '',
			password: (this.loginForm.get('password')?.value as string) || '',
		};
		return this.cognito
			.login(details, true, this.navigateToAfterLogin)
			.then((user) => {
				if (user?.isNewUser) {
					this.store.dispatch(
						popSuccessToast({ title: $localize`:@@ToastChangePassword:Please change your password.` }),
					);
				} else {
					this.loggedIn.emit({ isNewUser: user?.isNewUser || false });
				}

				this.isAuthenticating$.next(false);
				this.store.dispatch(closeMobileMenu());
				this.navigateAfterLogin();
			})
			.catch((err) => {
				this.isAuthenticating$.next(false);
				// istanbul ignore if -- this should never happen.
				if (!(err instanceof Error)) throw err;
				// If user account has expired call API to resend temporary password and show
				// notification to the user
				if (
					typeof err.message === 'string' &&
					err.message.startsWith('Temporary password has expired')
				) {
					this.store.dispatch(resendTemporaryPassword({ body: { email: details.email } }));
					this.isTemporaryPasswordResent = true;
					return;
				}
				// If "User not confirmed" or "Email not verified" error is received, redirect user to
				// the appropriate confirmation page
				if (!['Email not verified', 'User is not confirmed.'].includes(err.message)) {
					this.store.dispatch(popErrorToast({ title: err.message }));
					return;
				}
				const isEmailError = err.message === 'Email not verified';
				const errorMessage = isEmailError
					? $localize`:@@ToastEmailNotVerified:Email address not verified.`
					: $localize`:@@ToastUserNotConfirmed:User account not confirmed.`;
				this.store.dispatch(popErrorToast({ title: errorMessage }));
				if (isEmailError)
					void this.router.navigate(['user', 'confirm'], {
						queryParams: { 'is-verify': true },
					});
				else void this.router.navigate(['user', 'confirm']);
				this.loggedIn.emit({ isNewUser: true });
				this.store.dispatch(closeMobileMenu());
			});
	}

	/** Get form control style class */
	getStyleClass(type: 'group' | 'control' = 'control'): Record<string, boolean> {
		return {
			[`form-${type}-inverted`]: this.isInvertedStyle,
			[`form-${type}-primary`]: !this.isInvertedStyle,
		};
	}

	/** Toggle login form */
	toggleLoginForm(event: Event): void {
		event.preventDefault();
		this.showLoginForm = !this.showLoginForm;
	}

	/** Send user to registration page */
	register(): void {
		void this.router.navigate(['/user/onboard']);
		this.loggedIn.emit({ isNewUser: true });
	}

	/** Open forgot password dialog */
	forgot(): void {
		this.store.dispatch(open(Dialog.PASSWORD_REQUEST, { hideLoginLink: !this.isDialog }));
	}

	private readonly navigateAfterLogin = () => {
		if (this.navigateToAfterLogin) void this.router.navigateByUrl(this.navigateToAfterLogin);
	};

	/** Build login form */
	private buildForm() {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
			password: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(255)]],
		});
		this.formErrors$ = formValidationErrors$(this.loginForm, this.validationMessages);

		this.submitTooltip$ = this.formErrors$.pipe(
			startWith({}),
			map((errors) => {
				// TODO: Update tests for 100% coverage and condense this into a single expression.
				if (this.submitTooltip) {
					return this.submitTooltip;
				}

				if (!this.loginForm.valid) {
					/* istanbul ignore next - difficult to test validation states */
					return Object.values(errors).find(Boolean) || $localize`Please fill the required fields`;
				}
				/* istanbul ignore next - same as above */
				return '';
			}),
		);
	}
}
