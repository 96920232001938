// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module CustomersCanvas */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Performs the editor printfile upload
 * 
 * @param {module:types.EditorFileUploadInfoDto} body Editor printfile information
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.PrintfileDto>} Success
 */
export function uploadEditorFile(body: api.EditorFileUploadInfoDto, options?: UploadEditorFileOptions): Promise<api.Response<api.PrintfileDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(uploadEditorFileOperation, parameters);
}

/**
 * Call Customer's Canvas API to generate proof images from a state id
 * 
 * @param {module:types.GenerateProofImagesParameters} body Editor printfile information
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.ProofImageDto>} Proof images ready to be displayed
 */
export function generateProofImages(body: api.GenerateProofImagesParameters, options?: GenerateProofImagesOptions): Promise<api.Response<api.ProofImageDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(generateProofImagesOperation, parameters);
}

/**
 * Checks if user state file exists or not
 * 
 * @param {string} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @return {Promise<module:types.StateFileStatusDto>} State file status
 */
export function getStateFileStatus(id: string, options?: GetStateFileStatusOptions): Promise<api.Response<api.StateFileStatusDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getStateFileStatusOperation, parameters);
}

export interface UploadEditorFileOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GenerateProofImagesOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

export interface GetStateFileStatusOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
}

const uploadEditorFileOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/upload-editor-file',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const generateProofImagesOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/generate-proof-images',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getStateFileStatusOperation: api.OperationInfo = {
  path: '/v2/customers-canvas/state-file-status/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
