import { I18nService } from '../utils/i18n.service';
import { Directive, HostListener, Input } from '@angular/core';

/** Prepends locale to relative hrefs in anchor tags */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[href]' })
export class LinkRewriteDirective {
	@Input({ required: true }) href!: string;

	constructor(private readonly i18n: I18nService) {}

	/** Prepends locale to relative hrefs in anchor tags */
	@HostListener('click', ['$event'])
	rewrite(event: MouseEvent): void {
		if (this.isMailtoLink()) return;
		event.preventDefault();
		const href = window.location.hostname !== 'localhost' ? this.formatHref() : this.href;

		const target = (event.target as HTMLAnchorElement).target || '_self';

		window.open(href, target);
	}

	/** Returns href with locale prepended if it's a relative url */
	formatHref(): string {
		if (this.isAbsoluteUrl()) return this.href;
		const href = this.href.startsWith('/') ? this.href : `/${this.href}`;
		return `/${this.i18n.locale}${href}`;
	}

	/** Returns true if href is an absolute url */
	private isAbsoluteUrl(): boolean {
		return /^(?:https?:\/\/|\/\/)/i.test(this.href);
	}

	/** Returns true if href is a mailto link */
	private isMailtoLink(): boolean {
		return /^mailto/i.test(this.href);
	}
}
