import * as AddressBookApi from '@app/api/action/AddressBook';
import { ApiAction, RootReducer } from '@app/app.reducers';
import { isApiActionSuccess } from '@app/shared/utils/api-utils';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

export const RESET_SAVED_ADDRESS = 'mygrano/address-book/RESET_SAVED_ADDRESS';
export const resetSavedAddress = () => ({ type: RESET_SAVED_ADDRESS }) as const;

export type AddressModel = Partial<Exclude<api.AddressBook['address'], undefined>>;
export type AddressFormModel = Omit<AddressModel, 'address_id'> & {
	id?: number;
	address_type?: {
		is_shipping: boolean;
		is_billing: boolean;
		is_common: boolean;
	};
	set_as_default?: boolean;
};
export type AddressType = keyof api.OrderAddressesDto;

// TODO: Use RemoteData.
export interface SavedAddress {
	isProcessing?: boolean;
	isSuccess?: boolean;
	isError?: boolean;
	addressForm?: AddressFormModel;
	address?: api.AddressBook;
}

export interface State {
	addresses: api.AddressBook[];
	savedAddress?: SavedAddress;
}

export const initialState: State = {
	addresses: [],
};

type AddressAction =
	| ReturnType<typeof resetSavedAddress>
	| ApiAction<typeof AddressBookApi.GET_ADDRESSES, api.AddressBook[]>
	| ApiAction<typeof AddressBookApi.CREATE, api.AddressBook>
	| ApiAction<typeof AddressBookApi.UPDATE, api.AddressBook>
	| ApiAction<typeof AddressBookApi.DESTROY, api.AddressBook>
	| ApiAction<typeof AddressBookApi.CREATE_START, never>
	| ApiAction<typeof AddressBookApi.UPDATE_START, never>
	| ApiAction<typeof AddressBookApi.DESTROY_START, never>;

/** Reducer for the address book. */
export function reducer(state = initialState, action: AddressAction): State {
	switch (action.type) {
		case RESET_SAVED_ADDRESS:
			return { ...state, savedAddress: { ...initialState.savedAddress } };

		case AddressBookApi.CREATE_START:
			return { ...state, savedAddress: { ...state.savedAddress, isProcessing: true } };
		case AddressBookApi.CREATE:
			return {
				...state,
				addresses: isApiActionSuccess(action) ? [...state.addresses, action.payload] : [],
				savedAddress: {
					addressForm: isApiActionSuccess(action)
						? convertDatabaseRowToAddressFormModel(action.payload)
						: undefined,
					isProcessing: false,
					isSuccess: isApiActionSuccess(action),
					isError: action.error,
					address: isApiActionSuccess(action) ? action.payload : undefined,
				},
			};

		case AddressBookApi.UPDATE_START:
			return { ...state, savedAddress: { ...state.savedAddress, isProcessing: true } };
		case AddressBookApi.UPDATE:
			return {
				...state,
				addresses: isApiActionSuccess(action)
					? state.addresses.map((addr) => (addr.id === action.payload.id ? action.payload : addr))
					: [],
				savedAddress: {
					...state.savedAddress,
					isProcessing: false,
					isSuccess: isApiActionSuccess(action),
					isError: action.error,
					address: isApiActionSuccess(action) ? action.payload : undefined,
				},
			};

		case AddressBookApi.DESTROY_START:
			return {
				...state,
				savedAddress: { ...state.savedAddress, isProcessing: true },
			};
		case AddressBookApi.DESTROY:
			return {
				...state,
				addresses: isApiActionSuccess(action)
					? state.addresses.filter((address) => address.id !== action.payload.id)
					: [],
				savedAddress: {
					...state.savedAddress,
					isProcessing: false,
					isSuccess: isApiActionSuccess(action),
					isError: action.error,
					address: isApiActionSuccess(action) ? action.payload : undefined,
				},
			};

		case AddressBookApi.GET_ADDRESSES:
			return { ...state, addresses: action.error === true ? [] : action.payload };

		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.addressBook;

export const getAddresses = setName(
	'getAddresses',
	createSelector(getState, (state) => state.addresses),
);

export const getAddressesOfType = (addressType: AddressType, isCommon = false) =>
	setName(
		'getAddressesOfType',
		createSelector(
			getAddresses,
			(addresses) =>
				addresses?.filter((a) => a[`is_${addressType}`] && !!a.is_common === isCommon) ?? [],
		),
	);

export const getUserDefaultAddress = (addressType: AddressType) =>
	setName(
		'getUserDefaultAddress',
		createSelector(getAddresses, (addresses) =>
			addresses?.find((a) => a[`is_user_default_${addressType}`]),
		),
	);

export const getSavedAddress = setName(
	'getSavedAddress',
	createSelector(getState, (state) => state.savedAddress),
);

export const getAddressFormData = setName(
	'getAddressFormData',
	createSelector(getSavedAddress, (state) => state?.addressForm),
);

export const getSavedAddressProcessing = setName(
	'getSavedAddressProcessing',
	createSelector(getSavedAddress, (state) => state?.isProcessing),
);

export const getSavedAddressSuccess = setName(
	'getSavedAddressSuccess',
	createSelector(getSavedAddress, (state) => state?.isSuccess),
);

export const getSavedAddressError = setName(
	'getSavedAddressError',
	createSelector(getSavedAddress, (state) => state?.isError),
);

export const getSavedAddressDetails = setName(
	'getSavedAddressDetails',
	createSelector(getState, (state) => state.savedAddress?.address),
);

/** Converts a database row to an address form model. */
export function convertDatabaseRowToAddressFormModel(payload: api.AddressBook): AddressFormModel {
	return {
		id: payload?.id,
		address_name: payload?.address?.address_name,
		first_name: payload?.address?.first_name,
		last_name: payload?.address?.last_name,
		street_name: payload?.address?.street_name,
		zip_code: payload?.address?.zip_code,
		city: payload?.address?.city,
		country: payload?.address?.country,
		company: payload?.address?.company,
		edi_number: payload?.address?.edi_number,
		operator_id: payload?.address?.operator_id,
		operator: payload?.address?.operator,
		address_type: {
			is_shipping: payload?.is_shipping === true,
			is_billing: payload?.is_billing === true,
			is_common: payload?.is_common === true,
		},
	};
}
