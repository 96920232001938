<div fxLayout="row">
	<div class="header" fxLayout="row">
		<h3 class="header-dialog" fxFlex="80" i18n>Log in</h3>
		<g-icon
			[icon]="icons.faXmark"
			class="close-dialog"
			fxFlex="20"
			(click)="modal.close()"
			role="button"
			i18n-title
			title="Close"
		>
		</g-icon>
	</div>
	<div fxFlex="60" fxFlex.lt-sm="95" class="container">
		<p class="intro" *ngIf="dialogData?.isAccountConfirmed">
			<span i18n="@@LoginDialogAccountConfirmed">Your account has been confirmed</span>
		</p>

		<g-login-form
			(loggedIn)="modal.close()"
			[isDialog]="true"
			[isInvertedStyle]="false"
			data-cy="loginDialogLoginForm"
		></g-login-form>
	</div>
</div>
