// istanbul ignore file -- deprecated
import { Injectable } from '@angular/core';

type PrimaryGroupKeys =
	| 'contactInfo'
	| 'toast'
	| 'confirmCode'
	| 'productForm'
	| 'productWorkflowSelect'
	| 'productDesignService'
	| 'validateDesign'
	| 'checkout'
	| 'productEditor'
	| 'addToList'
	| 'cxml';

/**
 * Provides translated strings that are not part of templates
 * @deprecated Let's use the `$localize` template string tag.
 */
@Injectable({ providedIn: 'root' })
export class TranslatedService {
	private readonly translated: Map<string, string>;

	constructor() {
		this.translated = new Map<string, string>();
	}

	/**
	 * Collects translated strings. Should be ran only once, preferably in
	 * TranslatedStringsComponent.ngAfterViewInit().
	 */
	initialize(): void {
		const translatedStrings = document.getElementById('translatedStrings');

		if (!translatedStrings) {
			throw new Error(
				'TranslatedService.initialize() called before TranslatedStringsComponent is ready',
			);
		}

		this.collectStrings(translatedStrings);
	}

	/**
	 * Get a translated string based on string key.
	 * @param stringKey Key used for fetching the array
	 * @return Translated string
	 * @deprecated Let's use the `$localize` template string tag.
	 */
	string = (stringKey: `${PrimaryGroupKeys}.${string}`): string => {
		if (typeof stringKey !== 'string' || !stringKey.length) {
			throw new Error('TranslatedService.string() provided with invalid string key');
		}
		if (!this.translated.get(stringKey)) {
			throw new Error(`Missing translation (${stringKey})`);
		}
		return this.translated.get(stringKey);
	};

	/**
	 * Goes through strings in the TranslatedStringsComponent and collects them for later fetching.
	 * String keys consist of group and string IDs (data-group-id and data-string-id in
	 * TranslatedStringsComponent).
	 */
	private collectStrings(element: Element, keyPrefix = '') {
		if (!(element instanceof Element)) {
			throw new Error('TranslatedService.collectStrings() provided with invalid "element" parameter');
		}
		for (const child of element.children) {
			const childType = child.nodeName.toLowerCase();
			if (childType !== 'span') {
				continue;
			}

			const groupId = child.getAttribute('data-group-id');
			const stringId = child.getAttribute('data-string-id');
			if (!groupId && !stringId) {
				continue;
			}

			if (groupId) {
				// Spans with data-group-id will be considered groups of translated strings or subgroups
				const prefix = keyPrefix.length ? `${keyPrefix}.${groupId}` : groupId;
				this.collectStrings(child, prefix);
			} else {
				// Spans with data-string-id will be considered translated strings
				const stringKey = keyPrefix.length ? `${keyPrefix}.${stringId}` : stringId;
				this.translated.set(stringKey, (child as any).innerHTML);
			}
		}
	}
}
