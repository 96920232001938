// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Upload */
// Auto-generated, edits will be overwritten
import * as Upload from '../Upload';

export const GET_UPLOAD_URL_START = 's/Upload/GET_UPLOAD_URL_START';
export const GET_UPLOAD_URL = 's/Upload/GET_UPLOAD_URL';
export type GET_UPLOAD_URL = any;

export function getUploadUrl(options?: Upload.GetUploadUrlOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_UPLOAD_URL_START, meta: { info, params: { options } } });
    return Upload.getUploadUrl(options)
      .then(response => dispatch({
        type: GET_UPLOAD_URL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_FILE_STATUS_START = 's/Upload/UPDATE_FILE_STATUS_START';
export const UPDATE_FILE_STATUS = 's/Upload/UPDATE_FILE_STATUS';
export type UPDATE_FILE_STATUS = any;

export function updateFileStatus(options?: Upload.UpdateFileStatusOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_FILE_STATUS_START, meta: { info, params: { options } } });
    return Upload.updateFileStatus(options)
      .then(response => dispatch({
        type: UPDATE_FILE_STATUS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_FILE_STATUS_RECEIPT_HANDLE_START = 's/Upload/UPDATE_FILE_STATUS_RECEIPT_HANDLE_START';
export const UPDATE_FILE_STATUS_RECEIPT_HANDLE = 's/Upload/UPDATE_FILE_STATUS_RECEIPT_HANDLE';
export type UPDATE_FILE_STATUS_RECEIPT_HANDLE = any;

export function updateFileStatusReceiptHandle(options?: Upload.UpdateFileStatusReceiptHandleOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_FILE_STATUS_RECEIPT_HANDLE_START, meta: { info, params: { options } } });
    return Upload.updateFileStatusReceiptHandle(options)
      .then(response => dispatch({
        type: UPDATE_FILE_STATUS_RECEIPT_HANDLE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const FILE_STATUS_BY_KEY_START = 's/Upload/FILE_STATUS_BY_KEY_START';
export const FILE_STATUS_BY_KEY = 's/Upload/FILE_STATUS_BY_KEY';
export type FILE_STATUS_BY_KEY = api.FileStatus;

export function fileStatusByKey(key: string, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: FILE_STATUS_BY_KEY_START, meta: { info, params: { key } } });
    return Upload.fileStatusByKey(key)
      .then(response => dispatch({
        type: FILE_STATUS_BY_KEY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const REPORT_START = 's/Upload/REPORT_START';
export const REPORT = 's/Upload/REPORT';
export type REPORT = any;

export function report(id: number, options?: Upload.ReportOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: REPORT_START, meta: { info, params: { id, options } } });
    return Upload.report(id, options)
      .then(response => dispatch({
        type: REPORT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const STATUS_START = 's/Upload/STATUS_START';
export const STATUS = 's/Upload/STATUS';
export type STATUS = api.Printfile;

export function status(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: STATUS_START, meta: { info, params: { id } } });
    return Upload.status(id)
      .then(response => dispatch({
        type: STATUS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const START_PREFLIGHT_START = 's/Upload/START_PREFLIGHT_START';
export const START_PREFLIGHT = 's/Upload/START_PREFLIGHT';
export type START_PREFLIGHT = any;

export function startPreflight(options?: Upload.StartPreflightOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: START_PREFLIGHT_START, meta: { info, params: { options } } });
    return Upload.startPreflight(options)
      .then(response => dispatch({
        type: START_PREFLIGHT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPLOAD_EDITOR_TEMPLATE_START = 's/Upload/UPLOAD_EDITOR_TEMPLATE_START';
export const UPLOAD_EDITOR_TEMPLATE = 's/Upload/UPLOAD_EDITOR_TEMPLATE';
export type UPLOAD_EDITOR_TEMPLATE = {
    templates?: string[],
    links?: string[]
  };

export function uploadEditorTemplate(name: string, templates: File, options?: Upload.UploadEditorTemplateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPLOAD_EDITOR_TEMPLATE_START, meta: { info, params: { name, templates, options } } });
    return Upload.uploadEditorTemplate(name, templates, options)
      .then(response => dispatch({
        type: UPLOAD_EDITOR_TEMPLATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const FAKE_UPLOAD_START = 's/Upload/FAKE_UPLOAD_START';
export const FAKE_UPLOAD = 's/Upload/FAKE_UPLOAD';
export type FAKE_UPLOAD = undefined;

export function fakeUpload(options?: Upload.FakeUploadOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: FAKE_UPLOAD_START, meta: { info, params: { options } } });
    return Upload.fakeUpload(options)
      .then(response => dispatch({
        type: FAKE_UPLOAD,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_FILE_METADATA_START = 's/Upload/GET_FILE_METADATA_START';
export const GET_FILE_METADATA = 's/Upload/GET_FILE_METADATA';
export type GET_FILE_METADATA = api.PrintfileDto;

export function getFileMetadata(key: string, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_FILE_METADATA_START, meta: { info, params: { key } } });
    return Upload.getFileMetadata(key)
      .then(response => dispatch({
        type: GET_FILE_METADATA,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
