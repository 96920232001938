import menuIcon from '!!url-loader!./images/open.svg';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RootReducer, Store } from '@app/app.reducers';
import { openMobileMenu } from '@app/core/reducers/mobile-menu.reducer';
import { getLogoUrl, getMobileLogoUrl } from '@app/shared/reducers/storefront.reducer';
import { ViewportService } from '@app/shared/services/viewport.service';

@Component({
	selector: 'g-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	logoUrl$ = this.store.select(getLogoUrl);
	mobileLogoUrl$ = this.store.select(getMobileLogoUrl);
	menuIcon = this.sanitizer.bypassSecurityTrustUrl(menuIcon);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly sanitizer: DomSanitizer,
		public readonly viewport: ViewportService,
	) {}

	openMobileMenu(): void {
		this.store.dispatch(openMobileMenu());
	}
}
