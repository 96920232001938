<div class="price-summary-content">
	<div
		class="total-price-breakdown"
		*ngIf="
			(showPriceBreakdown && (totalPrice$ | async) && (products$ | async)?.length) || isConfirmedOrder
		"
	>
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Product price (no tax):</strong> </div>
			<div>
				<span class="total-price"
					>{{ (totalPrice$ | async)?.productsPriceWithoutTax | number: '1.2-2' }} €</span
				>
			</div>
		</div>
		<div fxLayout="row" *ngIf="showCarbonNeutralCompensation">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong i18n="@@CarbonNeutralCompensationLabel">Carbon neutral product (no tax):</strong>
				<span>&nbsp;</span>
				<gds-popover placement="right">
					<g-icon [icon]="icons.faCircleQuestion" slot="trigger" />
					<div class="carbon-note">
						<span i18n="@@CarbonNeutralCompensationInfoText">
							Carbon neutral products are products whose CO2-emissions related to its production have
							been calculated and compensated for by a carbon offset project.
						</span>
						<a [attr.href]="carbonNeutralCompensationMarketingPageUrl" target="_blank"
							><span i18n="@@CarbonNeutralCompensationInfoLink">Read more</span></a
						>
					</div>
				</gds-popover>
			</div>
			<div>
				<span class="total-price"
					>{{ (totalPrice$ | async)?.carbonNeutralCompensation | number: '1.2-2' }} €</span
				>
			</div>
		</div>
		<div fxLayout="row" *ngIf="showEnergyAndServiceFee">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong i18n="@@EnergyAndServiceFeeLabel">Energy and service fee (no tax):</strong>
				<span>&nbsp;</span>
				<gds-popover placement="right">
					<g-icon [icon]="icons.faCircleQuestion" slot="trigger" />
					<div class="energy-and-service-fee-note">
						<span i18n="@@EnergyAndServiceFeeInfoText">
							The prolonged and strong rise in raw material, energy and logistics costs will increase
							costs in our operations significantly. In order for us to continue to secure quick and
							smooth services, we will have to pass on some of these costs to our customers.
						</span>
						<a [attr.href]="energyAndServiceFeePageUrl" target="_blank"
							><span i18n="@@EnergyAndServiceFeeInfoLink">Read more</span></a
						>
					</div>
				</gds-popover>
			</div>
			<div>
				<span class="total-price"
					>{{ (totalPrice$ | async)?.energyAndServiceFee | number: '1.2-2' }} €</span
				>
			</div>
		</div>
		<div fxLayout="row" *ngIf="discount">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong>
					<span i18n>Discount</span>
					<span *ngIf="discount.type === 'percent'"> ({{ discount.percentage }} %)</span>:
				</strong>
			</div>
			<div>
				<span class="total-price">- {{ discount.amount }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="(totalPrice$ | async)?.additionalFee !== '0.00'">
			<div fxFlexAlign="center" fxFlex="grow">
				<strong><span i18n>Additional fees (no tax):</span></strong>
			</div>
			<div>
				<span class="total-price">{{ (totalPrice$ | async)?.additionalFee | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row" *ngIf="(totalPrice$ | async)?.handlingFee !== '0.00'">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Handling (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ (totalPrice$ | async)?.handlingFee | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Shipping (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ (totalPrice$ | async)?.shippingPrice | number: '1.2-2' }} €</span>
			</div>
		</div>
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Billing (no tax):</strong> </div>
			<div>
				<span class="total-price">{{ (totalPrice$ | async)?.billingPrice | number: '1.2-2' }} €</span>
			</div>
		</div>
		@for (taxRow of taxRows; track $index) {
			<div fxLayout="row" class="tax-row">
				<div fxFlexAlign="center" fxFlex="grow">
					<strong i18n="Tax amount (xx %):">Tax amount </strong
					><strong>({{ taxRow.percentage }} %):</strong>
				</div>
				<div>
					<span class="total-price">{{ taxRow.amount | number: '1.2-2' }} €</span>
				</div>
			</div>
		}
		<div fxLayout="row">
			<div fxFlexAlign="center" fxFlex="grow"> <strong i18n>Total tax:</strong> </div>
			<div>
				<span class="total-price">{{ (totalPrice$ | async)?.taxes?.total | number: '1.2-2' }} €</span>
			</div>
		</div>
	</div>

	<div
		class="discount-container"
		*ngIf="discount && !showPriceBreakdown && (products$ | async)?.length"
		fxLayout="row"
		fxFlexAlign="center"
	>
		<div fxFlexAlign="start" fxFlex="68 1 auto">
			<strong>
				<span i18n>Discount</span>
				<span *ngIf="discount.type === 'percent'"> ({{ discount.percentage }} %)</span>:
			</strong>
		</div>
		<div fxFlex="1 1 auto"></div>
		<div class="total-price-wrapper" fxFlex="1 1 auto">
			<span class="total-price">- {{ discount.amount }} €</span>
		</div>
	</div>
	<div
		class="total-price-container"
		*ngIf="(products$ | async)?.length && (totalPrice$ | async)"
		fxLayout="row"
		fxFlexAlign="center"
	>
		<div fxFlexAlign="start" fxFlex="68 1 auto">
			<strong *ngIf="showPriceBreakdown" i18n>Total (including tax):</strong>
			<strong *ngIf="!showPriceBreakdown" i18n>Total:</strong>
		</div>
		<div
			class="total-price-wrapper"
			fxFlex="1 1 auto"
			[ngClass]="{ 'is-loading': isFetchingTotalPrice$ | async }"
			data-cy="totalPriceContainer"
		>
			<g-icon [icon]="icons.faSpinner" pulse />
			<span *ngIf="showPriceBreakdown" class="total-price">
				{{ (totalPrice$ | async)?.totalPrice | number: '1.2-2' }} €
			</span>
			<span *ngIf="!showPriceBreakdown && (showPricesWithTax$ | async)" class="total-price">
				{{ (totalPrice$ | async)?.discountedProductsPriceWithAdditionalFee | number: '1.2-2' }}
				€
			</span>
			<span *ngIf="!showPriceBreakdown && !(showPricesWithTax$ | async)" class="total-price">
				{{ (totalPrice$ | async)?.discountedProductsPriceWithAdditionalFeeWithoutTax | number: '1.2-2' }}
				€
			</span>
		</div>
	</div>
	<g-vat-selector *ngIf="!showPriceBreakdown && (products$ | async)?.length && showPrices" />
</div>
