import { Component, HostBinding, OnInit } from '@angular/core';
import { PreviewTokenService } from '@app/shared/services/preview-token.service';

@Component({
	selector: 'g-preview-banner',
	templateUrl: './preview-banner.component.html',
	styleUrls: ['./preview-banner.component.scss'],
})
export class PreviewBannerComponent implements OnInit {
	@HostBinding('class.preview-mode') showPreviewBanner = false;

	constructor(private readonly previewTokenService: PreviewTokenService) {}

	ngOnInit(): void {
		this.previewTokenService.token$.subscribe((previewToken) => {
			if (previewToken) {
				this.showPreviewBanner = true;
			}
		});
	}
}
