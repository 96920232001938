import type { I18nService } from './i18n.service';

/* Recursively order categories asc */
export const sortCategories = (categories: api.CategoryDto[], i18n: I18nService): api.CategoryDto[] => {
	return (
		Array.isArray(categories)
			? [...categories].sort((a, b) =>
					i18n.getText(a.locale?.name).localeCompare(i18n.getText(b.locale?.name)),
				)
			: categories
	)?.map?.((category) => ({
		...category,
		children: category.children ? sortCategories(category.children, i18n) : undefined,
	}));
};
