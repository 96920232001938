/* istanbul ignore file */
import { Directive, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Copied due to the package not being maintained. Tests were using karma + jasmine and haven't been copied.
 * @see https://github.com/anein/angular2-trim-directive/blob/master/src/input-trim.directive.ts
 */
@Directive({
	standalone: true,
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'input[trim], textarea[trim]',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InputTrimDirective, multi: true }],
})
export class InputTrimDirective implements ControlValueAccessor {
	/** Get a value of the trim attribute if it was set. */
	@Input() trim: 'blur' | 'input' | '' = '';

	/** Keep the value of input element in a cache. */
	private _value?: string | null;

	constructor(
		@Inject(Renderer2) private readonly renderer: Renderer2,
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLInputElement>,
	) {
		this.renderer = renderer;
		this.elementRef = elementRef;
	}

	/** Get the type of input element. */
	private get _type(): string {
		return this.elementRef.nativeElement.type || 'text';
	}

	/** Updates the value on the blur event. */
	@HostListener('blur', ['$event.target.value'])
	onBlur(value: string): void {
		this.updateValue('blur', value.trim());
		this.onTouched();
	}

	/** Updates the value on the input event. */
	@HostListener('input', ['$event.target.value'])
	onInput(value: string): void {
		this.updateValue('input', value);
	}

	onChange = (_: unknown): void => {};
	onTouched = (): void => {};

	/** Registers a callback function that should be called when the control's value changes in the UI. */
	registerOnChange(fn: (_: unknown) => void): void {
		this.onChange = fn;
	}

	/** Registers a callback function that should be called when the control receives a blur event. */
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/** Writes a new value to the element based on the type of input element. */
	public writeValue(value: unknown): void {
		/**
		 * The Template Driven Form doesn't automatically convert undefined values to null. We will do,
		 * keeping an empty string as string because the condition `'' || null` returns null what
		 * could change the initial state of a model.
		 * The Reactive Form does it automatically during initialization.
		 * @see https://github.com/anein/angular2-trim-directive/issues/18
		 */
		// eslint-disable-next-line unicorn/no-null
		this._value = value === '' || typeof value !== 'string' ? '' : value || null;
		this.renderer.setProperty(this.elementRef.nativeElement, 'value', this._value);
		/**
		 * Hack: it updates the element value if `setProperty` doesn't set a new value for some reason.
		 * @see https://github.com/anein/angular2-trim-directive/issues/9
		 */
		if (this._type !== 'text' && this._value) {
			this.renderer.setAttribute(this.elementRef.nativeElement, 'value', this._value);
		}
	}

	/** Toggles the disabled state of the element. */
	setDisabledState(isDisabled: boolean): void {
		this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
	}

	/**
	 * Writes the cursor position in safari
	 * @param cursorPosition the cursor's current position
	 */
	private setCursorPointer(cursorPosition: number | null): void {
		// move the cursor to the stored position (Safari usually moves the cursor to the end)
		// setSelectionRange method apply only to inputs of types text, search, URL, tel and password
		if (['text', 'search', 'url', 'tel', 'password'].includes(this._type)) {
			// Ok, for some reason in the tests the type changed is not being catch and because of that
			// this line is executed and causes an error of DOMException, it pass the text without problem
			// But it should be a better way to validate that type change
			this.elementRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
		}
	}

	/**
	 * Trims an input value, and sets it to the model and element.
	 * @param event - input event
	 * @param value - input value
	 */
	private updateValue(event: 'blur' | 'input', value: string): void {
		// Check that trim attribute matched the event type and if so, perform trimming.
		value = this.trim !== '' && event !== this.trim ? value : value.trim();

		const previous = this._value;

		// store the cursor position
		const cursorPosition = this.elementRef.nativeElement.selectionStart;

		// write value to the element.
		this.writeValue(value);

		/**
		 * Update the model only on getting new value, and prevent firing
		 * the `dirty` state when click on empty fields.
		 * @see https://github.com/anein/angular2-trim-directive/issues/17
		 * @see https://github.com/anein/angular2-trim-directive/issues/35
		 * @see https://github.com/anein/angular2-trim-directive/issues/39
		 */
		if ((this._value || previous) && this._value?.trim() !== previous) {
			this.onChange(this._value);
		}

		// check that non-null value is being changed
		const hasTypedSymbol = value && previous && value !== previous;

		// write the cursor position
		if (hasTypedSymbol) this.setCursorPointer(cursorPosition);
	}
}
