import { Injectable } from '@angular/core';
import { StorePersistService } from '@app/checkout/modules/checkout-shared/services/store-persist.service';

@Injectable({ providedIn: 'root' })
export class NewFeatureGuardService {
	constructor(private readonly storePersistService: StorePersistService) {}

	canActivate(): boolean {
		return !!this.storePersistService.getFlag('enableNewLayout');
	}
}
