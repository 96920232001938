import { AddToWishlistDialogComponent } from '../components/add-to-wishlist-dialog/add-to-wishlist-dialog.component';
import { ImageViewerDialogComponent } from '../components/image-viewer-dialog/image-viewer-dialog.component';
import { Injectable, Type } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { ChangePasswordDialogComponent } from '@app/dialog/components/change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from '@app/dialog/components/confirm-dialog/confirm-dialog.component';
import { PasswordRequestDialogComponent } from '@app/dialog/components/password-request-dialog/password-request-dialog.component';
import { TermsDialogComponent } from '@app/dialog/components/terms-dialog/terms-dialog.component';
import { Dialog, close as closeDialog, getActiveDialog } from '@app/dialog/reducers/dialog.reducer';
import { getDebug, constant } from '@app/shared/utils/util';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export type DialogComponent = Type<any> & { name: string; MAX_WIDTH?: string };

export const DIALOGS = new Map<Dialog, DialogComponent>([
	[Dialog.PASSWORD_REQUEST, PasswordRequestDialogComponent],
	[Dialog.CHANGE_PASSWORD, ChangePasswordDialogComponent],
	[Dialog.TERMS, TermsDialogComponent],
	[Dialog.CONFIRM, ConfirmDialogComponent],
	[Dialog.IMAGE_VIEWER, ImageViewerDialogComponent],
	[Dialog.ADD_TO_WISHLIST, AddToWishlistDialogComponent],
]);

export const NOT_DISMISSABLE = new Set(['ProductEditorDialogComponent', 'ConfirmDialogComponent']);

export const DIALOG_DEFAULT_OPTIONS: NgbModalOptions = {
	scrollable: true,
};

// Not using providedIn root because we need a separate instance for each lazy loaded module.
@Injectable({ providedIn: 'any' })
export class DialogService {
	private readonly debug = getDebug('DialogService');
	private activeDialogRef?: NgbModalRef;

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly modalService: NgbModal,
	) {}

	/** @deprecated Use DialogService directly. */
	init(): void {
		this.store.select(getActiveDialog).subscribe((dialog) => {
			const dialogRef = dialog?.dialogRef;
			if (dialogRef === undefined) return this.closeOpenDialogs();
			this.debug('open', dialogRef, dialog);
			this.open(DIALOGS.get(dialogRef), dialog?.dialogData, dialog?.dialogOptions ?? {});
			this.activeDialogRef?.result.then(
				() => this.store.dispatch(closeDialog(dialogRef)),
				() => this.store.dispatch(closeDialog(dialogRef)),
			);
		});
	}

	open(component: DialogComponent, data?: unknown, options?: NgbModalOptions): NgbModalRef {
		this.closeOpenDialogs();

		options = { ...DIALOG_DEFAULT_OPTIONS, ...options };
		if (NOT_DISMISSABLE.has(component.name)) options = { ...options, beforeDismiss: constant(false) };

		this.activeDialogRef = this.modalService.open(component, options);
		document.documentElement.style.setProperty(
			'--g-max-width-modal-dialog',
			component.MAX_WIDTH ?? 'none',
		);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		this.activeDialogRef.componentInstance.dialogData = data ?? {};
		return this.activeDialogRef;
	}

	closeOpenDialogs(): void {
		if (!this.activeDialogRef) return;
		this.activeDialogRef.close();
	}
}
