// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Category */
// Auto-generated, edits will be overwritten
import * as Category from '../Category';

export const GET_CATEGORIES_START = 's/Category/GET_CATEGORIES_START';
export const GET_CATEGORIES = 's/Category/GET_CATEGORIES';
export type GET_CATEGORIES = api.CategoryDto[];

export function getCategories(options?: Category.GetCategoriesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_CATEGORIES_START, meta: { info, params: { options } } });
    return Category.getCategories(options)
      .then(response => dispatch({
        type: GET_CATEGORIES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_CATEGORIES_BY_FILTER_START = 's/Category/GET_CATEGORIES_BY_FILTER_START';
export const GET_CATEGORIES_BY_FILTER = 's/Category/GET_CATEGORIES_BY_FILTER';
export type GET_CATEGORIES_BY_FILTER = api.CategoryDto[];

export function getCategoriesByFilter(storeId: number, filters: string, options?: Category.GetCategoriesByFilterOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_CATEGORIES_BY_FILTER_START, meta: { info, params: { storeId, filters, options } } });
    return Category.getCategoriesByFilter(storeId, filters, options)
      .then(response => dispatch({
        type: GET_CATEGORIES_BY_FILTER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/Category/CREATE_START';
export const CREATE = 's/Category/CREATE';
export type CREATE = api.CategoryDto;

export function create(options?: Category.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return Category.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/Category/ALL_START';
export const ALL = 's/Category/ALL';
export type ALL = api.Category[];

export function all(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: {  } } });
    return Category.all()
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_START = 's/Category/UPDATE_START';
export const UPDATE = 's/Category/UPDATE';
export type UPDATE = api.CategoryDto;

export function update(id: number, options?: Category.UpdateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_START, meta: { info, params: { id, options } } });
    return Category.update(id, options)
      .then(response => dispatch({
        type: UPDATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/Category/DESTROY_START';
export const DESTROY = 's/Category/DESTROY';
export type DESTROY = undefined;

export function destroy(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { id } } });
    return Category.destroy(id)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ONE_START = 's/Category/ONE_START';
export const ONE = 's/Category/ONE';
export type ONE = api.Category;

export function one(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ONE_START, meta: { info, params: { id } } });
    return Category.one(id)
      .then(response => dispatch({
        type: ONE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SCHEMA_START = 's/Category/SCHEMA_START';
export const SCHEMA = 's/Category/SCHEMA';
export type SCHEMA = api.Category;

export function schema(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SCHEMA_START, meta: { info, params: {  } } });
    return Category.schema()
      .then(response => dispatch({
        type: SCHEMA,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
