import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';

/** Bypass Angular XSS defenses and mark the given HTML string as trusted. */
@Pipe({ name: 'trustedHtml', standalone: true })
export class TrustedHtmlPipe implements PipeTransform {
	constructor(private readonly sanitizer: DomSanitizer) {}

	/** Bypass Angular XSS defenses and mark the given HTML string as trusted. */
	transform(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}
