<form class="search-input-container">
	<input
		type="text"
		name="search"
		autocomplete="off"
		data-cy="searchField"
		class="search-input"
		i18n-placeholder="@@SearchFieldPlaceholder"
		placeholder="Search..."
		[formControl]="control"
		[matAutocomplete]="autocomplete"
		matAutocompleteOrigin
		#autocompleteDefaultOrigin="matAutocompleteOrigin"
		[matAutocompleteConnectedTo]="autocompleteOrigin || autocompleteDefaultOrigin"
	/>
	<g-icon [icon]="icons.faMagnifyingGlass" class="icon" />
	<mat-autocomplete
		autoActiveFirstOption
		class="product-search-menu"
		#autocomplete="matAutocomplete"
		[panelWidth]="panelWidth || ''"
	>
		@if (results$ | async; as results) {
			@switch (results.kind) {
				@case (Kinds.Pending) {
					<mat-option disabled>
						<g-icon class="spinner" [icon]="icons.faSpinner" size="2x" pulse />
						<span i18n="@@SearchFieldLoading">Searching...</span>
					</mat-option>
				}
				@case (Kinds.Success) {
					@for (result of results.data | parseSearchResults; track result.id) {
						<mat-option [routerLink]="['product', result.id, result.slug]" data-cy="searchResult">
							<div class="result" [title]="result.locale.product_name | getText">
								<figure class="thumbnail">
									@if (result.images?.master?.fitIn_80x80; as src) {
										<img [ngSrc]="src" i18n-alt="@@SearchFieldProductImgAlt" alt="product image" fill />
									}
								</figure>
								<span class="name">{{ result.locale.product_name | getText }}</span>
							</div>
						</mat-option>
					} @empty {
						<mat-option disabled>
							<g-icon class="spinner" [icon]="icons.faEmptySet" size="2x" />
							<span i18n="@@SearchFieldNoResults">No results</span>
						</mat-option>
					}
				}
				@case (Kinds.Failure) {
					<mat-option class="error" disabled i18n="@@SearchFieldError">Something went wrong.</mat-option>
				}
			}
		}
	</mat-autocomplete>
</form>
