import { IconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface SnackBarContentData {
	title?: string;
	body?: string;
	icon?: IconDefinition;
	action?: string;
	onAction?: () => void;
}

/** Snackbar content component. */
@Component({
	standalone: true,
	imports: [IconComponent, NgIf, MatButtonModule],
	selector: 'g-snackbar-content',
	templateUrl: './snackbar-content.component.html',
	styleUrls: ['./snackbar-content.component.scss'],
	providers: [],
})
export class SnackBarContentComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarContentData) {}
}
