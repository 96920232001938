import { Injectable } from '@angular/core';

export enum Browser {
	InternetExplorer,
	LegacyEdge,
	Edge,
	Other,
}

const BROWSER_BLACKLIST: Browser[] = [Browser.InternetExplorer, Browser.LegacyEdge];

@Injectable({
	providedIn: 'root',
})
export class BrowserSupportService {
	readonly browser: Browser;

	constructor() {
		this.browser = this.getBrowser();
	}

	getBrowser(): Browser {
		const userAgent = window.navigator.userAgent;

		if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
			return Browser.InternetExplorer;
		}

		if (userAgent.includes('Edge/')) {
			return Browser.LegacyEdge;
		}

		if (userAgent.includes('Edg/')) {
			return Browser.Edge;
		}

		return Browser.Other;
	}

	isUserBrowserSupported(): boolean {
		return !BROWSER_BLACKLIST.includes(this.browser);
	}
}
