<div class="vignette-header" *ngIf="content$ | async | foldSuccess; let content">
	<div class="container" fxLayoutAlign="end">
		<div *ngIf="pages$ | async | foldSuccess; let pages">
			<a
				mat-button
				*ngFor="let link of content.links"
				[attr.href]="link.link"
				rel="noopener"
				target="_blank"
				>{{ link.linkText }}</a
			>
			<span *ngFor="let pageKey of content.pages">
				<a
					mat-button
					class="link"
					*ngIf="getPage(pageKey, pages); let page"
					[routerLink]="['/page', page.key]"
				>
					{{ page.content.title }}
				</a>
			</span>
		</div>
		<button
			mat-button
			[matMenuTriggerFor]="localeMenu"
			class="locale-menu-toggle"
			i18n-title="@@LanguageMenuTitle"
			title="Choose language"
		>
			<span class="visually-hidden" i18n="@@ScreenReaderChooseLanguageCurrent"
				>Choose language, current:</span
			>
			<span i18n="@@CurrentLanguage">EN</span>
			<g-icon [icon]="icons.faCaretDown" size="sm"></g-icon>
		</button>
		<mat-menu #localeMenu="matMenu">
			<button
				mat-menu-item
				*ngFor="let languageCode of languageCodes"
				(click)="changeLanguage(languageCode)"
				>{{ languageCode.toUpperCase() }}</button
			>
		</mat-menu>
	</div>
</div>
