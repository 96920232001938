export function getCookie(name: string) {
	const ca: string[] = document.cookie.split(';');

	for (const cookie of ca) {
		if (cookie.includes(name, 0)) {
			const c = cookie.split('=');
			return c[1];
		}
	}

	return '';
}

export function setCookie(name: string, value: string, days: number) {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	document.cookie = `${name}=${value}${expires}; path=/`;
}
