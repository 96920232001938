<div class="order-product-list">
	<div class="products">
		@for (product of products; track product.uuid) {
			<div class="product" data-cy="orderProduct">
				<!-- TODO: Refactor using plain CSS Grid properly. -->
				<div
					class="product-grid"
					gdRows="[image-title] 1fr [info] auto [actions] auto"
					gdRows.gt-xs="auto"
					gdColumns="80px 1fr 1fr"
					[gdColumns.gt-xs]="buttonColumnLayout.get(product.uuid) || '80px 5fr 1fr 1fr'"
					gdAlignColumns="start center"
					gdGap="1rem"
				>
					<g-zoomable-image [images]="productImages.get(product.uuid)" key="fitIn_2000x2000">
						<figure class="thumbnail" gdRow="image-title / span 2" gdRow.gt-xs="auto" gdColumn="1">
							<img
								gFallbackProductImage
								[ngSrc]="
									product?.product_flow_data?.proof_image_thumbnail ||
									product?.product?.images?.master?.fitIn_80x80 ||
									''
								"
								i18n-alt="@@ProductImageAlt"
								alt="Product image"
								loading="auto"
								fill
							/>
						</figure>
					</g-zoomable-image>

					<div
						class="product-details"
						gdRow="image-title"
						gdRow.gt-xs="auto"
						gdColumn="2 / span 2"
						gdColumn.gt-xs="2"
					>
						<a class="product-link" title="{{ product.work_title }}">
							{{ product.work_title }}
						</a>
					</div>
					<div class="product-quantity" gdRow="info" gdRow.gt-xs="auto" gdColumn="2" gdColumn.gt-xs="3">
						{{ product | getProductQuantity }}&nbsp;
						<span>{{ product | getQuantityUnit }}</span>
					</div>
					<div
						*ngIf="showPrices"
						class="product-price"
						gdRow="info"
						gdRow.gt-xs="auto"
						gdColumn="3"
						gdColumn.gt-xs="4"
						gdGridAlign="end center"
					>
						{{ product?.price?.price_with_tax | number: '1.2-2' }} €
					</div>

					<div
						class="product-button"
						*ngIf="isButtonColumnVisible.get(product.uuid)"
						gdRow="actions"
						gdRow.gt-xs="auto"
						gdRows="1fr"
						gdGridAlign.gt-xs="end center"
					>
						<button
							*ngIf="isDownloadButtonVisible.get(product.uuid)"
							class="btn btn-small btn-secondary"
							(click)="downloadProductAsset(product)"
							gdColumn="1"
							i18n="@@OrderProductDownload"
							>Download</button
						>
						<button
							*ngIf="isCopyButtonVisible.get(product.uuid)"
							class="btn btn-small btn-primary"
							[disabled]="
								!isExistingProduct(product) ||
								(product.product?.type === 'stock' &&
									!!product.product?.id &&
									stockLevels.get(product.product!.id!) === 0)
							"
							(click)="copyToBasket(product)"
							gdColumn="2"
							data-cy="copyToBasketButton"
							i18n
							>Copy to basket</button
						>
					</div>
				</div>

				<div
					*ngIf="
						isCopyButtonVisible.get(product.uuid) && isOrderStatusReady && !isExistingProduct(product)
					"
					class="product-notification"
				>
					<span i18n="@@OrderProductListNotificationProductDoesNotExist"
						>Product has been removed from store and cannot be reordered.</span
					>
				</div>
				@if (showPrintfileError(product)) {
					<span class="error" i18n="@@ProductPrintfileExpired"
						>The printfile has expired. Remove this item and re-upload the printfile.</span
					>
				}
				<div
					*ngIf="
						isCopyButtonVisible.get(product.uuid) &&
						!!product.product?.id &&
						stockLevels.get(product.product!.id!)! > 0 &&
						(product | getProductQuantity) > stockLevels.get(product.product!.id!)!
					"
					class="product-notification"
				>
					<span i18n="@@OrderProductListNotificationNotEnoughStock">Not enough products in stock.</span>
				</div>
				<div
					*ngIf="
						isCopyButtonVisible.get(product.uuid) &&
						product.product &&
						product.product.id &&
						stockLevels.get(product.product.id) === 0
					"
					class="product-notification"
				>
					<span i18n="@@OrderProductListNotificationOutOfStock">Product is out of stock.</span>
				</div>
			</div>
		}
	</div>
</div>
