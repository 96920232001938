import { REGISTER } from '@app/api/action/User';
import { RootReducer } from '@app/app.reducers';
import { getId as getStorefrontId } from '@app/shared/reducers/storefront.reducer';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

// State
export interface State {
	username: string;
	passwordResetting: boolean;
	passwordResettingSuccess: boolean;
	requestingToken: boolean;
	requestingTokenSuccess: boolean;
	hasRegistrationSuccess: boolean;
	hasRegistrationError: boolean;
	registrationError?: {
		body: any;
		message: string;
	};
	password: string;
	passwordRetype: string;
	resetToken: string;
}

export const initialState: State = {
	username: '',
	passwordResetting: false,
	passwordResettingSuccess: false,
	requestingToken: false,
	requestingTokenSuccess: false,
	hasRegistrationSuccess: false,
	hasRegistrationError: false,
	password: '',
	passwordRetype: '',
	resetToken: '',
};

// Actions
export const SET_USERNAME = 'mygrano/registration/SET_USERNAME';
export const setUsername = (username) => {
	return {
		type: SET_USERNAME,
		payload: username,
	};
};

export const RESET = 'mygrano/registration/RESET';
export const reset = () => {
	return {
		type: RESET,
	};
};

export const SET_PASSWORD = 'mygrano/registration/SET_PASSWORD';
export const setPassword = (password) => {
	return {
		type: SET_PASSWORD,
		payload: password,
	};
};

export const SET_PASSWORD_RETYPE = 'mygrano/registration/SET_PASSWORD_RETYPE';
export const setPasswordRetype = (password) => {
	return {
		type: SET_PASSWORD_RETYPE,
		payload: password,
	};
};

export const SET_RESET_TOKEN = 'mygrano/registration/SET_RESET_TOKEN';
export const setResetToken = (token) => {
	return {
		type: SET_RESET_TOKEN,
		payload: token,
	};
};

// Reducer
export function reducer(state: State = initialState, action: any) {
	switch (action.type) {
		case SET_USERNAME:
			return {
				...state,
				username: action.payload,
			};

		case SET_PASSWORD:
			return {
				...state,
				password: action.payload,
			};

		case SET_PASSWORD_RETYPE:
			return {
				...state,
				passwordRetype: action.payload,
			};

		case SET_RESET_TOKEN:
			return {
				...state,
				resetToken: action.payload,
			};

		case RESET:
			return initialState;

		case REGISTER:
			return {
				...state,
				hasRegistrationSuccess: !action.error,
				hasRegistrationError: action.error,
				registrationError: action.error ? action.payload : '',
			};

		default:
			return state;
	}
}

// Selectors
export const getState = (state: RootReducer.State) => state.registration;
export const getUsername = setName(
	'getUsername',
	createSelector(getState, (state) => state.username),
);
export const getPasswordResetting = createSelector(getState, (state) => state.passwordResetting);
export const getPasswordResettingSuccess = createSelector(
	getState,
	(state) => state.passwordResettingSuccess,
);
export const getRequestingToken = setName(
	'getRequestingToken',
	createSelector(getState, (state) => state.requestingToken),
);
export const getRequestingTokenSuccess = setName(
	'getRequestingTokenSuccess',
	createSelector(getState, (state) => state.requestingTokenSuccess),
);
export const getPassword = createSelector(getState, (state) => state.password);
export const getPasswordRetype = createSelector(getState, (state) => state.passwordRetype);
export const getResetToken = createSelector(getState, (state) => state.resetToken);
export const getPasswordResetOptions = createSelector(
	getStorefrontId,
	getUsername,
	(storeId, username) => {
		return { body: { store_id: storeId, email: username } };
	},
);
export const getChangePasswordOptions = createSelector(
	getPassword,
	getPasswordRetype,
	getResetToken,
	(password, passwordRetype, token) => {
		return { body: { password, password_again: passwordRetype, token } };
	},
);
export const getHasRegistrationSuccess = setName(
	'getHasRegistrationSuccess',
	createSelector(getState, (state) => state.hasRegistrationSuccess),
);
export const getHasRegistrationError = setName(
	'getHasRegistrationError',
	createSelector(getState, (state) => state.hasRegistrationError),
);
export const getRegistrationError = setName(
	'getRegistrationError',
	createSelector(getState, (state) => state.registrationError),
);
