<div class="container header-area">
	@if (viewport.isMediumOrLarger$ | async) {
		<div class="header-area-content" fxLayout="row" fxLayoutAlign="space-between">
			<div fxLayoutAlign="start center">
				<a [routerLink]="['']" class="logo" fxLayoutAlign="start center">
					<img
						loading="lazy"
						[gResponsiveSrc]="logoUrl$ | async"
						width="150"
						height="45"
						i18n-alt="@@StoreLogoAlt"
						alt="Store logo"
					/>
				</a>
				<g-navigation fxFlexAlign="center" />
			</div>
			<div fxLayoutAlign="end">
				<g-search-field fxFlexAlign="center" panelWidth="460px" />
				<g-nav-actions mode="full" fxFlexAlign="center" />
			</div>
		</div>
	} @else {
		<div class="header-area-content is-mobile-menu" fxLayout="row" fxLayoutAlign="space-between center">
			<button
				type="button"
				(click)="openMobileMenu()"
				class="open-menu-button"
				fxFlex="33%"
				fxLayoutAlign="start"
			>
				<img
					i18n-alt="@@OpenMobileMenuIconAlt"
					alt="open menu"
					[src]="menuIcon"
					class="open-menu"
					width="36"
					height="96"
				/>
			</button>
			<div fxFlex="33%" fxLayoutAlign="center">
				<a [routerLink]="['']" class="logo mobile-logo">
					<img
						loading="lazy"
						[gResponsiveSrc]="mobileLogoUrl$ | async"
						width="64"
						height="45"
						i18n-alt="@@StoreLogoAlt"
						alt="Store logo"
					/>
				</a>
			</div>
			<div fxFlex="33%" fxLayoutAlign="end">
				<g-nav-actions mode="cart" />
			</div>
		</div>
	}
</div>
