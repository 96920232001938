import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RootReducer, Store } from '@app/app.reducers';
import { DialogBase } from '@app/dialog/dialog-base';
import { popSuccessToast } from '@app/shared/reducers/toast.reducer';
import { TranslatedService } from '@app/shared/services/translated.service';
import { FormErrors, formValidationErrors$, ValidationMessages } from '@app/shared/utils/form-helper';
import { hasProp } from '@app/shared/utils/util';

@Component({
	selector: 'g-add-to-wishlist-dialog',
	templateUrl: './add-to-wishlist-dialog.component.html',
	styleUrls: ['./add-to-wishlist-dialog.component.scss'],
})
export class AddToWishlistDialogComponent extends DialogBase implements OnInit {
	form: UntypedFormGroup;
	formErrors$: FormErrors;
	validationMessages: ValidationMessages;
	private accept: (form: unknown) => Promise<void>;

	constructor(
		protected store: Store<RootReducer.State>,
		private readonly translated: TranslatedService,
		private readonly fb: UntypedFormBuilder,
	) {
		super(store);
	}

	ngOnInit(): void {
		this.buildForm();
		this.dialogData$.subscribe((data) => {
			if (hasProp(data, 'accept') && typeof data.accept === 'function')
				this.accept = data.accept as (form: unknown) => Promise<void>;
			if (hasProp(data, 'form') && data.form) this.form.patchValue(data.form);
		});
	}

	async onSubmit(): Promise<void> {
		if (!this.form.valid) return;
		if (typeof this.accept !== 'function')
			throw new Error('Add to wishlist dialog requires an accept handler function!');
		await this.accept(this.form.value);
		this.store.dispatch(popSuccessToast({ title: this.translated.string('addToList.addedToList') }));
		this.closeDialog();
	}

	private buildForm() {
		this.form = this.fb.group({
			name: ['', [Validators.required, Validators.maxLength(100)]],
		});
		this.validationMessages = {
			name: {
				required: this.translated.string('addToList.nameRequired'),
				maxlength: this.translated.string('addToList.nameMaxLength'),
			},
		};
		this.formErrors$ = formValidationErrors$(this.form, this.validationMessages);
	}
}
