import { RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { createSelector } from 'reselect';

/** @deprecated Use DialogService directly. */
export enum Dialog {
	LOGIN,
	PASSWORD_REQUEST,
	NEW_ADDRESS,
	EDIT_PROFILE,
	CHANGE_PASSWORD,
	TERMS,
	CONFIRM,
	UPLOAD,
	IMAGE_VIEWER,
	ADD_TO_WISHLIST,
}

export interface State {
	dialogRef: Dialog;
	dialogData?: unknown;
	dialogOptions?: NgbModalOptions;
}

export const initialState: State = {
	dialogRef: undefined,
};

export const OPEN = 'mygrano/dialog/OPEN';
/** @deprecated Use DialogService directly. */
export const open = (dialogRef: Dialog, dialogData?: any, dialogOptions?: NgbModalOptions) => ({
	type: OPEN,
	payload: { dialogRef, dialogData, dialogOptions },
});

export const CLOSE = 'mygrano/dialog/CLOSE';
/** @deprecated Use DialogService directly. */
export const close = (dialogRef?: Dialog) => ({
	type: CLOSE,
	payload: dialogRef,
});

export function reducer(state: State = initialState, action: any) {
	switch (action.type) {
		case OPEN:
			return { ...state, ...action.payload };
		case CLOSE:
			if (action.payload === state.dialogRef || action.payload === undefined) return initialState;
			return state;

		default:
			return state;
	}
}

/** @deprecated Use DialogService directly. */
export const getActiveDialog = setName('getActiveDialog', (state: RootReducer.State) => state.dialog);

/** @deprecated Use DialogService directly. */
export const getActiveDialogData = setName(
	'getActiveDialogData',
	createSelector(getActiveDialog, (state: State) => state.dialogData),
);
