import {
	LIST_MY_WORKFLOW_ORDERS,
	LIST_MY_WORKFLOW_ORDERS_START,
	REVIEW_START,
	REVIEW,
} from '@app/api/action/ApprovalWorkflow';
import { ApiAction, RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

// TODO: Use remote data.
export interface State {
	orders: api.OrderDto[];
	isLoading: boolean;
	isSubmitSuccess: boolean;
	hasLoadingError: boolean;
	hasSubmitError: boolean;
}

export const initialState: State = {
	orders: [],
	isLoading: false,
	isSubmitSuccess: false,
	hasLoadingError: false,
	hasSubmitError: false,
};

export const RESET_STATUSES = 'mygrano/approval/RESET_STATUSES';
export const resetStatuses = () => ({ type: RESET_STATUSES } as const);

type ApprovalAction =
	| ReturnType<typeof resetStatuses>
	| ApiAction<typeof LIST_MY_WORKFLOW_ORDERS_START, never>
	| ApiAction<typeof LIST_MY_WORKFLOW_ORDERS, api.OrderDto[]>
	| ApiAction<typeof REVIEW_START, never>
	| ApiAction<typeof REVIEW, api.SuccessDto>;

export function reducer(state = initialState, action: ApprovalAction) {
	switch (action.type) {
		case LIST_MY_WORKFLOW_ORDERS_START:
			return { ...state, isLoading: true, hasLoadingError: false };

		case LIST_MY_WORKFLOW_ORDERS:
			if (action.error === true) return { ...state, hasLoadingError: true, isLoading: false };
			return { ...state, hasLoadingError: false, isLoading: false, orders: action.payload };

		case REVIEW_START:
			return { ...state, isLoading: true, isSubmitSuccess: false, hasSubmitError: false };

		case REVIEW:
			if (action.error === true)
				return { ...state, isSubmitSuccess: false, hasSubmitError: true, isLoading: false };
			return { ...state, hasSubmitError: false, isLoading: false, isSubmitSuccess: true };

		case RESET_STATUSES:
			return {
				...state,
				isLoading: false,
				hasSubmitError: false,
				hasLoadingError: false,
				isSubmitSuccess: false,
			};

		default:
			return state;
	}
}

export const getState = setName('getState', (state: RootReducer.State) => state.approvals);

export const getMyApprovalOrders = setName(
	'getMyApprovalOrders',
	createSelector(getState, (state) => state.orders),
);

export const getApprovalOrderById = (orderId: number) =>
	setName(
		'getApprovalOrderById',
		createSelector(getMyApprovalOrders, (orders) => orders?.find((order) => order.id === orderId)),
	);

export const isLoadingOrders = setName(
	'isLoadingOrders',
	createSelector(getState, (state) => state.isLoading),
);

export const isSubmitSuccess = setName(
	'isSubmitSuccess',
	createSelector(getState, (state) => state.isSubmitSuccess),
);

export const hasLoadingError = setName(
	'hasLoadingError',
	createSelector(getState, (state) => state.hasLoadingError),
);

export const hasSubmitError = setName(
	'hasSubmitError',
	createSelector(getState, (state) => state.hasSubmitError),
);
