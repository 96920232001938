import { Component } from '@angular/core';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Login dialog component */
@Component({
	selector: 'g-login-dialog',
	templateUrl: './login-dialog.component.html',
	styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
	static readonly MAX_WIDTH = '700px';
	readonly icons = { faXmark };
	readonly dialogData?: { isAccountConfirmed: boolean };
	constructor(public readonly modal: NgbActiveModal) {}
}
