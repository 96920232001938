import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContentAccessGuardService } from '@app/shared/services/content-access-guard.service';

export const rootRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
	[
		{
			path: 'category',
			canActivate: [ContentAccessGuardService],
			loadChildren: () => import('@app/category/category.module').then((m) => m.CategoryModule),
		},
		{
			path: 'checkout',
			loadChildren: () => import('@app/checkout/checkout.module').then((m) => m.CheckoutModule),
		},
		{
			path: 'product',
			loadChildren: () => import('@app/product/product.module').then((m) => m.ProductModule),
		},
		{
			path: 'products',
			canActivate: [ContentAccessGuardService],
			loadChildren: () => import('@app/category/category.module').then((m) => m.CategoryModule),
		},
		{
			path: 'user',
			loadChildren: () => import('@app/user/user.module').then((m) => m.UserModule),
		},
		{
			path: 'admin',
			canActivate: [ContentAccessGuardService],
			loadChildren: () => import('@app/admin/admin.module').then((m) => m.AdminModule),
		},
		{
			path: '**',
			redirectTo: '/',
		},
	],
	{},
);
