import { DOCUMENT, Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { getVignetteContent, getPages, PageResponse } from '@app/core/reducers/content.reducer';
import { setCookie } from '@app/shared/utils/cookie-helper';
import { I18nService } from '@app/shared/utils/i18n.service';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

/** Vignette header component. */
@UntilDestroy()
@Component({
	selector: 'g-vignette-header',
	templateUrl: './vignette.component.html',
	styleUrls: ['./vignette.component.scss'],
})
export class VignetteComponent implements OnInit {
	currentUrl = '/';
	readonly icons = { faCaretDown };
	readonly languageCodes = this.i18n.enabledLocales;
	/** Content is fetched in content access guard. */
	readonly content$ = this.store.select(getVignetteContent);
	readonly pages$ = this.store.select(getPages);

	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly router: Router,
		private readonly location: Location,
		private readonly i18n: I18nService,
		@Inject(DOCUMENT) private readonly document: Document,
	) {}

	/** Initializes the component. */
	ngOnInit(): void {
		// Get initial path like this because it can't be fetched through ActivatedRoute since this
		// component is outside <router-outlet>
		this.currentUrl = this.location.path();
		// Update current URL when router path changes
		this.router.events
			.pipe(
				untilDestroyed(this),
				filter((event) => event instanceof NavigationEnd),
			)
			.subscribe((event: NavigationEnd) => (this.currentUrl = event.url));
	}

	/** Changes the language of the current page. */
	changeLanguage(languageCode: string): void {
		setCookie('locale', languageCode, 365);
		this.document.location.href = `/${languageCode}${this.currentUrl}`;
	}

	/** Returns the page with the given key. */
	getPage(key: string, pages: PageResponse[]): PageResponse {
		if (typeof key !== 'string' || !key) return;
		return pages?.find((page) => page.key === key);
	}
}
