import {
	reducer as StorefrontReducer,
	State as StorefrontState,
} from './shared/reducers/storefront.reducer';
import { getDebug } from './shared/utils/util';
import {
	reducer as AddressBookReducer,
	State as AddressBookState,
} from './user/reducers/address-book.reducer';
import { reducer as AuthReducer, State as AuthState } from './user/reducers/auth.reducer';
import { Injectable } from '@angular/core';
import {
	reducer as ApprovalsReducer,
	State as ApprovalsState,
} from '@app/admin/reducers/approvals.reducer';
import {
	reducer as CategoryReducer,
	State as CategoryState,
} from '@app/category/reducers/category.reducer';
import {
	reducer as CheckoutReducer,
	State as CheckoutState,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import {
	reducer as PersistedListsReducer,
	State as PersistedListsState,
} from '@app/checkout/modules/checkout-shared/reducers/persisted-list.reducer';
import {
	reducer as ContactRequestReducer,
	State as ContactRequestState,
} from '@app/core/reducers/contact-request.reducer';
import { reducer as ContentReducer, State as ContentState } from '@app/core/reducers/content.reducer';
import {
	reducer as MobileMenuReducer,
	State as MobileMenuState,
} from '@app/core/reducers/mobile-menu.reducer';
import { reducer as DialogReducer, State as DialogState } from '@app/dialog/reducers/dialog.reducer';
import {
	reducer as ProductFlowReducer,
	State as ProductFlowState,
} from '@app/product/reducers/product-flow.reducer';
import { reducer as ProductReducer, State as ProductState } from '@app/product/reducers/product.reducer';
import { reducer as ToastReducer, State as ToastState } from '@app/shared/reducers/toast.reducer';
import {
	reducer as UnsavedWorkReducer,
	State as UnsavedWorkState,
} from '@app/shared/reducers/unsaved-work.reducer';
import { reducer as UploadReducer, State as UploadState } from '@app/shared/reducers/upload.reducer';
import { setName } from '@app/shared/utils/name-helper';
import { reducer as OrderReducer, State as OrderState } from '@app/user/reducers/order.reducer';
import {
	reducer as RegistrationReducer,
	State as RegistrationState,
} from '@app/user/reducers/registration.reducer';
import { reducer as UserReducer, State as UserState } from '@app/user/reducers/user.reducer';
// eslint-disable-next-line no-restricted-imports
import { ActionReducerMap, Action as OriginalAction, Store as OriginalStore } from '@ngrx/store';
import { createSelector } from 'reselect';

export interface ActionObj extends OriginalAction {
	payload?: any;
	error?: boolean;
	meta?: {
		res?: api.FetchResponse;
		params?: any;
		info?: Record<string, any>;
	};
}
export type ActionFn = (dispatch: Dispatch) => void | Promise<void>;

export type Action = ActionObj | ActionFn;

export { type ApiAction } from './shared/utils/api-utils';

// Extend dispatch to allow async dispatchers (callbacks).
export type Dispatch = (action: Action) => void;

/** Extend Store to allow async dispatchers (callbacks). */
@Injectable({ providedIn: 'root' })
export class Store<T> extends OriginalStore<T> {
	/** Extend dispatch to allow async dispatchers (callbacks). */
	public dispatch(action: Action): void {
		if (typeof action === 'function') void action(this.dispatch.bind(this));
		else super.dispatch(action);
	}
}

export interface AppStatus {
	isContentShown: boolean;
	showGlobalSpinner: boolean;
}

export interface Pagination {
	page?: number;
	pageCount?: number;
	pageSize?: number;
	rowCount?: number;
}

export const initialStatus = {
	isContentShown: false,
	showGlobalSpinner: false,
};

export const SHOW_CONTENT = 'mygrano/app/SHOW_CONTENT';
export const showContent = () => ({ type: SHOW_CONTENT });
export const HIDE_CONTENT = 'mygrano/app/HIDE_CONTENT';
export const hideContent = () => ({ type: HIDE_CONTENT });
export const SHOW_GLOBAL_SPINNER = 'mygrano/app/SHOW_GLOBAL_SPINNER';
export const showGlobalSpinner = () => ({ type: SHOW_GLOBAL_SPINNER });
export const HIDE_GLOBAL_SPINNER = 'mygrano/app/HIDE_GLOBAL_SPINNER';
export const hideGlobalSpinner = () => ({ type: HIDE_GLOBAL_SPINNER });
export const REDIRECT_TO_LOGIN_PAGE = 'mygrano/app/REDIRECT_TO_LOGIN_PAGE';

const debug = getDebug('reducer');

/** AppStatus reducer. */
export function AppStatusReducer(state: AppStatus = initialStatus, action: ActionObj) {
	const reducerName = action.type?.split('/')[1] ?? 'unknown';
	debug.extend(reducerName)('action', action.type, action.payload);
	switch (action.type) {
		case SHOW_CONTENT:
			return { ...state, isContentShown: true };
		case HIDE_CONTENT:
			return { ...state, isContentShown: false };
		case SHOW_GLOBAL_SPINNER:
			return { ...state, showGlobalSpinner: true };
		case HIDE_GLOBAL_SPINNER:
			return { ...state, showGlobalSpinner: false };
		default:
			return state;
	}
}

export const getAppStatus = (state: RootReducer.State) => state.appStatus;
export const getShowContent = setName(
	'getShowContent',
	createSelector(getAppStatus, (state: AppStatus) => state.isContentShown),
);
export const getShowGlobalSpinner = setName(
	'getShowGlobalSpinner',
	createSelector(getAppStatus, (state: AppStatus) => state.showGlobalSpinner),
);

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RootReducer {
	export interface State {
		appStatus: AppStatus;
		auth: AuthState;
		storefront: StorefrontState;
		registration: RegistrationState;
		category: CategoryState;
		product: ProductState;
		productFlow: ProductFlowState;
		checkout: CheckoutState;
		addressBook: AddressBookState;
		toast: ToastState;
		order: OrderState;
		upload: UploadState;
		contactRequest: ContactRequestState;
		mobileMenu: MobileMenuState;
		dialog: DialogState;
		content: ContentState;
		user: UserState;
		unsavedWork: UnsavedWorkState;
		persistedLists: PersistedListsState;
		approvals: ApprovalsState;
	}

	export const reducers: ActionReducerMap<State> = {
		appStatus: AppStatusReducer,
		auth: AuthReducer,
		storefront: StorefrontReducer,
		registration: RegistrationReducer,
		category: CategoryReducer,
		product: ProductReducer,
		productFlow: ProductFlowReducer,
		checkout: CheckoutReducer,
		addressBook: AddressBookReducer,
		toast: ToastReducer,
		order: OrderReducer,
		upload: UploadReducer,
		contactRequest: ContactRequestReducer,
		mobileMenu: MobileMenuReducer,
		dialog: DialogReducer,
		content: ContentReducer,
		user: UserReducer,
		unsavedWork: UnsavedWorkReducer,
		persistedLists: PersistedListsReducer,
		approvals: ApprovalsReducer,
	};
}
