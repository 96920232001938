import { Pipe, PipeTransform } from '@angular/core';
import { Link } from '@app/core/reducers/content.reducer';

/** Check if the link is a router link. */
@Pipe({ name: 'isRouterLink', standalone: true })
export class IsRouterLinkPipe implements PipeTransform {
	/** Check if the link is a router link. */
	transform(link: Link): boolean {
		return (link?.link ?? '').startsWith('/');
	}
}

/** Check if the link is an external link. */
@Pipe({ name: 'isExternalLink', standalone: true })
export class IsExternalLinkPipe implements PipeTransform {
	/** Check if the link is an external link. */
	transform(link: Link): boolean {
		return /^(https?|tel):/.test(link?.link ?? '');
	}
}
