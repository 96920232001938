import { receivePunchoutLoginError } from '../reducers/storefront.reducer';
import { hasProp } from '../utils/util';
import { NewRelic } from './newrelic.service';
import { Injectable } from '@angular/core';
import { Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { CognitoService } from '@app/shared/services/cognito.service';
import { getUser, isLoggedUser } from '@app/user/reducers/user.reducer';
import { identity, MonoTypeOperatorFunction } from 'rxjs';
import { first, skip } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PunchOutGuardService {
	constructor(
		private readonly cognito: CognitoService,
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
		private readonly newRelic: NewRelic,
	) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<UrlTree> {
		const token = route.queryParamMap.get('t');
		const selectUser = (operator: MonoTypeOperatorFunction<api.UserDto> = identity) =>
			this.store.select(getUser).pipe(operator, first()).toPromise();
		try {
			// Log out any active session.
			if (isLoggedUser(await selectUser())) await this.cognito.logout();
			// Attempt cXML authentication.
			await this.cognito.loginWithCxmlToken(token);
			// Listen for updated user to see if login succeeded.
			const user = await selectUser(skip(1));
			if (!isLoggedUser(user)) throw new Error('Authentication failed');
			// Login success, redirect to product list.
			return this.router.createUrlTree(['/products']);
		} catch (error: unknown) {
			this.newRelic.noticeError(error);
			this.store.dispatch(
				receivePunchoutLoginError(hasProp(error, 'code') ? (error.code as string) : 'ERROR'),
			);
			return this.router.createUrlTree(['/']);
		}
	}
}
