import { Component, AfterViewInit } from '@angular/core';
import { TranslatedService } from '@app/shared/services/translated.service';

@Component({
	selector: 'g-translated-strings',
	templateUrl: './translated-strings.component.html',
	styleUrls: ['./translated-strings.component.scss'],
})
export class TranslatedStringsComponent implements AfterViewInit {
	constructor(private readonly translated: TranslatedService) {}

	ngAfterViewInit() {
		this.translated.initialize();
	}
}
