<div fxLayout="row">
	<div class="header" fxFlex="100" fxLayout="row">
		<h3 class="header-dialog" fxFlex="80" i18n>Change password</h3>
		<g-icon
			[icon]="icons.faXmark"
			class="close-dialog"
			fxFlex="20"
			(click)="closeDialog()"
			role="button"
			i18n-title
			title="Close"
		>
		</g-icon>
	</div>
	<div *ngIf="!showTerms" class="container" fxFlex="60" fxFlex.lt-sm="95">
		<form id="password-change" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
			<div class="form-group-primary">
				<label for="oldPassword" i18n>Old password</label>
				<input
					type="password"
					id="oldPassword"
					class="form-control form-control-primary"
					formControlName="oldPassword"
					gAutofocus
					autocomplete="current-password"
				/>
				<div class="validation-error">
					{{ formErrors$ | async | validationField: 'oldPassword' }}
				</div>

				<label for="change_password" i18n>Password</label>
				<input
					type="password"
					id="change_password"
					class="form-control form-control-primary"
					formControlName="password"
					autocomplete="new-password"
				/>
				<g-password-strength
					[password]="changePasswordForm.get('password').value"
					[validatorControl]="changePasswordForm.get('passwordScore')"
				>
				</g-password-strength>
				<div class="validation-error">
					<p>{{ formErrors$ | async | validationField: 'password' }}</p>
					<p>{{ formErrors$ | async | validationField: 'passwordScore' }}</p>
				</div>

				<label for="change_confirm_password" i18n>Confirm password</label>
				<input
					type="password"
					id="change_confirm_password"
					class="form-control form-control-primary"
					formControlName="confirm_password"
					autocomplete="new-password"
				/>
				<div class="validation-error">
					{{ formErrors$ | async | validationField: 'confirm_password' }}
				</div>

				<div class="form-check" *ngIf="showTermsCheckbox">
					<label class="form-check-label">
						<input class="form-check-input" type="checkbox" formControlName="acceptTerms" />
						<span i18n>I accept the</span>
					</label>
					<a (click)="toggleTerms()" class="show-terms" i18n>terms &amp; conditions</a>
					<div class="validation-error">
						{{ formErrors$ | async | validationField: 'acceptTerms' }}
					</div>
				</div>

				<button type="submit" [disabled]="!changePasswordForm.valid" class="btn btn-primary" i18n
					>Change password</button
				>
			</div>
		</form>
	</div>
	<div *ngIf="showTerms" class="modal-body container-full-width">
		<button type="button" (click)="toggleTerms()" class="btn btn-secondary" i18n>Hide terms</button>
		<g-content-page [key]="'terms'" fullbleed></g-content-page>
		<button type="button" (click)="toggleTerms()" class="btn btn-secondary" i18n>Hide terms</button>
	</div>
</div>
