<g-content-page [key]="'terms'" fullbleed></g-content-page>
<g-icon
	[icon]="icons.faXmark"
	class="close-dialog"
	(click)="closeDialog()"
	role="button"
	i18n-title
	title="Close"
>
</g-icon>
