import { Component, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RootReducer, Store } from '@app/app.reducers';
import { getPages, getContentByKey } from '@app/core/reducers/content.reducer';
import { getHomePage } from '@app/shared/reducers/storefront.reducer';
import { SeoService } from '@app/shared/services/seo.service';
import { pluckSuccessData } from '@app/shared/utils/remote-data';
import { firstTruthy, isPlainObject } from '@app/shared/utils/util';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

const DEFAULT_PAGE_MAPPING = new Map([
	['default-products', ['products']],
	['default-profile', ['user', 'profile']],
	['default-index', ['/']],
]);

/** Main component */
@Component({
	selector: 'g-main',
	template: '',
	styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
	constructor(
		private readonly seo: SeoService,
		private readonly router: Router,
		private readonly store: Store<RootReducer.State>,
	) {}

	/** Initialize component */
	ngOnInit(): void {
		combineLatest([
			this.store.select(getPages).pipe(pluckSuccessData, firstTruthy),
			this.store.select(getHomePage).pipe(firstTruthy),
		])
			.pipe(
				map(([contentPages, homepageKey = 'default-index']) => {
					// Use built-in page if it exists, check for custom page otherwise.
					const builtInHome = DEFAULT_PAGE_MAPPING.get(homepageKey);
					if (builtInHome) return builtInHome;
					const customHome = contentPages?.find((p) => p.key === homepageKey);
					// If custom home page is not found, use default index.
					return customHome ? ['page', customHome.key] : DEFAULT_PAGE_MAPPING.get('default-index')!;
				}),
			)
			.subscribe((redirect) => void this.router.navigate(redirect));
		// Fetched in content-access-guard service.
		this.store
			.select(getContentByKey('homepage-seo'))
			.pipe(pluckSuccessData, firstTruthy)
			.subscribe(
				(homepageSeo) =>
					isPlainObject(homepageSeo) &&
					this.seo.setDirectly({
						title: homepageSeo?.seo_title as string,
						description: homepageSeo?.seo_description as string,
					}),
			);
	}
}
