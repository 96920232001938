// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Upload */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get presigned S3 url for upload
 * 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function getUploadUrl(options?: GetUploadUrlOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(getUploadUrlOperation, parameters);
}

/**
 * Adds a FileStatus record to the database that has the file key and infection status.
 * 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function updateFileStatus(options?: UpdateFileStatusOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(updateFileStatusOperation, parameters);
}

/**
 * Adds SQS message's receipt handle to a file's FileStatus record
 * 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function updateFileStatusReceiptHandle(options?: UpdateFileStatusReceiptHandleOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(updateFileStatusReceiptHandleOperation, parameters);
}

/**
 * Get Printfile's antivirus check status by Printfile key.
 * 
 * @param {string} key 
 * @return {Promise<module:types.FileStatus>} OK
 */
export function fileStatusByKey(key: string): Promise<api.Response<api.FileStatus>> {
  const parameters: api.OperationParamGroups = {
    path: {
      key
    }
  };
  return gateway.request(fileStatusByKeyOperation, parameters);
}

/**
 * Set Printfile preflight status by Printfile id
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function report(id: number, options?: ReportOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(reportOperation, parameters);
}

/**
 * Get Printfile preflight status by Printfile id
 * 
 * @param {number} id 
 * @return {Promise<module:types.Printfile>} OK
 */
export function status(id: number): Promise<api.Response<api.Printfile>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(statusOperation, parameters);
}

/**
 * Start Pdf preflight for a file with the corresponding key. Product options are needed for size calculation.
 * 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function startPreflight(options?: StartPreflightOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(startPreflightOperation, parameters);
}

/**
 * This route allows you to upload a "Designs" and related "Links" to Customer's Canvas.
 * **Note: Swagger UI does not support uploading multiple files, but the API does.**
 * 
 * @param {string} name Name of the template (folder name)
 * @param {file} templates The template files for the different print surfaces (back and front for example)
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {file} [options.links] Resource files that are linked to the template
 * @return {Promise<object>} Upload successful
 */
export function uploadEditorTemplate(name: string, templates: File, options?: UploadEditorTemplateOptions): Promise<api.Response<{
    templates?: string[],
    links?: string[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    formData: {
      name,
      templates,
      links: options.links
    }
  };
  return gateway.request(uploadEditorTemplateOperation, parameters);
}

/**
 * E2E tests are redirected here instead of the S3 upload
 * 
 * @param {object} options Optional options
 * @param {object} [options.key] 
 * @return {Promise<object>} OK
 */
export function fakeUpload(options?: FakeUploadOptions): Promise<api.Response<undefined>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      key: options.key
    }
  };
  return gateway.request(fakeUploadOperation, parameters);
}

/**
 * Get Printfile's latest PDF report etc. by Printfile key.
 * 
 * @param {string} key Printfile key
 * @return {Promise<module:types.PrintfileDto>} The printfile metadata
 */
export function getFileMetadata(key: string): Promise<api.Response<api.PrintfileDto>> {
  const parameters: api.OperationParamGroups = {
    path: {
      key
    }
  };
  return gateway.request(getFileMetadataOperation, parameters);
}

export interface GetUploadUrlOptions {
  body?: {
    key: string
  };
}

export interface UpdateFileStatusOptions {
  body?: {
    key: string,
    is_infected: boolean
  };
}

export interface UpdateFileStatusReceiptHandleOptions {
  body?: {
    key: string,
    sqs_receipt_handle: string
  };
}

export interface ReportOptions {
  body?: {
    report?: {
      is_printable?: boolean,
      warnings?: {
        message?: {
          en?: string,
          fi?: string
        },
        occurences?: {
          amount?: number,
          pages?: string
        },
        error_code?: string
      }[],
      errors?: {
        message?: {
          en?: string,
          fi?: string
        },
        occurences?: {
          amount?: number,
          pages?: string
        },
        error_code?: string
      }[]
    },
    status: string
  };
}

export interface StartPreflightOptions {
  body?: {
    key: string,
    filename?: string,
    product_id?: number,
    profile?: string,
    options?: any,
    item_id: string,
    store_id?: number,
    editor_state_id?: string
  };
}

export interface UploadEditorTemplateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  /**
   * Resource files that are linked to the template
   */
  links?: File;
}

export interface FakeUploadOptions {
  key?: {
    key: string
  };
}

const getUploadUrlOperation: api.OperationInfo = {
  path: '/upload/get-upload-url',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateFileStatusOperation: api.OperationInfo = {
  path: '/upload/update-file-status',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateFileStatusReceiptHandleOperation: api.OperationInfo = {
  path: '/upload/update-file-status-receipt-handle',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const fileStatusByKeyOperation: api.OperationInfo = {
  path: '/upload/file-status-by-key/{key}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const reportOperation: api.OperationInfo = {
  path: '/upload/{id}/report',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const statusOperation: api.OperationInfo = {
  path: '/upload/{id}/status',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const startPreflightOperation: api.OperationInfo = {
  path: '/upload/start-preflight',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const uploadEditorTemplateOperation: api.OperationInfo = {
  path: '/upload/upload-editor-template',
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const fakeUploadOperation: api.OperationInfo = {
  path: '/upload/fake-upload',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getFileMetadataOperation: api.OperationInfo = {
  path: '/v2/upload/file-metadata/{key}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
