import { BrowserSupportService } from './browser-support.service';
import { Injectable } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { getStorefrontErrorCode, getPunchOutErrorCode } from '@app/shared/reducers/storefront.reducer';
import { getIsMaintenanceMode } from '@app/user/reducers/auth.reducer';
import { combineLatest, Observable, of, race } from 'rxjs';
import { first, map } from 'rxjs/operators';

export enum FaultCode {
	NotFound = 1,
	UnsupportedBrowser,
	PunchOutError,
	Maintenance,
}

@Injectable({
	providedIn: 'root',
})
export class FaultCodeService {
	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly browserSupportService: BrowserSupportService,
	) {}

	getFaultCode$(): Observable<FaultCode> {
		if (!this.browserSupportService.isUserBrowserSupported()) {
			return of(FaultCode.UnsupportedBrowser);
		}

		const maintenance$ = combineLatest([
			this.store.select(getIsMaintenanceMode).pipe(first(Boolean)),
			this.store.select(getStorefrontErrorCode).pipe(map(Number)),
		]).pipe<FaultCode>(
			map(([, storeErrorCode]) => {
				return storeErrorCode === 404 ? FaultCode.NotFound : FaultCode.Maintenance;
			}),
		);

		const punchOut$ = this.store.select(getPunchOutErrorCode).pipe(
			first(Boolean),
			map(() => FaultCode.PunchOutError),
		);

		return race(maintenance$, punchOut$);
	}
}
