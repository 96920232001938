// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Order */
// Auto-generated, edits will be overwritten
import * as Order from '../Order';

export const UPDATE_ORDER_SYNC_START = 's/Order/UPDATE_ORDER_SYNC_START';
export const UPDATE_ORDER_SYNC = 's/Order/UPDATE_ORDER_SYNC';
export type UPDATE_ORDER_SYNC = any;

export function updateOrderSync(id: number, options?: Order.UpdateOrderSyncOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_ORDER_SYNC_START, meta: { info, params: { id, options } } });
    return Order.updateOrderSync(id, options)
      .then(response => dispatch({
        type: UPDATE_ORDER_SYNC,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_ORDER_STATUS_START = 's/Order/UPDATE_ORDER_STATUS_START';
export const UPDATE_ORDER_STATUS = 's/Order/UPDATE_ORDER_STATUS';
export type UPDATE_ORDER_STATUS = api.OrderDto;

export function updateOrderStatus(id: number, options?: Order.UpdateOrderStatusOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_ORDER_STATUS_START, meta: { info, params: { id, options } } });
    return Order.updateOrderStatus(id, options)
      .then(response => dispatch({
        type: UPDATE_ORDER_STATUS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/Order/ALL_START';
export const ALL = 's/Order/ALL';
export type ALL = api.OrderDto[];

export function all(options?: Order.AllOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: { options } } });
    return Order.all(options)
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/Order/CREATE_START';
export const CREATE = 's/Order/CREATE';
export type CREATE = api.OrderDto;

export function create(options?: Order.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return Order.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_ORDER_START = 's/Order/GET_ORDER_START';
export const GET_ORDER = 's/Order/GET_ORDER';
export type GET_ORDER = api.OrderDto;

export function getOrder(accessToken: string, options?: Order.GetOrderOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_ORDER_START, meta: { info, params: { accessToken, options } } });
    return Order.getOrder(accessToken, options)
      .then(response => dispatch({
        type: GET_ORDER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_BY_STORE_START = 's/Order/GET_BY_STORE_START';
export const GET_BY_STORE = 's/Order/GET_BY_STORE';
export type GET_BY_STORE = api.OrderDto[];

export function getByStore(options?: Order.GetByStoreOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_BY_STORE_START, meta: { info, params: { options } } });
    return Order.getByStore(options)
      .then(response => dispatch({
        type: GET_BY_STORE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SEARCH_ALL_START = 's/Order/SEARCH_ALL_START';
export const SEARCH_ALL = 's/Order/SEARCH_ALL';
export type SEARCH_ALL = api.OrderDto[];

export function searchAll(searchTerm: string, options?: Order.SearchAllOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SEARCH_ALL_START, meta: { info, params: { searchTerm, options } } });
    return Order.searchAll(searchTerm, options)
      .then(response => dispatch({
        type: SEARCH_ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SEARCH_BY_STORE_START = 's/Order/SEARCH_BY_STORE_START';
export const SEARCH_BY_STORE = 's/Order/SEARCH_BY_STORE';
export type SEARCH_BY_STORE = api.OrderDto[];

export function searchByStore(searchTerm: string, options?: Order.SearchByStoreOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SEARCH_BY_STORE_START, meta: { info, params: { searchTerm, options } } });
    return Order.searchByStore(searchTerm, options)
      .then(response => dispatch({
        type: SEARCH_BY_STORE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_MY_ORDERS_START = 's/Order/GET_MY_ORDERS_START';
export const GET_MY_ORDERS = 's/Order/GET_MY_ORDERS';
export type GET_MY_ORDERS = api.OrderDto[];

export function getMyOrders(options?: Order.GetMyOrdersOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_MY_ORDERS_START, meta: { info, params: { options } } });
    return Order.getMyOrders(options)
      .then(response => dispatch({
        type: GET_MY_ORDERS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_ADDRESS_START = 's/Order/UPDATE_ADDRESS_START';
export const UPDATE_ADDRESS = 's/Order/UPDATE_ADDRESS';
export type UPDATE_ADDRESS = api.OrderDto;

export function updateAddress(orderId: number, options?: Order.UpdateAddressOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_ADDRESS_START, meta: { info, params: { orderId, options } } });
    return Order.updateAddress(orderId, options)
      .then(response => dispatch({
        type: UPDATE_ADDRESS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_PRODUCT_QUOTE_START = 's/Order/CREATE_PRODUCT_QUOTE_START';
export const CREATE_PRODUCT_QUOTE = 's/Order/CREATE_PRODUCT_QUOTE';
export type CREATE_PRODUCT_QUOTE = any;

export function createProductQuote(options?: Order.CreateProductQuoteOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_PRODUCT_QUOTE_START, meta: { info, params: { options } } });
    return Order.createProductQuote(options)
      .then(response => dispatch({
        type: CREATE_PRODUCT_QUOTE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_FORM_ORDER_START = 's/Order/CREATE_FORM_ORDER_START';
export const CREATE_FORM_ORDER = 's/Order/CREATE_FORM_ORDER';
export type CREATE_FORM_ORDER = any;

export function createFormOrder(options?: Order.CreateFormOrderOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_FORM_ORDER_START, meta: { info, params: { options } } });
    return Order.createFormOrder(options)
      .then(response => dispatch({
        type: CREATE_FORM_ORDER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ONE_START = 's/Order/ONE_START';
export const ONE = 's/Order/ONE';
export type ONE = api.OrderDto;

export function one(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ONE_START, meta: { info, params: { id } } });
    return Order.one(id)
      .then(response => dispatch({
        type: ONE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
