import {
	ProductMediaItem,
	ProductOrderIntegration,
	ProductType,
	SelectedOptions,
} from '../reducers/product.reducer';
import { ProductApprovalStatus } from '@app/admin/components/approval/approval-types';
import { getProductsByIdList } from '@app/api/action/Product';
import { RootReducer, Store } from '@app/app.reducers';
import {
	CartItem,
	CartOrOrderProduct,
	isCartItem,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import {
	getNonFetchedProductIds,
	getProductsById,
	isLoading as isLoadingProducts,
} from '@app/product/reducers/product.reducer';
import { filterNil, hasProp } from '@app/shared/utils/util';
import { environment } from '@src/environments/environment';
import { combineLatest, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

export type ProductWithId = api.ProductDto & { id: number };

// istanbul ignore next - edge cases
const splitCaps = (str: string) =>
	str
		.replaceAll(/([a-z])([A-Z]+)/g, (_, s1: string, s2: string) => s1 + ' ' + s2)
		.replace(
			/([A-Z])([A-Z]+)([^\dA-Za-z]*)$/,
			(m, s1: string, s2: string, s3: string) => s1 + s2.toLowerCase() + s3,
		)
		.replaceAll(/([A-Z]+)([A-Z][a-z])/g, (_, s1: string, s2: string) => s1.toLowerCase() + ' ' + s2);

export const snakeCase = (str: string): string =>
	splitCaps(str)
		.replaceAll(/\W+/g, ' ')
		.split(/ |\B(?=[A-Z])/)
		.map((word) => word.toLowerCase())
		.join('_');

export const camelCase = (str: string): string => {
	str = str.replaceAll(/[\s._-]+(.)?/g, (_, c: string) => c?.toUpperCase?.() || '');
	return str[0].toLowerCase() + str.slice(1);
};

/** Get product's quantity from options */
export function getProductQuantity<TDefault>(
	{
		options = {},
		...meta
	}:
		| { sku?: string; options: SelectedOptions; product?: { sku?: string } }
		| Pick<api.CartItemDto | api.CartItemWithProductDto | api.OrderProductDto, 'options' | 'product'>,
	defaultValue: TDefault = undefined as unknown as TDefault,
): number | TDefault {
	// Figure out the SKU.
	const sku = (
		hasProp(meta, 'sku') ? meta.sku : hasProp(meta.product, 'sku') ? meta.product.sku : 'unknown'
	) as string;
	if (typeof options !== 'object' || options === null) return defaultValue;
	if (
		!('selected_options' in options) &&
		!('selectedOptions' in options) &&
		('quantity' in options || 'order_quantity' in options)
	) {
		options = { selected_options: options };
	}
	const itemOptions =
		'selected_options' in options
			? options.selected_options
			: 'selectedOptions' in options
				? options.selectedOptions
				: undefined;

	if (!itemOptions) return defaultValue;
	// istanbul ignore if - product configuration error
	if (itemOptions.quantity && itemOptions.order_quantity)
		throw new Error(
			`Quantity conflict for SKU "${sku}": ${itemOptions.quantity} vs ${itemOptions.order_quantity}`,
		);

	return (
		itemOptions.quantity ??
		(itemOptions.order_quantity ? Number.parseInt(itemOptions.order_quantity) : defaultValue)
	);
}

/**
 * Check if product's type matches
 * @param product Product whose type you want to check
 * @param productType Product type or list  of product types that should match product
 */
export function productTypeMatches(
	product: CartOrOrderProduct,
	productType: ProductType | ProductType[],
): boolean {
	const productTypeValue = product?.type;
	return Array.isArray(productType)
		? productType.includes(productTypeValue as ProductType)
		: productTypeValue === productType;
}

/**
 * Check if product is a "variable" product e.g. has variable options, printfile or text fields.
 * @param product Product to check
 */
export function isVariableProduct(product: CartOrOrderProduct): boolean {
	// Printfile can vary or product options can vary.
	return hasUserProvidedPrintfile(product) || hasSelectableOptions(product);
}

/**
 * Check if product has user-provided printfile
 * @param product Product to check
 */
export function hasUserProvidedPrintfile(product: CartOrOrderProduct): boolean {
	return productTypeMatches(product, [
		ProductType.Variable,
		ProductType.Editable,
		ProductType.MultipleOption,
	]);
}

/**
 * Check if product is a form product
 * @param product Product to check
 */
export function isFormProduct(product: CartOrOrderProduct): boolean {
	return productTypeMatches(product, [ProductType.Form]);
}

export function selectedOptionsAreValid(
	product: api.ProductDto,
	selectedOptions: SelectedOptions,
): boolean {
	// Get the number of required and provided variables
	const optionsCount = Object.keys(product?.properties?.selectableOptions ?? {}).length;
	const textFieldsCount = Object.keys(product?.properties?.textFields ?? {}).length;
	// Ignore empty values.
	const inputCount = Object.values(selectedOptions || {}).filter(
		(val) => (typeof val === 'string' && val.length > 0) || Number.isFinite(val),
	).length;
	// If there's a discrepancy between required and provided variables, something is wrong.
	// Trigger "designer product options error" so user can be redirected back to product page.
	if (inputCount !== optionsCount + textFieldsCount) return false;
	return true;
}

export function hasSelectableOptions(product: CartOrOrderProduct): boolean {
	if (!product) return false;
	const properties = product.properties;
	if (properties?.selectableOptions) {
		const hasSelectables = Object.keys(properties.selectableOptions).some((selectable) => {
			const variable = properties.selectableOptions[selectable];
			if (!Array.isArray(variable?.selectableValues) || variable.selectableValues.length === 0)
				return false;
			if (selectable !== 'order_quantity' && variable.selectableValues.length <= 1) return false;
			// "order_quantity" dropdown is always visible
			return true;
		});
		if (hasSelectables) return true;
	}
	if (properties?.textFields) {
		const hasTextFields = Object.keys(properties.textFields).some((textField) => {
			const variable = properties.textFields[textField];
			if (!variable?.show) return false;
			return true;
		});
		if (hasTextFields) return true;
	}
	return false;
}

/**
 * Convert value in millimeters to PostScript point size
 * @param mmValue Value in millimeters
 */
export function convertMillimetersToPostScriptPoints(mmValue: number): number {
	const conversionFactor = 2.834_645_669_3;
	return Math.round(mmValue * conversionFactor);
}

/** Get product's images either from proof images or product images */
export function collectProductImages(
	item: CartItem | api.OrderProductDto,
): ProductMediaItem[] | string[] {
	const proofImages = isCartItem(item)
		? item?.productFlowData?.scaledProofImages
		: item?.product_flow_data?.scaled_proof_images;
	if (proofImages && (proofImages.length ?? 0) > 0) return proofImages;
	return [item?.product?.images?.master, ...(item?.product?.images?.additional ?? [])].filter(filterNil);
}

/**
 * Check if all given integrations are set in product
 * @param product Product whose integrations you want to check
 * @param integrationType Integration or list of integrations to check in product
 */
export function hasOrderIntegration(
	product: api.ProductDto,
	integrationType: ProductOrderIntegration | ProductOrderIntegration[],
): boolean {
	let integrations = product?.metadata?.order_integration ?? [];
	if (!Array.isArray(integrations)) {
		integrations = [integrations];
	}
	return Array.isArray(integrationType)
		? integrationType.every((type) => integrations.includes(type))
		: integrations.includes(integrationType);
}

/**
 * Shorthand for checking if product has download integration
 * @param product Product to check
 */
export function isDownloadableProduct(product: api.ProductDto): boolean {
	return hasOrderIntegration(product, ProductOrderIntegration.Download);
}

/** Get product's printfile URL */
export function generateFileBucketUrl(key: string): string {
	return `https://${environment.fileBucketUrl}/${key}`;
}

/**
 * Get product asset URL, this can be a user printfile or preset file
 * @param orderProduct Product object stored in order
 */
export function getProductAssetUrl(
	orderProduct: Pick<api.OrderProductDto, 'printfile' | 'product'>,
): string | undefined {
	const printfile = (orderProduct?.printfile ||
		orderProduct.product?.properties?.printfile) as api.OrderProductPrintfileDto;
	if (printfile?.url) return printfile.url;
	if (printfile?.key) return generateFileBucketUrl(printfile.key);
}

/**
 * Get approval workflow assigned to product
 * @param product Product object
 */
export function getProductApprovalWorkflow(product: api.ProductDto): string | undefined {
	return product?.metadata?.approval_workflow;
}

/**
 * Get approval status of order product
 * @param item Ordered product
 */
export function getProductApprovalStatus(item: api.OrderProductDto): ProductApprovalStatus | undefined {
	return item?.approval_status as ProductApprovalStatus;
}

/**
 * Check if product has approval workflow
 * @param product Product to check
 */
export function hasApprovalWorkflow(product: api.ProductDto): boolean {
	return (getProductApprovalWorkflow(product)?.length ?? 0) > 0;
}

/**
 * Convert object keys from camelCase to snake_case
 * @param source Source object. Will not me changed.
 * @deprecated Map objects manually if necessary.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function deepSnakeCase(source: any): any {
	if (typeof source !== 'object') return {};
	const converted = {};
	Object.entries(source).forEach(
		([key, value]) =>
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
			(converted[snakeCase(key)] =
				typeof value === 'object' && !Array.isArray(value)
					? deepSnakeCase(value)
					: structuredClone(value)),
	);
	return converted;
}

/**
 * Convert object keys from snake_case to camelCase
 * @param source Source object. Will not me changed.
 * @deprecated Map objects manually if necessary.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function deepCamelCase(source: any): any {
	if (typeof source !== 'object') return {};
	const converted = {};
	Object.entries(source).forEach(
		([key, value]) =>
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
			(converted[camelCase(key)] =
				typeof value === 'object' && !Array.isArray(value)
					? deepCamelCase(value)
					: structuredClone(value)),
	);
	return converted;
}

/**
 * Fetch product data when some of the provided product IDs haven't been fetched yet
 * @param store Reducer store
 * @param productIds Array of product IDs
 * @param waitUntilLoaded When true, return only once API request has finished
 */
export async function fetchMissingProductsById(
	store: Store<RootReducer.State>,
	productIds: number[],
	waitUntilLoaded = true,
): Promise<void> {
	const missingProductIds = await firstValueFrom(store.select(getNonFetchedProductIds(productIds)));
	if (missingProductIds.length === 0) return;
	store.dispatch(getProductsByIdList([...missingProductIds].join(',')));
	if (!waitUntilLoaded) return;
	await firstValueFrom(
		combineLatest([
			store.select(getProductsById(new Set(productIds))),
			store.select(isLoadingProducts),
		]).pipe(first(([products, isLoading]) => Array.isArray(products) && !isLoading)),
	);
}
