<section class="add-to-wishlist-dialog">
	<section class="modal-header">
		<h3 class="header-dialog"><span i18n="@@AddToListDialogSaveProductTitle">Save product</span></h3>
		<g-icon
			[icon]="icons.faXmark"
			class="close-dialog"
			(click)="closeDialog()"
			role="button"
			i18n-title
			title="Close"
		>
		</g-icon>
	</section>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<section class="modal-body">
			<div class="form-group">
				<label class="d-block">
					<span i18n="@@AddToListDialogFormName">Name your product</span><span class="required">*</span>
				</label>
				<input
					type="text"
					name="name"
					formControlName="name"
					trim="blur"
					class="form-control d-block"
					gAutofocus
				/>
				<div class="validation-error">{{ formErrors$ | async | validationField: 'name' }}</div>
			</div>
			<p i18n="@@AddToListInfo"
				>You will find all the products you have saved under My Products in the user menu.</p
			>
		</section>
		<section class="modal-footer">
			<div class="actions" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
				<button type="button" class="btn" (click)="closeDialog()" i18n="@@AddToListDialogCancel"
					>Cancel</button
				>
				<button
					type="submit"
					class="btn btn-primary"
					[disabled]="!form.valid"
					i18n="@@AddToListDialogSave"
					data-cy="addToListDialogSave"
					>Save</button
				>
			</div>
		</section>
	</form>
</section>
