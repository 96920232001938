import { RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { createSelector } from 'reselect';

export interface State {
	isOpen: boolean;
}

export const initialState: State = {
	isOpen: undefined,
};

export const OPEN_MOBILE_MENU = 'mygrano/mobileMenu/OPEN_MOBILE_MENU';
export const openMobileMenu = () => ({ type: OPEN_MOBILE_MENU });

export const CLOSE_MOBILE_MENU = 'mygrano/mobileMenu/CLOSE_MOBILE_MENU';
export const closeMobileMenu = () => ({ type: CLOSE_MOBILE_MENU });

export function reducer(state: State = initialState, action: any) {
	switch (action.type) {
		case OPEN_MOBILE_MENU:
			return { ...state, isOpen: true };
		case CLOSE_MOBILE_MENU:
			return { ...state, isOpen: false };
		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.mobileMenu;
export const getMobileMenuStatus = setName(
	'getMobileMenuStatus',
	createSelector(getState, (state: State) => state.isOpen),
);
