import { I18nService } from '../utils/i18n.service';
import { isPlainObject, truncate } from '../utils/util';
import { StoreDataService } from './store-data.service';
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageContent } from '@app/core/reducers/content.reducer';
import { environment } from '@src/environments/environment';
import { filter } from 'rxjs/operators';

export const TITLE_LENGTH_LIMIT = 60;
export const DESCRIPTION_LENGTH_LIMIT = 300;

interface SeoProperties {
	title?: api.LocaleStringDto | string;
	description?: api.LocaleStringDto | string;
}

const isCategory = (input: unknown): input is api.CategoryDto =>
	isPlainObject(input) &&
	typeof input.pim_id === 'string' &&
	typeof input.slug === 'string' &&
	typeof input.path === 'string' &&
	!!input.metadata &&
	!!input.locale &&
	Array.isArray(input.children);

const isProduct = (input: unknown): input is api.ProductDto =>
	isPlainObject(input) &&
	typeof input.pim_id === 'string' &&
	typeof input.type === 'string' &&
	!!input.metadata &&
	!!input.locale &&
	!!input.properties &&
	Array.isArray(input.product_properties) &&
	Array.isArray(input.categories);

const isContentPage = (input: unknown): input is PageContent =>
	isPlainObject(input) &&
	typeof input.key === 'string' &&
	typeof input.status === 'string' &&
	typeof input.title === 'string' &&
	typeof input.html === 'string';

/** SEO utility service. */
@Injectable({ providedIn: 'root' })
export class SeoService {
	private readonly baseUrl: string = this.storeData.storeData.base_url!;
	private readonly storeName: string = this.i18n.getStoreLocaleValue('name')!;
	private readonly fallbackTitle: string = this.i18n.getStoreLocaleValue('title')!;
	private readonly fallbackDescription: string = this.i18n.getStoreLocaleValue('description')!;

	constructor(
		private readonly i18n: I18nService,
		private readonly metaService: Meta,
		private readonly titleService: Title,
		private readonly storeData: StoreDataService,
		router: Router,
	) {
		// Set lang attribute on html tag.
		document.documentElement.setAttribute('lang', this.i18n.locale);
		// Listen to route changes and update hreflang tags.
		// This is a singleton service so we don't need to unsubscribe.
		router.events
			.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
			.subscribe((event) => {
				// Remove robots meta tag on every navigation event.
				this.metaService.removeTag('name="robots"');
				this.setHreflangTags(event.url);
			});
	}

	/**
	 * Set title and meta description based on provided object e.g. category or product.
	 * @param context Data used to determine title and description
	 */
	deduceProperties(context: PageContent | api.ProductDto | api.CategoryDto): void {
		let title: api.LocaleStringDto | string | undefined = '';
		let description: api.LocaleStringDto | string | undefined = '';
		// Determine title and description based on context type.
		if (isCategory(context)) {
			title = context.metadata?.seo_title || context.locale?.name;
			description = context.metadata?.seo_description || context.metadata?.descriptionbody;
		} else if (isProduct(context)) {
			title = context.locale.product_name;
			description = context.locale?.seo_description || context.locale?.product_description;
		} else if (isContentPage(context)) {
			title = context.seo_title || context.title;
			description = context.seo_description || context.html;
		}
		this.setDirectly({ title, description });
	}

	/** Set title and description metadata directly. Text can be localized and will be sanitized. */
	setDirectly({ title = '', description = '' }: SeoProperties): void {
		// Sanitize and set title and description with fallback values.
		title = this.sanitizeContent(title || this.fallbackTitle, TITLE_LENGTH_LIMIT);
		description = this.sanitizeContent(
			description || this.fallbackDescription,
			DESCRIPTION_LENGTH_LIMIT,
		);
		this.titleService.setTitle(
			environment.titleTemplate
				.replace('{{PAGE_TITLE}}', title)
				.replace('{{STORE_NAME}}', this.storeName || ''),
		);
		this.metaService.removeTag('name="description"');
		this.metaService.addTag({ name: 'description', content: description });
	}

	/**
	 * Set hreflang tags for internationalization.
	 * @param currentUrl The current URL.
	 */
	private setHreflangTags(currentUrl: string): void {
		// Remove existing hreflang tags.
		for (const link of document.querySelectorAll('link[hreflang]')) link.remove();
		// Add hreflang tags for all enabled locales.
		for (const locale of this.i18n.enabledLocales) {
			const link = document.createElement('link');
			link.setAttribute('hreflang', locale);
			link.setAttribute('href', `https://${this.baseUrl}/${locale}${currentUrl}`);
			link.setAttribute('rel', 'alternate');
			document.head.append(link);
		}
		const defaultLink = document.createElement('link');
		defaultLink.setAttribute('hreflang', 'x-default');
		defaultLink.setAttribute('href', `https://${this.baseUrl}/`);
		defaultLink.setAttribute('rel', 'alternate');
		document.head.append(defaultLink);
	}

	/**
	 * Strips HTML tags, removes new lines, tabs and excessive spaces and does other sanitizing
	 * to the provided string
	 * @param content Text to be sanitized
	 * @param truncateTo Maximum length which the sanitized string will be truncated to
	 */
	private sanitizeContent(content?: api.LocaleStringDto | string, truncateTo?: number) {
		// istanbul ignore next - should never happen but...
		if (!content) return '';
		// Parse localized strings.
		if (typeof content === 'object') content = this.i18n.getText(content);
		// Strip <style> tags and their contents
		let sanitized = content.replaceAll(/<style>([\S\s]+?)<\/style>/g, '');
		// Add space after every HTML tag to avoid tangled text after tags are removed
		sanitized = sanitized.replaceAll('>', '> ');
		// Convert line endings and tabs to spaces
		sanitized = sanitized.replaceAll(/\r\n?|\n|\t/g, ' ');
		// Strip HTML tags
		const div = document.createElement('div');
		div.innerHTML = sanitized;
		// istanbul ignore next
		sanitized = (div.textContent || div.textContent || '').trim();
		// Remove subsequent spaces
		while (sanitized.includes('  ')) {
			sanitized = sanitized.replaceAll(/ {2}/g, ' ');
		}
		return truncateTo ? truncate(sanitized, truncateTo) : sanitized;
	}
}
