import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

/** Provides information about the current browser viewport. */
@Injectable({ providedIn: 'root' })
export class ViewportService {
	readonly isMediumOrLarger$ = this.matches([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]);
	readonly isSmallOrExtraSmall$ = this.matches([Breakpoints.XSmall, Breakpoints.Small]);
	readonly isExtraSmall$ = this.matches(Breakpoints.XSmall);
	readonly isNotExtraSmall$ = this.matches([
		Breakpoints.Small,
		Breakpoints.Medium,
		Breakpoints.Large,
		Breakpoints.XLarge,
	]);

	constructor(private readonly breakpointObserver: BreakpointObserver) {}

	/** Utility method to check if the current viewport matches the given breakpoints. */
	private matches(breakpoints: readonly string[] | string): Observable<boolean> {
		return this.breakpointObserver.observe(breakpoints).pipe(
			distinctUntilChanged(),
			map((result) => result.matches),
			shareReplay(1),
		);
	}
}
