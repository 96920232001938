import { BreakPoint, BREAKPOINT } from '@angular/flex-layout';

export const PRINT_BREAKPOINTS: BreakPoint[] = [
	{
		alias: 'print',
		suffix: 'Print',
		mediaQuery: 'print',
		overlapping: false,
	},
];

export const PrintBreakpointsProvider = {
	provide: BREAKPOINT,
	useValue: PRINT_BREAKPOINTS,
	multi: true,
};
