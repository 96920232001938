/** @file Helpers for working with our Swagger API client. */
import { ServiceError } from '@app/api/gateway';

/**
 * A subset of ServiceError properties.
 *
 * ApiError was previously extended from api.ServiceError but the error
 * received from fetch is actually an instance of another ServiceError.
 * @see {@link ServiceError}
 * @see {@link api.ServiceError}
 * @todo Figure out why there are 2 ServiceError classes and if something can be done to simplify things.
 */
export interface ApiError extends Partial<ServiceError> {
	trace?: string;
}
export interface ApiActionSuccess<
	Type = string,
	TPayload = unknown,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
> {
	error: false;
	type: Type;
	payload: TPayload;
	meta: { res: api.FetchResponse; info: TMetaInfo; params: TMetaParams };
}

export interface ApiActionError<
	Type = string,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
> {
	error: true;
	type: Type;
	payload: ApiError;
	meta: { info: TMetaInfo; params: TMetaParams };
}

/** Check if API response is error */
export function isApiError(response: api.Response<unknown>): response is api.Response<ApiError> {
	return response.error ?? false;
}

/** Check if API response is error */
export function isApiSuccess<T>(
	response: api.Response<T>,
	// Exclude is needed here, T alone will include both types.
): response is api.Response<Exclude<T, ApiError>> {
	return !response.error;
}

/** Any API action can result in a successful payload or an error payload */
export type ApiAction<
	Type = string,
	TPayload = unknown,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
> =
	| ApiActionSuccess<Type, TPayload, TMetaInfo, TMetaParams>
	| ApiActionError<Type, TMetaInfo, TMetaParams>;

/** Check that an API action is successful */
export function isApiActionSuccess<
	Type = string,
	TPayload = unknown,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
>(
	action: ApiAction<Type, TPayload, TMetaInfo, TMetaParams>,
): action is ApiActionSuccess<Type, TPayload, TMetaInfo, TMetaParams> {
	return !action.error;
}

/** Parses API action and returns either undefined or ApiError */
export function parseApiActionError<
	Type = string,
	TPayload = unknown,
	TMetaInfo = Record<string, unknown>,
	TMetaParams = Record<string, unknown>,
>(action: ApiAction<Type, TPayload, TMetaInfo, TMetaParams>): ApiError | undefined {
	if (!action.error) return undefined;

	const source = action.payload instanceof ServiceError ? action.payload.body : action.payload;

	return {
		message: source?.message || 'Unknown error',
		trace: source?.trace,
	};
}
