// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Price */
// Auto-generated, edits will be overwritten
import * as Price from '../Price';

export const GET_PRICE_FOR_PRODUCT_START = 's/Price/GET_PRICE_FOR_PRODUCT_START';
export const GET_PRICE_FOR_PRODUCT = 's/Price/GET_PRICE_FOR_PRODUCT';
export type GET_PRICE_FOR_PRODUCT = api.ProductPriceDto;

export function getPriceForProduct(id: number, options?: Price.GetPriceForProductOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRICE_FOR_PRODUCT_START, meta: { info, params: { id, options } } });
    return Price.getPriceForProduct(id, options)
      .then(response => dispatch({
        type: GET_PRICE_FOR_PRODUCT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRICE_FOR_ORDER_START = 's/Price/GET_PRICE_FOR_ORDER_START';
export const GET_PRICE_FOR_ORDER = 's/Price/GET_PRICE_FOR_ORDER';
export type GET_PRICE_FOR_ORDER = api.OrderPriceDto;

export function getPriceForOrder(options?: Price.GetPriceForOrderOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRICE_FOR_ORDER_START, meta: { info, params: { options } } });
    return Price.getPriceForOrder(options)
      .then(response => dispatch({
        type: GET_PRICE_FOR_ORDER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRICES_START = 's/Price/GET_PRICES_START';
export const GET_PRICES = 's/Price/GET_PRICES';
export type GET_PRICES = {
    billing?: api.BillingPrice[],
    additional?: api.AdditionalPrice[]
  };

export function getPrices(options?: Price.GetPricesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRICES_START, meta: { info, params: { options } } });
    return Price.getPrices(options)
      .then(response => dispatch({
        type: GET_PRICES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_SHIPPING_PRICES_START = 's/Price/GET_SHIPPING_PRICES_START';
export const GET_SHIPPING_PRICES = 's/Price/GET_SHIPPING_PRICES';
export type GET_SHIPPING_PRICES = any;

export function getShippingPrices(options?: Price.GetShippingPricesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_SHIPPING_PRICES_START, meta: { info, params: { options } } });
    return Price.getShippingPrices(options)
      .then(response => dispatch({
        type: GET_SHIPPING_PRICES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRICE_DATA_FOR_SEEDS_START = 's/Price/GET_PRICE_DATA_FOR_SEEDS_START';
export const GET_PRICE_DATA_FOR_SEEDS = 's/Price/GET_PRICE_DATA_FOR_SEEDS';
export type GET_PRICE_DATA_FOR_SEEDS = any;

export function getPriceDataForSeeds(options?: Price.GetPriceDataForSeedsOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRICE_DATA_FOR_SEEDS_START, meta: { info, params: { options } } });
    return Price.getPriceDataForSeeds(options)
      .then(response => dispatch({
        type: GET_PRICE_DATA_FOR_SEEDS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/Price/ALL_START';
export const ALL = 's/Price/ALL';
export type ALL = any;

export function all(type: 'product'|'product_price_group'|'material'|'billing'|'shipping'|'size'|'layout'|'finishing'|'additional'|'printing'|'starting'|'packaging'|'product_options'|'tax', options?: Price.AllOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: { type, options } } });
    return Price.all(type, options)
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SET_PRICE_START = 's/Price/SET_PRICE_START';
export const SET_PRICE = 's/Price/SET_PRICE';
export type SET_PRICE = api.ProductPrice | api.ProductPriceGroup | api.MaterialPrice | api.BillingPrice | api.ShippingPrice | api.SheetSize | api.SheetLayout | api.FinishingPrice | api.AdditionalPrice | api.PrintingPrice | api.StartingPrice | api.PackagingPrice | api.ProductOptionsPrice | api.TaxRate;

export function setPrice(options?: Price.SetPriceOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SET_PRICE_START, meta: { info, params: { options } } });
    return Price.setPrice(options)
      .then(response => dispatch({
        type: SET_PRICE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SET_PRICES_START = 's/Price/SET_PRICES_START';
export const SET_PRICES = 's/Price/SET_PRICES';
export type SET_PRICES = (api.ProductPrice | api.ProductPriceGroup | api.MaterialPrice | api.BillingPrice | api.ShippingPrice | api.SheetSize | api.SheetLayout | api.FinishingPrice | api.AdditionalPrice | api.PrintingPrice | api.StartingPrice | api.PackagingPrice | api.ProductOptionsPrice | api.TaxRate)[];

export function setPrices(type: 'product'|'product_price_group'|'material'|'billing'|'shipping'|'size'|'layout'|'finishing'|'additional'|'printing'|'starting'|'packaging'|'product_options'|'tax', options?: Price.SetPricesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SET_PRICES_START, meta: { info, params: { type, options } } });
    return Price.setPrices(type, options)
      .then(response => dispatch({
        type: SET_PRICES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/Price/DESTROY_START';
export const DESTROY = 's/Price/DESTROY';
export type DESTROY = undefined;

export function destroy(type: 'product'|'product_price_group'|'material'|'billing'|'shipping'|'size'|'layout'|'finishing'|'additional'|'printing'|'starting'|'packaging'|'product_options'|'tax', id: number, options?: Price.DestroyOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { type, id, options } } });
    return Price.destroy(type, id, options)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
