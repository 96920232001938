import { Component, OnInit, OnDestroy } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { DialogBase } from '@app/dialog/dialog-base';
import { firstTruthy } from '@app/shared/utils/util';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';

export enum ImageAdvanceDirection {
	Next = 1,
	Previous = -1,
}

export type ImageViewerImage = string | Record<string, string>;

@Component({
	selector: 'g-image-viewer-dialog',
	templateUrl: './image-viewer-dialog.component.html',
	styleUrls: ['./image-viewer-dialog.component.scss'],
})
export class ImageViewerDialogComponent extends DialogBase implements OnInit, OnDestroy {
	static MAX_WIDTH = '100vw';
	shownImageUrl?: string;
	imageAlt?: string;
	shownImageIndex = -1;
	images: ImageViewerImage[] = [];
	key?: string;
	isLoading = true;
	readonly icons = {
		faXmark,
		faSpinner,
		faChevronLeft,
		faChevronRight,
	};
	readonly direction = ImageAdvanceDirection;

	constructor(protected store: Store<RootReducer.State>) {
		super(store); // Needed for tests
	}

	ngOnInit(): void {
		this.dialogData$.pipe(firstTruthy).subscribe((input: any) => {
			const { images, key, imageIndex = 0, imageAlt = '' } = input;
			this.images = images ?? [];
			this.key = key;
			this.imageAlt = imageAlt;
			this.selectImage(imageIndex);
		});
		document.body.addEventListener('keydown', this.onKeyDown);
	}

	ngOnDestroy(): void {
		document.body.removeEventListener('keydown', this.onKeyDown);
	}

	selectImage(index: number) {
		this.shownImageIndex = Math.min(Math.max(0, index), this.images.length - 1);
		const image = this.images[this.shownImageIndex];
		const imgUrl = typeof image === 'object' ? image[this.key!] : image;
		if (imgUrl && this.shownImageUrl !== imgUrl) {
			this.isLoading = true;
			this.shownImageUrl = imgUrl;
		} else {
			this.isLoading = false;
		}
	}

	selectAdjacentImage(direction: ImageAdvanceDirection) {
		this.selectImage(this.shownImageIndex + direction);
	}

	canAdvanceImage(direction: ImageAdvanceDirection) {
		const newIndex = this.shownImageIndex + direction;
		return newIndex < this.images.length && newIndex >= 0;
	}

	onKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowRight') {
			this.selectAdjacentImage(ImageAdvanceDirection.Next);
		} else if (event.key === 'ArrowLeft') {
			this.selectAdjacentImage(ImageAdvanceDirection.Previous);
		}
	};
}
