import { UntypedFormGroup } from '@angular/forms';
import { OperatorFunction, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Subscribes to password field changes and sets passwordScore pristine/dirty status based on
 * whether password is valid or not
 * @param form Form which contains the password and password score
 * @param destroy$ Observable from TakeUntilDestroy
 * @param passwordField Name of password field in form. Defaults to "password"
 * @param passwordScoreField Name of password score field in form. Defaults to "passwordScore"
 * @return Subscription for the password watcher. Will be automatically unsubscribed
 * when destroy$ parameter is provided.
 */
export function syncPasswordScoreValidation(
	form: UntypedFormGroup,
	takeUntilDestroy: OperatorFunction<unknown, unknown>,
	passwordField = 'password',
	passwordScoreField = 'passwordScore',
): Subscription {
	let selector$ = form.get(passwordField).valueChanges.pipe(distinctUntilChanged());
	if (takeUntilDestroy) selector$ = selector$.pipe(takeUntilDestroy);
	return selector$.subscribe((password) => {
		// Don't show "password is too weak" error when password is otherwise invalid
		if (!password || (typeof password === 'string' && password.length < 10))
			form.controls[passwordScoreField].markAsPristine();
		else form.controls[passwordScoreField].markAsDirty();
	});
}
