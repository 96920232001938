<div class="address-summary-content">
	<div
		class="address-container"
		fxLayout="row"
		fxLayout.xs="column"
		fxLayoutGap="0px"
		fxLayoutGap.xs="20px"
	>
		@if (userInfo$ | async; as userInfo) {
			<ul class="address-column user-info" fxLayout="column" fxFlex="33" fxFlex.xs="100">
				<li class="header" i18n="@@OrdererHeader">Orderer</li>
				<li i18n-title="@@OrdererFullName" title="Full Name"
					>{{ userInfo.first_name }} {{ userInfo.last_name }}</li
				>
				<li i18n-title="@@OrdererEmail" title="Email">{{ userInfo.email }}</li>
				<li i18n-title="@@OrdererPhone" title="Phone">{{ userInfo.phone }}</li>
			</ul>
		}

		@if (shippingAddress$ | async; as shippingAddress) {
			<ul class="address-column shipping-address" fxLayout="column" fxFlex="33" fxFlex.xs="100">
				<li class="header" i18n="@@ShippingAddressHeader">Shipping Address</li>
				<li i18n-title="@@ShippingAddressCompany" title="Company">{{ shippingAddress.company }}</li>
				<li i18n-title="@@ShippingAddressFullName" title="Full Name"
					>{{ shippingAddress.first_name }} {{ shippingAddress.last_name }}</li
				>
				<li i18n-title="@@ShippingAddressPhoneNumber" title="Phone Number">{{
					shippingAddress.phone
				}}</li>
				@if (shippingAddress.email) {
					<li i18n-title="@@ShippingAddressEmail" title="Email">{{ shippingAddress.email }}</li>
				}
				<li i18n-title="@@ShippingAddressStreetName" title="Street Name">{{
					shippingAddress.street_name
				}}</li>
				<li i18n-title="@@ShippingAddressSecondAddressLine" title="Second Address Line">{{
					shippingAddress.second_address_line
				}}</li>
				<li i18n-title="@@ShippingAddressZipCode" title="Zip Code"
					>{{ shippingAddress.zip_code }} {{ shippingAddress.city }}</li
				>
				<li i18n-title="@@ShippingAddressCountry" title="Country">{{ shippingAddress.country }}</li>
			</ul>
		}

		@if (billingAddress$ | async; as billingAddress) {
			<ul class="address-column billing-address" fxLayout="column" fxFlex="33" fxFlex.xs="100">
				<li class="header" i18n="@@BillingAddressHeader">Billing Address</li>
				@if (isBillingAddressCompanyValid$ | async) {
					<li i18n-title="@@BillingAddressCompany" title="Company">{{ billingAddress.company }}</li>
				}
				@if (isBillingAddressFullNameValid$ | async; as boolean) {
					<li i18n-title="@@BillingAddressFullName" title="Full Name"
						>{{ billingAddress.first_name }} {{ billingAddress.last_name }}</li
					>
				}
				<li i18n-title="@@BillingAddressStreetName" title="Street Name">{{
					billingAddress.street_name
				}}</li>
				<li i18n-title="@@BillingAddressZipCode" title="Zip Code"
					>{{ billingAddress.zip_code }} {{ billingAddress.city }}</li
				>
				<li i18n-title="@@BillingAddressCountry" title="Country">{{ billingAddress.country }}</li>
				@if (isBillingAddressEmailValid$ | async) {
					<li i18n-title="@@BillingAddressEmail" title="Email">{{ billingAddress.email }}</li>
				}
				@if (isBillingAddressEdiNumberValid$ | async) {
					<li i18n-title="@@BillingAddressEdiNumber" title="EDI number"
						>{{ billingAddress.edi_number }}
						<b i18n="@@BillingAddressEdiNumberParens">(EDI number)</b></li
					>
				}
				@if (isBillingAddressOperatorValid$ | async) {
					<li i18n-title="@@BillingAddressOperator" title="Operator"
						>{{ billingAddress.operator }} <b i18n="@@BillingAddressOperatorParens">(Operator)</b></li
					>
				}

				@if (isBillingAddressOperatorIdValid$ | async) {
					<li i18n-title="@@BillingAddressOperatorId" title="Operator ID"
						>{{ billingAddress.operator_id }}
						<b i18n="@@BillingAddressOperatorIdParens">(Operator ID)</b></li
					>
				}
				<li i18n-title="@@BillingAddressVatNumber" title="VAT number">{{
					billingAddress.vat_number
				}}</li>
			</ul>
		}
	</div>
</div>
