import { I18nService } from '../utils/i18n.service';
import { Injectable } from '@angular/core';
import { isCartItem } from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { getProductQuantity } from '@app/product/utils/product-utils';

type DataLayerEventType =
	| 'add_to_cart'
	| 'remove_from_cart'
	| 'purchase'
	| 'search'
	| 'view_item'
	| 'select_item'
	| 'view_item_list'
	| 'view_promotion'
	| 'select_promotion'
	| 'begin_checkout'
	| 'add_shipping_info'
	| 'add_payment_info';

type DataLayerProduct = {
	affiliation?: string;
	coupon?: string;
	discount?: number;
	index?: number;
	item_brand?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
	item_list_id?: string;
	item_list_name?: string;
	item_variant?: string;
	price?: number;
	quantity?: number;
} & ({ item_id: string; item_name?: string } | { item_id?: string; item_name: string }); // name or id is required.

type DataLayerPromotion = {
	creative_name?: string;
	creative_slot?: string;
} & ( // name or id is required.
	| { promotion_id: string; promotion_name?: string }
	| { promotion_id?: string; promotion_name: string }
) &
	DataLayerProduct;

type ItemWithProduct = api.CartItemWithProductDto | api.OrderProductDto;
type ItemOrProduct = ItemWithProduct | api.ProductDto;

/**
 * DataLayer class for pushing data to window.dataLayer.
 * Made according to https://developers.google.com/tag-manager/enhanced-ecommerce
 * GA4 implementation according to https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
 */
@Injectable({ providedIn: 'root' })
export class DataLayer {
	static CATEGORY_LISTING = 'Category listing';
	static NOT_FOUND_PAGE = 'Not found page';

	readonly dataLayer = (window.dataLayer || []) as DataLayerEvent[] & {
		push: <EventType extends DataLayerEventType>(event: DataLayerEvent<EventType>) => void;
	};

	constructor(private readonly i18n: I18nService) {}

	/**
	 * Reset ecommerce variable on dataLayer
	 *
	 */
	resetEcommerce(): void {
		this.dataLayer.push({
			ecommerce: undefined,
		});
	}

	/**
	 * Returns product member from a CartItem if input is a CartItem. Otherwise returns input.
	 * @param item CartItem or Product
	 * @returns Product
	 */
	getProduct(item: ItemOrProduct): api.ProductDto {
		if (isCartItem(item)) return item.product;
		return item as api.ProductDto;
	}

	/**
	 * Add product impressions
	 * @param products Array of products
	 * @param list Optional list name
	 */
	addImpressions(products: api.ProductDto[], list?: string): void {
		this.resetEcommerce();
		const items = products.map((product: api.ProductDto) => ({
			item_name: this.getProductName(product),
			item_id: this.getProductId(product),
			item_category: this.getProductCategory(product),
			item_list_name: list,
		}));
		this.dataLayer.push({
			event: 'view_item_list',
			ecommerce: {
				items,
			},
		});
	}

	/**
	 * Add a promotion view.
	 * @param promotion Array of objects representing an internal site promotion.
	 * Id or name is required.
	 * @param promotion.promotion_id The id of the promotion.
	 * @param promotion.promotion_name The name of the promotion.
	 * @param promotion.creative_name Something that distinguishes the promotion.
	 * @param promotion.creative_slot Name of the promotional slot.
	 */
	addPromotionView(promotion: DataLayerPromotion): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'view_promotion',
			ecommerce: {
				items: [promotion],
			},
		});
	}

	/**
	 * Add a promotion click or view.
	 * @param promotion Array of objects representing an internal site promotion.
	 * Id or name is required.
	 * @param promotion.promotion_id The id of the promotion.
	 * @param promotion.promotion_name The name of the promotion.
	 * @param promotion.creative_name Something that distinguishes the promotion.
	 * @param promotion.creative_slot Name of the promotional slot.
	 */
	addPromotionClick(promotion: DataLayerPromotion): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'select_promotion',
			ecommerce: {
				items: [promotion],
			},
		});
	}

	/**
	 * Add product click
	 * @param item Product or CartItem
	 * @param list Optional list name
	 */
	addProductClick(item: ItemOrProduct, list?: string): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'select_item',
			ecommerce: {
				items: [
					{
						item_name: this.getProductName(item),
						item_id: this.getProductId(item),
						item_category: this.getProductCategory(item),
						item_list_name: list,
					},
				],
			},
		});
	}

	/**
	 * Add product detail view
	 * @param item Product
	 * @param list Optional list name
	 */
	addProductDetailView(item: api.ProductDto, list?: string): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'view_item',
			ecommerce: {
				items: [
					{
						item_name: this.getProductName(item),
						item_id: this.getProductId(item),
						item_category: this.getProductCategory(item),
						item_list_name: list,
					},
				],
			},
		});
	}

	/** Add product to cart */
	addProductToCart(item: api.CartItemWithProductDto, currency = 'EUR' as const): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'add_to_cart',
			ecommerce: {
				currency,
				value: this.getTotalPrice(item),
				items: [
					{
						item_id: this.getProductId(item),
						item_name: this.getProductName(item),
						discount: this.getProductDiscount(item),
						item_category: this.getProductCategory(item),
						quantity: getProductQuantity(item),
						price: this.getProductPrice(item),
					},
				],
			},
		});
	}

	/**
	 * Remove product from cart
	 * @param item CartItem
	 */
	removeProductFromCart(item: api.CartItemWithProductDto, currency = 'EUR' as const): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'remove_from_cart',
			ecommerce: {
				currency,
				value: this.getTotalPrice(item),
				items: [
					{
						item_id: this.getProductId(item),
						item_name: this.getProductName(item),
						discount: this.getProductDiscount(item),
						item_category: this.getProductCategory(item),
						price: this.getProductPrice(item),
						quantity: getProductQuantity(item),
					},
				],
			},
		});
	}

	/**
	 * Begin checkout
	 * @param cartItems Array of CartItems
	 */
	beginCheckout(cartItems: api.CartItemWithProductDto[]): void {
		this.resetEcommerce();
		const items = cartItems.map((item) => ({
			item_name: this.getProductName(item),
			item_id: this.getProductId(item),
			item_category: this.getProductCategory(item),
			price: this.getProductPrice(item),
			quantity: getProductQuantity(item, 1),
		}));
		this.dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				items,
			},
		});
	}

	/**
	 * Add purchase
	 * @param order Order
	 */
	addPurchase(order: api.OrderDto, currency = 'EUR' as const): void {
		this.resetEcommerce();
		const items = order.products.map((item) => ({
			item_name: this.getProductName(item),
			item_id: this.getProductId(item),
			item_category: this.getProductCategory(item),
			price: this.getProductPrice(item),
			quantity: getProductQuantity(item, 1),
		}));

		// If there is a billing price, add it as an item (ED-2063)
		const billingPrice = this.getOrderBillingPrice(order);
		if (billingPrice > 0) {
			items.push({
				item_name: 'Billing',
				item_id: 'billing',
				item_category: 'Billing',
				price: billingPrice,
				quantity: 1,
			});
		}

		this.dataLayer.push({
			event: 'purchase',
			ecommerce: {
				currency,
				transaction_id: this.getOrderId(order),
				value: this.getOrderPrice(order),
				tax: this.getOrderTax(order),
				shipping: this.getOrderShippingPrice(order),
				coupon: this.getCoupon(order),
				items,
			},
		});
	}

	/** Add search term */
	addSearch(searchTerm: string): void {
		this.resetEcommerce();
		this.dataLayer.push({
			event: 'search',
			search_term: searchTerm,
		});
	}

	/**
	 * Get product name from a product
	 * @param product CartItem or Product
	 * @returns Product name
	 */
	private getProductName(product: ItemOrProduct): string {
		return this.i18n.getText(this.getProduct(product)?.locale?.product_name);
	}

	/**
	 * Get product ID from a product
	 * @param product CartItem or Product
	 * @returns Product ID
	 */
	private getProductId(product: ItemOrProduct): string {
		return this.getProduct(product)?.sku;
	}

	/** Get product category name */
	private getProductCategory(item: ItemOrProduct): string {
		return this.i18n.getText(this.getProduct(item)?.categories?.[0]?.locale?.name);
	}

	/**
	 * Get price for a single product
	 * @param item CartItem
	 * @returns Price
	 */
	private getProductPrice(item: api.CartItemWithProductDto | api.OrderProductDto): number {
		// Shouldn't use floats for prices, but this is for analytics so it's probably fine.
		return Number.parseFloat(item?.price?.price_without_tax || '0') / getProductQuantity(item, 1);
	}

	/**
	 * Get total price for item, taking the quantity into account
	 * @param item CartItem
	 * @returns Price
	 */
	private getTotalPrice(item: api.CartItemWithProductDto | api.OrderProductDto): number {
		return Number.parseFloat(item?.price?.price_without_tax || '0');
	}

	/**
	 * Get the discount for a single product
	 * @param item CartItem
	 * @returns Discount
	 */
	private getProductDiscount(item: api.CartItemWithProductDto | api.OrderProductDto): number {
		return Number.parseFloat(item?.price?.discounted_price || '0') / getProductQuantity(item, 1);
	}

	/**
	 * Get order id from an Order
	 * @param order Order
	 * @returns Order Id
	 */
	private getOrderId(order: api.OrderDto): string {
		return order?.order_number ?? '???';
	}

	/**
	 * Get total price for an Order
	 * @param order Order
	 * @returns Order price
	 */
	private getOrderPrice(order: api.OrderDto): number {
		return Number.parseFloat(order?.metadata?.total_price?.totalPriceWithoutTax || '0');
	}

	/**
	 * Get tax for an order
	 * @param order Order
	 * @returns Tax amount
	 */
	private getOrderTax(order: api.OrderDto): number {
		return Number.parseFloat(order?.metadata?.total_price?.taxes?.total || '0');
	}

	/**
	 * Get shipping price
	 * @param order Order
	 * @returns Shipping price
	 */
	private getOrderShippingPrice(order: api.OrderDto): number {
		return Number.parseFloat(order?.metadata?.total_price?.shippingPrice || '0');
	}

	/**
	 * Get billing price
	 * @param order Order
	 * @returns Billing price
	 */
	private getOrderBillingPrice(order: api.OrderDto): number {
		return Number.parseFloat(order?.metadata?.total_price?.billingPrice || '0');
	}

	/**
	 * Get coupon code
	 * @param order Order
	 * @returns Coupon code
	 */
	private getCoupon(order: api.OrderDto): string {
		return order?.metadata?.discount_code ?? '';
	}
}
