import { NewRelic } from '../services/newrelic.service';
import { I18nService } from '../utils/i18n.service';
import { isResolved, isSuccess } from '../utils/remote-data';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { baseUrl, BASE_URL } from '@app/api/action/Store';
import { init } from '@app/api/gateway';
import { RootReducer, Store } from '@app/app.reducers';
import { getStore, StoreWithDetails } from '@app/shared/reducers/storefront.reducer';
import { CognitoService } from '@app/shared/services/cognito.service';
import {
	PreviewTokenService,
	PREVIEW_TOKEN_QUERY_PARAM,
} from '@app/shared/services/preview-token.service';
import { StoreDataService } from '@app/shared/services/store-data.service';
import { setName } from '@app/shared/utils/name-helper';
import { hasProp } from '@app/shared/utils/util';
import { getIsAuthenticating } from '@app/user/reducers/auth.reducer';
import debug from 'debug';
import { firstValueFrom } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

const FORCE_LOGIN_ON_ERROR_CODES = new Set([401]);

/** Load storefront data from API or DOM. */
export function appInitializer(
	store: Store<RootReducer.State>,
	cognito: CognitoService,
	previewTokenService: PreviewTokenService,
	i18n: I18nService,
	storeData: StoreDataService,
	newRelic: NewRelic,
): () => Promise<void> {
	const debugLog = debug('ecom:appInitializer');
	return async () => {
		// Storefront data is already embedded in the html payload!
		if (hasProp(window, 'initStorefrontData')) {
			if (typeof window.initStorefrontData === 'string') {
				debugLog('parsing pre-fetched data');
				const data = JSON.parse(window.initStorefrontData) as StoreWithDetails;
				// @ts-expect-error: It is readonly, but we must set it here.
				storeData.storeData = data;
				// JSON data
				store.dispatch({ type: BASE_URL, payload: data, error: false });
			}
		} else {
			debugLog('fetching store data');
			store.dispatch(setName('baseUrl', baseUrl(window.location.host, { locale: i18n.locale })));
		}

		return firstValueFrom(
			store.select(getStore).pipe(
				// Wait until we get the store or there was an error.
				first((storefront) => isResolved(storefront)),
				switchMap(async (storefront) => {
					debugLog('storefront initialized', storefront);
					if (isSuccess(storefront)) {
						// @ts-expect-error: It is readonly, but we must set it here.
						storeData.storeData = storefront.data;

						newRelic.setCustomAttribute('storeIdentifier', storefront.data.base_url!);
					}
					await initAuthorization(store, cognito, previewTokenService);
					i18n.init();
				}),
			),
		);
	};
}

/**
 * Setup OpenAPI client authorization with preview or Cognito JWT token.
 * @param store service
 * @param cognito service
 * @param previewTokenService service
 */
async function initAuthorization(
	store: Store<RootReducer.State>,
	cognito: CognitoService,
	previewTokenService: PreviewTokenService,
) {
	const previewToken = getPreviewToken(previewTokenService);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const apiOptions = { fetchOptions: { headers: {} as Record<string, string> } };
	if (previewToken) {
		apiOptions.fetchOptions.headers['X-Preview-Token'] = previewToken;
	}
	init({
		...apiOptions,
		getAuthorization: cognito.getAuthorization,
		processResponse: processRequestError(store, cognito),
	});
}

/**
 * Check request response for specific error codes (such as 401 Unauthorized)
 * and log user out and redirect them to login page when necessary.
 * @param store service
 * @param cognito service
 * @returns Function useable as openssl-client processResponse/processError callback
 */
export function processRequestError(
	store: Store<RootReducer.State>,
	cognito: CognitoService,
): api.ServiceOptions['processResponse'] {
	const debugLog = debug('ecom:processRequestError');
	return async (_req, res) => {
		const isAuthenticating = !!(await firstValueFrom(store.select(getIsAuthenticating)));
		const errorCode = res?.error === true ? res?.raw?.status : undefined;
		const shouldRedirectToLogin =
			Number.isFinite(errorCode) && FORCE_LOGIN_ON_ERROR_CODES.has(errorCode!);
		debugLog('handling request error', { errorCode, shouldRedirectToLogin, isAuthenticating });
		// Do nothing if it's not necessary to force user to login page
		if (!shouldRedirectToLogin || isAuthenticating) return { res };
		// Log user out to prevent issues with invalid Cognito sessions
		await cognito.authReady;
		await cognito.logout();
		return { res };
	};
}

/**
 * Checks query parameters and local storage for preview token. When query parameters contain
 * a token, it will be stored to local storage so it's not lost in page refresh.
 * @param previewTokenService service
 * @returns Preview token
 */
function getPreviewToken(previewTokenService: PreviewTokenService): string {
	const token = new URL(window.location.href).searchParams.get(PREVIEW_TOKEN_QUERY_PARAM);
	if (token) previewTokenService.setToken(token);
	return previewTokenService.getTokenValue();
}

export const AppInitializerProvider = {
	provide: APP_INITIALIZER,
	deps: [Store, CognitoService, PreviewTokenService, I18nService, StoreDataService, NewRelic],
	useFactory: appInitializer,
	multi: true,
};

/** Module to initialize the app. */
@NgModule({ providers: [AppInitializerProvider] })
export class AppInitializerModule {}
