// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/AddressBook */
// Auto-generated, edits will be overwritten
import * as AddressBook from '../AddressBook';

export const GET_ADDRESSES_BY_USER_IDS_START = 's/AddressBook/GET_ADDRESSES_BY_USER_IDS_START';
export const GET_ADDRESSES_BY_USER_IDS = 's/AddressBook/GET_ADDRESSES_BY_USER_IDS';
export type GET_ADDRESSES_BY_USER_IDS = api.AddressBook[];

export function getAddressesByUserIds(body: {
    userIds?: string[]
  }, options?: AddressBook.GetAddressesByUserIdsOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_ADDRESSES_BY_USER_IDS_START, meta: { info, params: { body, options } } });
    return AddressBook.getAddressesByUserIds(body, options)
      .then(response => dispatch({
        type: GET_ADDRESSES_BY_USER_IDS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_ADDRESSES_START = 's/AddressBook/GET_ADDRESSES_START';
export const GET_ADDRESSES = 's/AddressBook/GET_ADDRESSES';
export type GET_ADDRESSES = api.AddressBook[];

export function getAddresses(options?: AddressBook.GetAddressesOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_ADDRESSES_START, meta: { info, params: { options } } });
    return AddressBook.getAddresses(options)
      .then(response => dispatch({
        type: GET_ADDRESSES,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_ADDRESSES_BY_USER_START = 's/AddressBook/GET_ADDRESSES_BY_USER_START';
export const GET_ADDRESSES_BY_USER = 's/AddressBook/GET_ADDRESSES_BY_USER';
export type GET_ADDRESSES_BY_USER = api.AddressBook[];

export function getAddressesByUser(userId: string, options?: AddressBook.GetAddressesByUserOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_ADDRESSES_BY_USER_START, meta: { info, params: { userId, options } } });
    return AddressBook.getAddressesByUser(userId, options)
      .then(response => dispatch({
        type: GET_ADDRESSES_BY_USER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/AddressBook/CREATE_START';
export const CREATE = 's/AddressBook/CREATE';
export type CREATE = api.AddressBook;

export function create(options?: AddressBook.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return AddressBook.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/AddressBook/ALL_START';
export const ALL = 's/AddressBook/ALL';
export type ALL = api.AddressBook[];

export function all(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: {  } } });
    return AddressBook.all()
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_START = 's/AddressBook/UPDATE_START';
export const UPDATE = 's/AddressBook/UPDATE';
export type UPDATE = api.AddressBook;

export function update(id: number, options?: AddressBook.UpdateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_START, meta: { info, params: { id, options } } });
    return AddressBook.update(id, options)
      .then(response => dispatch({
        type: UPDATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/AddressBook/DESTROY_START';
export const DESTROY = 's/AddressBook/DESTROY';
export type DESTROY = api.AddressBook;

export function destroy(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { id } } });
    return AddressBook.destroy(id)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ONE_START = 's/AddressBook/ONE_START';
export const ONE = 's/AddressBook/ONE';
export type ONE = api.AddressBook;

export function one(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ONE_START, meta: { info, params: { id } } });
    return AddressBook.one(id)
      .then(response => dispatch({
        type: ONE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SET_DEFAULT_START = 's/AddressBook/SET_DEFAULT_START';
export const SET_DEFAULT = 's/AddressBook/SET_DEFAULT';
export type SET_DEFAULT = undefined;

export function setDefault(storeId: number, options?: AddressBook.SetDefaultOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SET_DEFAULT_START, meta: { info, params: { storeId, options } } });
    return AddressBook.setDefault(storeId, options)
      .then(response => dispatch({
        type: SET_DEFAULT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SCHEMA_START = 's/AddressBook/SCHEMA_START';
export const SCHEMA = 's/AddressBook/SCHEMA';
export type SCHEMA = api.AddressBook;

export function schema(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SCHEMA_START, meta: { info, params: {  } } });
    return AddressBook.schema()
      .then(response => dispatch({
        type: SCHEMA,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
