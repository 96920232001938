import { Pipe, PipeTransform } from '@angular/core';

/** Pipe that returns the value of a field from a validation object. */
@Pipe({ name: 'validationField', standalone: true })
export class ValidationFieldPipe implements PipeTransform {
	/** Pipe that returns the value of a field from a validation object. */
	transform(obj: Record<string, string> | null, field: string): string | undefined {
		return obj?.[field] ?? undefined;
	}
}
