// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module ContactRequest */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Send contact request
 * 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function send(options?: SendOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(sendOperation, parameters);
}

export interface SendOptions {
  body?: {
    first_name: string,
    last_name: string,
    company?: string,
    attachment?: string,
    email: string,
    phone: string,
    message: string,
    store_id: number,
    email2?: string,
    city?: string
  };
}

const sendOperation: api.OperationInfo = {
  path: '/contact-request/send',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
