// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Order */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Updates Mylly and Logia status/errors to order and related products
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function updateOrderSync(id: number, options?: UpdateOrderSyncOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOrderSyncOperation, parameters);
}

/**
 * Updates order status to provided values
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<module:types.OrderDto>} OK
 */
export function updateOrderStatus(id: number, options?: UpdateOrderStatusOptions): Promise<api.Response<api.OrderDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOrderStatusOperation, parameters);
}

/**
 * Get list of Orders from database.
 * 
 * @param {object} options Optional options
 * @param {number} [options.limit] 
 * @param {number} [options.offset] 
 * @param {string} [options.sort=id] 
 * @param {string} [options.order=ASC] Enum: ASC, DESC. 
 * @param {string} [options.sortLang=fi] 
 * @param {number} [options.startAt] 
 * @param {number} [options.endAt] 
 * @param {number} [options.id] 
 * @return {Promise<module:types.OrderDto[]>} OK
 */
export function all(options?: AllOptions): Promise<api.Response<api.OrderDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    query: {
      limit: options.limit,
      offset: options.offset,
      sort: options.sort,
      order: options.order,
      sortLang: options.sortLang,
      startAt: options.startAt,
      endAt: options.endAt,
      id: options.id
    }
  };
  return gateway.request(allOperation, parameters);
}

/**
 * Save an order to be fulfilled after payment has been confirmed
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.OrderDto>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.OrderDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Get Order by access token
 * 
 * @param {string} accessToken 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.OrderDto>} OK
 */
export function getOrder(accessToken: string, options?: GetOrderOptions): Promise<api.Response<api.OrderDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      accessToken
    }
  };
  return gateway.request(getOrderOperation, parameters);
}

/**
 * Get list of Orders from database for given store.
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {number} [options.limit] 
 * @param {number} [options.offset] 
 * @param {string} [options.sort=id] 
 * @param {string} [options.order=ASC] Enum: ASC, DESC. 
 * @param {string} [options.sortLang=fi] 
 * @param {number} [options.startAt] 
 * @param {number} [options.endAt] 
 * @return {Promise<module:types.OrderDto[]>} OK
 */
export function getByStore(options?: GetByStoreOptions): Promise<api.Response<api.OrderDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      limit: options.limit,
      offset: options.offset,
      sort: options.sort,
      order: options.order,
      sortLang: options.sortLang,
      startAt: options.startAt,
      endAt: options.endAt
    }
  };
  return gateway.request(getByStoreOperation, parameters);
}

/**
 * Get list of Orders from database for given search term.
 * 
 * @param {string} searchTerm 
 * @param {object} options Optional options
 * @param {number} [options.limit] 
 * @param {number} [options.offset] 
 * @param {string} [options.sort=id] 
 * @param {string} [options.order=ASC] Enum: ASC, DESC. 
 * @param {string} [options.sortLang=fi] 
 * @param {number} [options.startAt] 
 * @param {number} [options.endAt] 
 * @return {Promise<module:types.OrderDto[]>} OK
 */
export function searchAll(searchTerm: string, options?: SearchAllOptions): Promise<api.Response<api.OrderDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    query: {
      limit: options.limit,
      offset: options.offset,
      sort: options.sort,
      order: options.order,
      sortLang: options.sortLang,
      startAt: options.startAt,
      endAt: options.endAt
    },
    path: {
      searchTerm
    }
  };
  return gateway.request(searchAllOperation, parameters);
}

/**
 * Get list of Orders from database for given store and search term.
 * 
 * @param {string} searchTerm 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {number} [options.limit] 
 * @param {number} [options.offset] 
 * @param {string} [options.sort=id] 
 * @param {string} [options.order=ASC] Enum: ASC, DESC. 
 * @param {string} [options.sortLang=fi] 
 * @param {number} [options.startAt] 
 * @param {number} [options.endAt] 
 * @return {Promise<module:types.OrderDto[]>} OK
 */
export function searchByStore(searchTerm: string, options?: SearchByStoreOptions): Promise<api.Response<api.OrderDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      limit: options.limit,
      offset: options.offset,
      sort: options.sort,
      order: options.order,
      sortLang: options.sortLang,
      startAt: options.startAt,
      endAt: options.endAt
    },
    path: {
      searchTerm
    }
  };
  return gateway.request(searchByStoreOperation, parameters);
}

/**
 * Get orders for currently authenticated user
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.OrderDto[]>} OK
 */
export function getMyOrders(options?: GetMyOrdersOptions): Promise<api.Response<api.OrderDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    }
  };
  return gateway.request(getMyOrdersOperation, parameters);
}

/**
 * Update address in order metadata
 * 
 * @param {number} orderId 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.OrderDto>} OK
 */
export function updateAddress(orderId: number, options?: UpdateAddressOptions): Promise<api.Response<api.OrderDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      orderId
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateAddressOperation, parameters);
}

/**
 * Create product quotation request from form data
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function createProductQuote(options?: CreateProductQuoteOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(createProductQuoteOperation, parameters);
}

/**
 * Create product order from form data
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function createFormOrder(options?: CreateFormOrderOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(createFormOrderOperation, parameters);
}

/**
 * Get a single Order
 * 
 * @param {number} id 
 * @return {Promise<module:types.OrderDto>} OK
 */
export function one(id: number): Promise<api.Response<api.OrderDto>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(oneOperation, parameters);
}

export interface UpdateOrderSyncOptions {
  body?: {
    is_waiting_for_sonet?: boolean,
    mylly_error_count?: number,
    mylly_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
    mylly_error?: string,
    logia_wms_error_count?: number,
    logia_wms_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
    logia_wms_error?: string,
    products?: {
      uuid?: string,
      mylly_id?: string,
      mylly_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      logia_wms_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      approval_status?: 'Pending'|'Approved'|'Rejected',
      tracking_data?: {
        delivery_id?: string,
        company?: string,
        first_name?: string,
        last_name?: string,
        street_name?: string,
        zip_code?: string,
        city?: string,
        country?: string,
        phone?: string,
        email?: string,
        shipment_date?: string,
        received_date?: string,
        tracking_code?: string,
        tracking_url_fi?: string,
        tracking_url_en?: string
      }[]
    }[]
  };
}

export interface UpdateOrderStatusOptions {
  body?: {
    order?: 'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Waiting Sonet'|'Waiting Payment'|'Waiting Approval'|'Partially Delivered'
  };
}

export interface AllOptions {
  limit?: number;
  offset?: number;
  sort?: string;
  order?: 'ASC'|'DESC';
  sortLang?: string;
  startAt?: number;
  endAt?: number;
  id?: number;
}

export interface CreateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    items: {
      id: number,
      uuid: string,
      title?: string,
      work_title?: string,
      mylly_id?: string,
      mylly_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      logia_wms_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      cxml_status?: 'Sending'|'Sent'|'In Process'|'In Delivery'|'Completed'|'Cancelled'|'Partially Delivered',
      printfile?: {
        id: number,
        key: string,
        filename: string,
        url: string
      },
      productSource?: 'new-product'|'my-product'|'previous-order',
      addedToOrderAt?: number,
      price?: {
        starting_price?: string,
        packaging_price?: string,
        material_price?: string,
        printing_price?: string,
        finishing_price?: string,
        price_without_margin?: string,
        material_consumption?: string,
        discounted_price_without_tax: string,
        discounted_price: string,
        price_with_margin: string,
        tax_multiplier: string,
        price_with_tax: string,
        price_without_tax: string,
        tax_amount: string,
        additional_fee?: string,
        size?: {
          width: number,
          height: number
        }
      },
      options: {
        printfile_key?: string,
        selected_options?: any
      },
      product_flow_data?: {
        steps?: string[],
        selected_workflow?: {
          key?: string,
          steps?: string[]
        },
        scaled_proof_images?: string[],
        proof_image_thumbnail?: string,
        design_service?: any,
        is_design_validated?: boolean
      },
      product_storage?: {
        storage_type?: string,
        stock_level?: number
      },
      product?: api.Product,
      approval_comment?: string,
      approval_status?: 'Pending'|'Approved'|'Rejected',
      reviewed_at?: string,
      reviewed_by?: string
    }[],
    metadata: {
      shipping_method: string,
      billing_method: 'onlineBank'|'invoice',
      online_payment_method?: 'mobile'|'bank'|'creditcard'|'credit',
      total_price?: any,
      reference_code?: string,
      purchase_order_number?: string,
      order_message?: string,
      user_locale?: string,
      user_info: {
        first_name: string,
        last_name: string,
        email: string,
        phone?: string,
        company_name?: string,
        vat_number?: string,
        sonet_number?: string
      },
      user_defined_delivery_date?: string
    },
    addresses: {
      billing: any,
      shipping: any
    },
    discount_code?: string,
    access_token?: string,
    cxml_session_id?: number,
    store_id?: number
  };
}

export interface GetOrderOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface GetByStoreOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  order?: 'ASC'|'DESC';
  sortLang?: string;
  startAt?: number;
  endAt?: number;
}

export interface SearchAllOptions {
  limit?: number;
  offset?: number;
  sort?: string;
  order?: 'ASC'|'DESC';
  sortLang?: string;
  startAt?: number;
  endAt?: number;
}

export interface SearchByStoreOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  order?: 'ASC'|'DESC';
  sortLang?: string;
  startAt?: number;
  endAt?: number;
}

export interface GetMyOrdersOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface UpdateAddressOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    address?: any,
    is_shipping?: boolean,
    is_billing?: boolean
  };
}

export interface CreateProductQuoteOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    first_name: string,
    last_name: string,
    company?: string,
    attachment?: string,
    email: string,
    phone: string,
    message: string,
    product_id: number,
    store_id?: number
  };
}

export interface CreateFormOrderOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    first_name: string,
    last_name: string,
    company?: string,
    street_name: string,
    zip_code: string,
    city: string,
    email: string,
    phone: string,
    message?: string,
    product_id: number,
    store_id?: number
  };
}

const updateOrderSyncOperation: api.OperationInfo = {
  path: '/order/update-order-sync/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOrderStatusOperation: api.OperationInfo = {
  path: '/order/update-order-status/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/order',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/order',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getOrderOperation: api.OperationInfo = {
  path: '/order/by-token/{accessToken}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getByStoreOperation: api.OperationInfo = {
  path: '/order/by-store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const searchAllOperation: api.OperationInfo = {
  path: '/order/search/{searchTerm}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const searchByStoreOperation: api.OperationInfo = {
  path: '/order/search-by-store/{searchTerm}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getMyOrdersOperation: api.OperationInfo = {
  path: '/order/my-orders',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateAddressOperation: api.OperationInfo = {
  path: '/order/update-address/{orderId}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createProductQuoteOperation: api.OperationInfo = {
  path: '/order/by-form/quote',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createFormOrderOperation: api.OperationInfo = {
  path: '/order/by-form/order',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/order/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
