export const handleBeforeUnload = (event) => {
	event.returnValue = 'Are you sure you want to leave the page?';
};

export interface State {
	unsavedWork: Set<string>;
}

export const initialState: State = {
	unsavedWork: new Set(),
};

export const ADD_UNSAVED_WORK = 'mygrano/app/ADD_UNSAVED_WORK';
export const addUnsavedWork = (key) => ({ type: ADD_UNSAVED_WORK, payload: key });
export const REMOVE_UNSAVED_WORK = 'mygrano/app/REMOVE_UNSAVED_WORK';
export const removeUnsavedWork = (key) => ({ type: REMOVE_UNSAVED_WORK, payload: key });

export function reducer(state: State = initialState, action: any) {
	switch (action.type) {
		case ADD_UNSAVED_WORK:
			if (!state.unsavedWork.has(action.payload) && state.unsavedWork.size === 0)
				window.addEventListener('beforeunload', handleBeforeUnload);
			return { ...state, unsavedWork: new Set(state.unsavedWork).add(action.payload) };
		case REMOVE_UNSAVED_WORK: {
			if (state.unsavedWork.has(action.payload) && state.unsavedWork.size <= 1)
				window.removeEventListener('beforeunload', handleBeforeUnload);
			const unsavedWork = new Set(state.unsavedWork);
			unsavedWork.delete(action.payload);
			return { ...state, unsavedWork };
		}
		default:
			return state;
	}
}
