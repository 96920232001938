@if (faultCode$ | async) {
	<g-fault-page [faultCode]="faultCode$ | async" />
} @else {
	<ng-container *ngTemplateOutlet="appContainer" />
}

<ng-template #appContainer>
	<g-preview-banner data-cy="previewBanner" />
	@if ((showVignetteHeader$ | async) && (showContent$ | async) && (viewport.isMediumOrLarger$ | async)) {
		<g-vignette-header />
	}
	@if ((showHeader$ | async) && (showContent$ | async)) {
		<g-header />
	}

	<div class="page-content">
		@if (showNotificationBanner$ | async) {
			<g-custom-html-block [key]="'notification-banner'" />
		}

		<main [@routeFadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''">
			<router-outlet #outlet="outlet" (deactivate)="onDeactivate()" />
		</main>

		@if ((showCustomHtml$ | async) && (showContent$ | async)) {
			<g-custom-html-block [key]="'custom-html'" />
		}

		@if ((showFooter$ | async) && (showContent$ | async)) {
			<g-footer />
		}
	</div>

	@if (viewport.isSmallOrExtraSmall$ | async) {
		<g-mobile-navigation />
	}

	@if (showSpinner$ | async) {
		<g-loading-spinner [@spinnerFade] [fixed]="true" />
	}
</ng-template>
<g-translated-strings />
