// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/User */
// Auto-generated, edits will be overwritten
import * as User from '../User';

export const ME_START = 's/User/ME_START';
export const ME = 's/User/ME';
export type ME = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    emailVerified?: boolean,
    phoneNumberVerified?: boolean,
    enabled?: string,
    identifier?: string,
    isConfirmed?: boolean,
    isFederated?: boolean,
    userStatus?: string,
    groups?: {
      id?: number,
      name?: string
    }[],
    groupMetadata?: {
      defaultShowPricesWithTax?: boolean
    },
    permissions?: {
      access_approval_workflow_products: boolean,
      access_billing_step: boolean,
      access_shipping_step: boolean,
      access_storefront_admin: boolean,
      access_storefront_admin_prices: boolean,
      allow_billing_address_editing: boolean,
      allow_checkout: boolean,
      allow_download: boolean,
      allow_request_quote: boolean,
      allow_shipping_address_editing: boolean,
      show_internal_info: boolean,
      show_prices: boolean,
      show_staged_content: boolean,
      allow_my_products: boolean,
      allow_profile_editing: boolean
    }
  };

export function me(options?: User.MeOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ME_START, meta: { info, params: { options } } });
    return User.me(options)
      .then(response => dispatch({
        type: ME,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ANONYMOUS_USER_START = 's/User/ANONYMOUS_USER_START';
export const ANONYMOUS_USER = 's/User/ANONYMOUS_USER';
export type ANONYMOUS_USER = {[key: string]: any};

export function anonymousUser(options?: User.AnonymousUserOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ANONYMOUS_USER_START, meta: { info, params: { options } } });
    return User.anonymousUser(options)
      .then(response => dispatch({
        type: ANONYMOUS_USER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const BY_ID_LIST_START = 's/User/BY_ID_LIST_START';
export const BY_ID_LIST = 's/User/BY_ID_LIST';
export type BY_ID_LIST = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: any[],
    casCustomers?: any[],
    stores?: any[]
  }[];

export function byIdList(options?: User.ByIdListOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: BY_ID_LIST_START, meta: { info, params: { options } } });
    return User.byIdList(options)
      .then(response => dispatch({
        type: BY_ID_LIST,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_BY_CUSTOMER_ID_START = 's/User/GET_BY_CUSTOMER_ID_START';
export const GET_BY_CUSTOMER_ID = 's/User/GET_BY_CUSTOMER_ID';
export type GET_BY_CUSTOMER_ID = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    emailVerified?: boolean,
    phoneNumberVerified?: boolean,
    enabled?: string,
    identifier?: string,
    isConfirmed?: boolean,
    isFederated?: boolean,
    userStatus?: string,
    groups?: {
      id?: number,
      name?: string
    }[],
    stores?: any[],
    groupMetadata?: {
      defaultShowPricesWithTax?: boolean
    }
  };

export function getByCustomerId(id: string, customerId: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_BY_CUSTOMER_ID_START, meta: { info, params: { id, customerId } } });
    return User.getByCustomerId(id, customerId)
      .then(response => dispatch({
        type: GET_BY_CUSTOMER_ID,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/User/ALL_START';
export const ALL = 's/User/ALL';
export type ALL = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: any[],
    casCustomers?: any[],
    stores?: any[]
  }[];

export function all(options?: User.AllOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: { options } } });
    return User.all(options)
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/User/CREATE_START';
export const CREATE = 's/User/CREATE';
export type CREATE = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  };

export function create(options?: User.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return User.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const REGISTER_START = 's/User/REGISTER_START';
export const REGISTER = 's/User/REGISTER';
export type REGISTER = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  };

export function register(options?: User.RegisterOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: REGISTER_START, meta: { info, params: { options } } });
    return User.register(options)
      .then(response => dispatch({
        type: REGISTER,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_TO_CUSTOMER_USER_POOL_START = 's/User/CREATE_TO_CUSTOMER_USER_POOL_START';
export const CREATE_TO_CUSTOMER_USER_POOL = 's/User/CREATE_TO_CUSTOMER_USER_POOL';
export type CREATE_TO_CUSTOMER_USER_POOL = {
    id?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customer?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }
  };

export function createToCustomerUserPool(customerId: number, options?: User.CreateToCustomerUserPoolOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_TO_CUSTOMER_USER_POOL_START, meta: { info, params: { customerId, options } } });
    return User.createToCustomerUserPool(customerId, options)
      .then(response => dispatch({
        type: CREATE_TO_CUSTOMER_USER_POOL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/User/DESTROY_START';
export const DESTROY = 's/User/DESTROY';
export type DESTROY = undefined;

export function destroy(id: string, options?: User.DestroyOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { id, options } } });
    return User.destroy(id, options)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ONE_START = 's/User/ONE_START';
export const ONE = 's/User/ONE';
export type ONE = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    emailVerified?: boolean,
    phoneNumberVerified?: boolean,
    enabled?: string,
    identifier?: string,
    isConfirmed?: boolean,
    isFederated?: boolean,
    userStatus?: string,
    groups?: {
      id?: number,
      name?: string
    }[],
    stores?: any[],
    groupMetadata?: {
      defaultShowPricesWithTax?: boolean
    }
  };

export function one(id: string, options?: User.OneOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ONE_START, meta: { info, params: { id, options } } });
    return User.one(id, options)
      .then(response => dispatch({
        type: ONE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_BY_CUSTOMER_ID_START = 's/User/DESTROY_BY_CUSTOMER_ID_START';
export const DESTROY_BY_CUSTOMER_ID = 's/User/DESTROY_BY_CUSTOMER_ID';
export type DESTROY_BY_CUSTOMER_ID = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  };

export function destroyByCustomerId(id: string, customerId: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_BY_CUSTOMER_ID_START, meta: { info, params: { id, customerId } } });
    return User.destroyByCustomerId(id, customerId)
      .then(response => dispatch({
        type: DESTROY_BY_CUSTOMER_ID,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const LIST_USER_POOLS_START = 's/User/LIST_USER_POOLS_START';
export const LIST_USER_POOLS = 's/User/LIST_USER_POOLS';
export type LIST_USER_POOLS = {
    userPools: any[],
    paginationToken?: string
  };

export function listUserPools(limit: number, paginationToken: string, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: LIST_USER_POOLS_START, meta: { info, params: { limit, paginationToken } } });
    return User.listUserPools(limit, paginationToken)
      .then(response => dispatch({
        type: LIST_USER_POOLS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SEARCH_USERS_START = 's/User/SEARCH_USERS_START';
export const SEARCH_USERS = 's/User/SEARCH_USERS';
export type SEARCH_USERS = {
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: any[],
    casCustomers?: any[],
    stores?: any[]
  }[];

export function searchUsers(searchTerm: string, options?: User.SearchUsersOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SEARCH_USERS_START, meta: { info, params: { searchTerm, options } } });
    return User.searchUsers(searchTerm, options)
      .then(response => dispatch({
        type: SEARCH_USERS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const BATCH_IMPORT_START = 's/User/BATCH_IMPORT_START';
export const BATCH_IMPORT = 's/User/BATCH_IMPORT';
export type BATCH_IMPORT = {
    id?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyName?: string,
    vatNumber?: string,
    locale?: string,
    fullName?: string,
    createdAt?: string,
    updatedAt?: string,
    services?: {
      id?: number,
      identifier?: string,
      name?: string,
      customerId?: number,
      type?: string,
      metadata?: any,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[],
    customers?: {
      id?: number,
      name?: string,
      customerId?: string,
      cognitoUserPoolId?: string,
      cognitoUserPoolClientId?: string,
      cognitoRegion?: string
    }[]
  }[];

export function batchImport(options?: User.BatchImportOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: BATCH_IMPORT_START, meta: { info, params: { options } } });
    return User.batchImport(options)
      .then(response => dispatch({
        type: BATCH_IMPORT,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const RESEND_TEMPORARY_PASSWORD_START = 's/User/RESEND_TEMPORARY_PASSWORD_START';
export const RESEND_TEMPORARY_PASSWORD = 's/User/RESEND_TEMPORARY_PASSWORD';
export type RESEND_TEMPORARY_PASSWORD = {
    success?: boolean
  };

export function resendTemporaryPassword(options?: User.ResendTemporaryPasswordOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: RESEND_TEMPORARY_PASSWORD_START, meta: { info, params: { options } } });
    return User.resendTemporaryPassword(options)
      .then(response => dispatch({
        type: RESEND_TEMPORARY_PASSWORD,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const RESEND_CUSTOMER_TEMPORARY_PASSWORD_START = 's/User/RESEND_CUSTOMER_TEMPORARY_PASSWORD_START';
export const RESEND_CUSTOMER_TEMPORARY_PASSWORD = 's/User/RESEND_CUSTOMER_TEMPORARY_PASSWORD';
export type RESEND_CUSTOMER_TEMPORARY_PASSWORD = {
    success?: boolean
  };

export function resendCustomerTemporaryPassword(customerId: number, options?: User.ResendCustomerTemporaryPasswordOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: RESEND_CUSTOMER_TEMPORARY_PASSWORD_START, meta: { info, params: { customerId, options } } });
    return User.resendCustomerTemporaryPassword(customerId, options)
      .then(response => dispatch({
        type: RESEND_CUSTOMER_TEMPORARY_PASSWORD,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
