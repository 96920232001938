import { Component, Input } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { getContentByKey, CustomHtmlBlockContent } from '@app/core/reducers/content.reducer';
import { ApiSelector } from '@app/shared/utils/remote-data';

/** A component for rendering custom blocks of trusted HTML content. */
@Component({
	selector: 'g-custom-html-block',
	templateUrl: './custom-html-block.component.html',
	styleUrls: ['./custom-html-block.component.scss'],
})
export class CustomHtmlBlockComponent {
	content$: ApiSelector<CustomHtmlBlockContent> | undefined;
	constructor(private readonly store: Store<RootReducer.State>) {}
	/** Set the key for the custom HTML block. */
	@Input() set key(key: string) {
		this.content$ = this.store.select(getContentByKey<CustomHtmlBlockContent>(key));
	}
}
