import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

export interface ResponsiveImage {
	src: string;
	srcSet: string;
	sizes?: string;
}

/** Directive for responsive images. */
@Directive({ selector: 'img[gResponsiveSrc]', standalone: true })
export class ResponsiveImageDirective implements AfterViewInit {
	@Input() gResponsiveSrc?: ResponsiveImage;

	constructor(
		private readonly renderer: Renderer2,
		private readonly el: ElementRef<HTMLImageElement>,
	) {}

	/** Directive initialization. */
	ngAfterViewInit(): void {
		if (!this.gResponsiveSrc?.src || this.el.nativeElement.getAttribute('src-set')) return;
		this.renderer.setAttribute(this.el.nativeElement, 'src', this.gResponsiveSrc.src);
		this.renderer.setAttribute(this.el.nativeElement, 'srcset', this.gResponsiveSrc.srcSet);
		if (this.gResponsiveSrc.sizes)
			this.renderer.setAttribute(this.el.nativeElement, 'sizes', this.gResponsiveSrc.sizes);
	}
}
