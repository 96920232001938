// @ts-nocheck
/// <reference path="../types.d.ts"/>
// Auto-generated, edits will be overwritten
const spec: api.OpenApiSpec = {
  'host': 'api.granoshop.fi',
  'schemes': [
    'https'
  ],
  'basePath': '',
  'contentTypes': [
    'application/json'
  ],
  'accepts': [
    'application/json'
  ],
  'securityDefinitions': {
    'apiKeyAuth': {
      'type': 'apiKey',
      'name': 'Authorization',
      'in': 'header'
    }
  }
};
export default spec;
