import {
	FETCH_CONTENT,
	FETCH_CONTENT_START,
	FETCH_MULTIPLE_CONTENT,
	FETCH_MULTIPLE_CONTENT_START,
	FETCH_PAGES,
	FETCH_PAGES_START,
} from '@app/api/action/Content';
import { ActionObj, RootReducer } from '@app/app.reducers';
import { setName } from '@app/shared/utils/name-helper';
import { ApiState, Failure, Initialized, Pending, Success } from '@app/shared/utils/remote-data';
import { createSelector } from 'reselect';

export type ContentStatus = 'public' | 'private';

export interface Link {
	link: string;
	linkText: string;
}

export interface VignetteContent {
	links: Link[];
	pages: string[];
}

export interface MainMenuContent {
	links: Link[];
	pages: string[];
}

export interface FooterContent {
	footerStyle: string;
	showLogo: boolean;
	showDescription: boolean;
	showCategories: boolean;
	showPageLinks: boolean;
	showSomeLinks: boolean;
	description: string;
	someLinks: { someLink: string; someLinkText: string; someLinkIcon: string; someLinkUrl: string }[];
	pages: string[];
	html: string;
}

export interface CustomHtmlBlockContent {
	enable: boolean;
	html: string;
}

export interface NotificationBannerContent {
	enable: boolean;
	html: string;
}

export interface HomepageSeoContent {
	seo_title: string;
	seo_description: string;
}

export interface PageContent {
	type: 'page';
	key: string;
	html: string;
	title: string;
	seo_title?: string;
	seo_description?: string;
	images?: Record<string, string>;
	status: ContentStatus;
	linkedTo?: ContentResponse[];
}

export interface ContentResponse<T = unknown> {
	created_at: string;
	updated_at: string;
	locale: 'en' | 'fi' | 'sv';
	key: string;
	store_id: number;
	content: T;
	type: string;
	status: ContentStatus;
}

export interface PageResponse extends ContentResponse<PageContent> {
	type: 'page';
}

export interface State {
	vignette: ApiState<VignetteContent>;
	mainmenu: ApiState<MainMenuContent>;
	footer: ApiState<FooterContent>;
	contentByKey: Record<string, ApiState<unknown>>;
	pages: ApiState<PageResponse[]>;
}

const typedContentInitialState = {
	vignette: new Initialized(),
	mainmenu: new Initialized(),
	footer: new Initialized(),
};

export const initialState: State = {
	...typedContentInitialState,
	contentByKey: {},
	pages: new Initialized(),
};

const typedContentKeys = new Set(Object.keys(typedContentInitialState));
const isTypedContent = (key: string) => typedContentKeys.has(key);

function setContentState(
	state: State,
	content: Record<string, any>,
	contentKeys: string[] = Object.keys(content),
) {
	if (contentKeys.filter(Boolean).length === 0)
		throw new Error(`Invalid content keys for content: ${JSON.stringify(content)}`);
	return {
		...state,
		...contentKeys
			.filter(isTypedContent)
			.reduce((currentState, key) => ({ ...currentState, [key]: content[key] }), state),
		contentByKey: contentKeys.reduce(
			(currentState, key) => ({ ...currentState, [key]: content[key] }),
			state.contentByKey,
		),
	};
}

/** Content reducer */
export function reducer(state: State = initialState, action: ActionObj): State {
	switch (action.type) {
		case FETCH_CONTENT_START: {
			return setContentState(state, {
				[action?.meta?.params?.key ?? '']: new Pending(),
			});
		}
		case FETCH_CONTENT: {
			return setContentState(state, {
				// TODO: Use action.meta.info instead because response is missing for network errors.
				[(action?.meta?.res?.url ?? '').split('/').pop()!]: !action.error
					? new Success({
							...action.payload.content,
							type: action.payload.type,
							status: action.payload.status,
						})
					: new Failure(action.payload),
			});
		}
		case FETCH_MULTIPLE_CONTENT_START: {
			return setContentState(
				state,
				(action?.meta?.params?.options?.keys ?? '')
					.split(',')
					.reduce((o: any, key: string) => ({ ...o, [key]: new Pending() }), {}),
			);
		}
		case FETCH_MULTIPLE_CONTENT: {
			// If an error occurred, mark all pending content as failed.
			if (action.error) {
				return setContentState(
					state,
					Object.fromEntries(
						Object.entries({ ...state, ...state.contentByKey }).map(([key, val]) => [
							key,
							val instanceof Pending ? new Failure(action.payload) : val,
						]),
					),
				);
			}
			// If successful, update state.
			return setContentState(
				state,
				Object.keys(action.payload ?? {})
					.map(
						(key) =>
							[
								key,
								action.payload[key].content
									? new Success({
											...action.payload[key].content,
											type: action.payload[key].type,
											status: action.payload[key].status,
										})
									: new Failure(action.payload[key].message),
							] as const,
					)
					.reduce((o, [key, data]) => ({ ...o, [key]: data }), {}),
			);
		}
		case FETCH_PAGES_START: {
			return { ...state, pages: new Pending() };
		}
		case FETCH_PAGES: {
			return {
				...state,
				pages: !action.error ? new Success(action.payload) : new Failure(action.payload),
			};
		}
		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.content;
export const getVignetteContent = setName(
	'getVignetteContent',
	createSelector(getState, (state: State) => state.vignette),
);
export const getMainMenuContent = setName(
	'getMainMenuContent',
	createSelector(getState, (state: State) => state.mainmenu),
);
export const getFooterContent = setName(
	'getFooterContent',
	createSelector(getState, (state: State) => state.footer),
);
export const getPages = setName(
	'getPages',
	createSelector(getState, (state: State) => state.pages),
);
export const getContentByKey = <T = unknown>(key: string) =>
	setName(
		'getContentByKey',
		createSelector(
			getState,
			(state: State) => (state.contentByKey[key] as ApiState<T>) || new Initialized(),
		),
	);
export const getIsLoading = setName(
	'getIsLoading',
	createSelector(
		getState,
		// Return true if any main content is pending.
		(state: State) => [...typedContentKeys].some((key) => state[key as keyof State] instanceof Pending),
	),
);
export const getIsContentReceived = setName(
	'getIsContentReceived',
	createSelector(
		getState,
		// Return true if all main content is loaded
		(state: State) => [...typedContentKeys].every((key) => state[key as keyof State] instanceof Success),
	),
);
