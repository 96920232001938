@if (mode === 'full' || mode === 'user') {
	@if (user$ | async; as user) {
		<!-- User actions -->
		<button mat-button class="user menu-toggle" data-cy="userMenuButton" [matMenuTriggerFor]="userMenu">
			@if (!(isPunchout$ | async)) {
				<g-icon [icon]="icons.faUser" class="user-icon"></g-icon>
			} @else {
				<g-icon
					i18n-title="@@PunchOutSessionActiveIcon"
					title="Punchout session active"
					class="user-icon"
					[icon]="(productCount$ | async) > 0 ? icons.faPersonDolly : icons.faPersonDollyEmpty"
				></g-icon>
			}
			<span class="visually-hidden">User Actions, Current user: </span>
			<span class="user-text">{{ user?.firstName }} {{ user?.lastName }}</span>
			<g-icon [icon]="icons.faCaretDown" size="md"></g-icon>
		</button>
	} @else {
		<!-- Login button -->
		<button
			mat-button
			class="user login-button"
			data-cy="loginDialogButton"
			(click)="openLoginDialog()"
			[disabled]="!(isUserLoaded$ | async)"
		>
			<g-icon [icon]="icons.faUser" class="user-icon"></g-icon>
			@if (isUserLoaded$ | async) {
				<span i18n="@@LoginOrRegisterButton">Log in / Register</span>
			} @else {
				<span i18n="@@UserLoadingButtonDisabled">Logging in...</span>
			}
		</button>
	}
}
<!-- User actions menu -->
<mat-menu #userMenu="matMenu">
	@if (!(isPunchout$ | async)) {
		<button
			mat-menu-item
			data-cy="myProfileButton"
			type="button"
			role="menuitem"
			[routerLink]="['/user/profile']"
			i18n="@@UserActionAccount"
			>Account</button
		>
	}
	@if ((userPermissions$ | async)?.allow_my_products) {
		<button
			mat-menu-item
			data-cy="myProductButton"
			type="button"
			role="menuitem"
			[routerLink]="['/checkout/my-products']"
			i18n="@@UserActionMyProducts"
			>My Products</button
		>
	}
	@if (adminUrl$ | async; as adminUrl) {
		<a
			mat-menu-item
			data-cy="manageSite"
			role="menuitem"
			[attr.href]="adminUrl"
			i18n="@@UserActionManageSite"
			>Manage site</a
		>
	}
	@if (isApprovalAdmin$ | async) {
		<button
			mat-menu-item
			data-cy="approvals"
			type="button"
			role="menuitem"
			[routerLink]="['/admin/approvals']"
			i18n="@@UserActionApprovals"
			>Approvals</button
		>
	}
	<button
		mat-menu-item
		type="button"
		data-cy="logoutButton"
		role="menuitem"
		(click)="cognito.logout()"
		i18n="@@UserActionLogout"
		>Logout</button
	>
</mat-menu>
<!-- Cart icon -->
@if (mode === 'full' || mode === 'cart') {
	<a
		mat-button
		data-cy="shoppingCart"
		class="cart"
		i18n-title="@@GoToCartButton"
		title="Go to the shopping cart"
		[routerLink]="isNewLayout ? ['/checkout/v2/cart'] : ['/checkout/cart']"
	>
		<g-icon
			class="cart-icon"
			[icon]="icons.faCartShopping"
			[ngClass]="{ active: (productCount$ | async) > 0 }"
		></g-icon>
		<span class="visually-hidden" i18n="@@ScreenReaderGoToCartButton"
			>Go to the shopping cart, product count:
		</span>
		@if (productCount$ | async; as count) {
			<span class="cart-count-badge">{{ count }}</span>
		}
	</a>
}
