import type { EnvironmentConfig } from '../types/environment';

export const environment: EnvironmentConfig = {
	cdnUrl: 'https://app.mygrano.fi',
	enableStoreDevtools: false,
	production: true,
	envName: 'prod',
	logErrors: true,
	apiUrl: 'https://api.granoshop.fi',
	authUrl: '/do-auth/token',
	adminUrl: 'https://{{BASE_URL}}/admin/customers/{{CUSTOMER_ID}}/storefront/{{STORE_ID}}/settings',
	assetPath: '/assets',
	snackBarConfig: {
		durationWithoutAction: 5000,
		durationWithAction: 10_000,
		verticalPosition: 'top',
		horizontalPosition: 'center',
	},
	titleTemplate: '{{PAGE_TITLE}} - {{STORE_NAME}}',
	fileBucketUrl: 'files.granoshop.fi',
	antivirusBucket: 'viruscheck-granoshop',
	cognito: {
		userPoolId: 'eu-west-1_5O6XAsKKB',
		clientId: 'ik4u46nc9l33g2gpa4l7q014v',
		identityPoolId: 'eu-west-1:ef70f27c-7ac0-4097-ad50-4af488117766',
	},
	awsRegion: 'eu-west-1',
	awsIotEndpoint: 'a25hwxxy5ttvfl-ats.iot.eu-west-1.amazonaws.com', // spell-checker: disable-line
	customersCanvasSdkUrl: `https://editor.mygrano.fi/prod/resources/generated/iframeapi.js?v=${process.env.BUILD_TIMESTAMP}`,
	editorFileUploadUrl: `https://api.granoshop.fi/v2/customers-canvas/upload-editor-file`,
	sharedSsoHost: 'https://id.grano.fi',
	sharedSsoApi: 'https://api.id.grano.fi',
	maxReorderTimeInDays: 365,
	fileService: {
		endpoint: 'https://jzrugtgpm5ggnh2fuhqpy5luhu.appsync-api.eu-west-1.amazonaws.com/graphql',
		apiId: 'bzym5eki5zbrtj3p3sd7czqome',
		identityPoolId: 'eu-west-1:1a24c3f9-5a74-45d8-ae5e-668cafa8cb2a',
		userPoolId: 'eu-west-1_KLIfqCKZE',
		clientId: '13jkgim5cllpmf7cfj18kvb2m8',
	},
	featureFlags: {
		enableNewFileInfra: false,
		enableNewLayout: false,
	},
};
