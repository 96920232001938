import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input } from '@angular/core';

/** A component that displays a summary of an order. */
@Component({
	standalone: true,
	imports: [NgSwitch, NgSwitchCase, NgSwitchDefault],
	selector: 'g-order-status-switch',
	templateUrl: './order-status-switch.component.html',
	styleUrls: ['./order-status-switch.component.scss'],
})
export class OrderStatusSwitchComponent {
	@Input() orderStatus?: string;
	@Input() defaultSwitch?: string;
}
