import { AsyncPipe, PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Store, RootReducer } from '@app/app.reducers';
import {
	getShowPricesWithTax,
	setShowPricesWithTax,
} from '@app/checkout/modules/checkout-shared/reducers/checkout.reducer';
import { storeModel } from '@app/shared/utils/util';

/** Allows the user to select whether prices should be shown with or without tax. */
@Component({
	standalone: true,
	imports: [MatSlideToggleModule, PercentPipe, AsyncPipe, FormsModule],
	selector: 'g-vat-selector',
	templateUrl: './vat-selector.component.html',
	styleUrls: ['./vat-selector.component.scss'],
})
export class VatSelectorComponent {
	@Input() readonly taxMultiplier: string;
	readonly showPricesWithTax = storeModel(this.store, getShowPricesWithTax, setShowPricesWithTax);

	constructor(private readonly store: Store<RootReducer.State>) {}
}
