import { getToast } from '../reducers/toast.reducer';
import { type SnackBarConfig, SnackBarService } from './snackbar.service';
import { Injectable } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';

export interface ToastOptions {
	title: string;
	body?: string;
	type?: string;
	immediate?: boolean;
	icon?: IconDefinition;
	timeout?: number;
	action?: string;
	showCloseButton?: boolean;
}

const iconMap: Record<string, IconDefinition> = {
	error: faCircleExclamation,
	warning: faTriangleExclamation,
	success: faCheck,
	info: faCircleInfo,
};

/** Toast service. */
@Injectable({ providedIn: 'root' })
export class ToastService {
	constructor(
		private readonly store: Store<RootReducer.State>,
		private readonly snackBar: SnackBarService,
	) {}

	/** Initialize the toast service. */
	init(): void {
		this.store.select(getToast).subscribe((toast) => {
			if (toast?.type) this.popToast(toast);
			else this.clear();
		});
	}

	/** Pop a toast. */
	popToast(options: ToastOptions): void {
		const {
			title,
			body,
			type = 'info',
			immediate = type === 'error',
			timeout,
			icon,
			showCloseButton,
			action,
		} = options;
		const config: SnackBarConfig = {
			body,
			title,
			immediate,
			duration: timeout,
			action: action ?? (showCloseButton ? $localize`:@@DismissToast:Dismiss` : ''),
			class: ['toast', `toast-${type}`],
			icon: icon ?? iconMap[type],
		};
		this.snackBar.open(config);
	}

	/** Clear the toast. */
	clear(): void {
		this.snackBar.clear();
	}
}
