<form *ngIf="showLoginForm" id="login" novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()">
	<div class="form-group" [ngClass]="getStyleClass('group')">
		<div
			*ngIf="isTemporaryPasswordResent"
			class="temporary-password-notification"
			i18n="@@LoginTemporaryPasswordResent"
			>User account has expired! New temporary password has been sent to your email.</div
		>
		<label for="email" i18n="@@LoginLabelEmail">Email</label>
		<input
			data-cy="loginEmail"
			trim="blur"
			name="email"
			id="email"
			type="email"
			autocapitalize="none"
			autocorrect="off"
			class="form-control"
			formControlName="email"
			[ngClass]="getStyleClass()"
			gAutofocus
			autocomplete="work email"
		/>
		<div class="validation-error"> {{ formErrors$ | async | validationField: 'email' }} </div>
		<label for="password" i18n="@@LoginLabelPassword">Password</label>
		<input
			data-cy="loginPassword"
			name="password"
			id="password"
			type="password"
			class="form-control"
			formControlName="password"
			[ngClass]="getStyleClass()"
			autocomplete="current-password"
		/>
		<div class="validation-error">
			{{ formErrors$ | async | validationField: 'password' }}
		</div>
		<button
			type="submit"
			id="submitButton"
			class="btn btn-primary"
			[matTooltip]="(submitTooltip$ | async) ?? ''"
			[matTooltipDisabled]="(submitTooltip$ | async) === null"
			matTooltipClass="tooltip"
			[disabled]="!loginForm.valid || submitDisabled"
		>
			@if ((isAuthenticating$ | async) === false) {
				<span>{{ submitText }}</span>
				@if (!!submitIcon) {
					<g-icon class="submit-icon" [icon]="submitIcon" fixedWidth />
				}
			} @else {
				<g-icon [icon]="icons.faSpinner" size="2x" pulse />
			}
		</button>
	</div>
</form>
<div class="federated-logins" *ngIf="!showLoginForm && federatedLoginUrls.length > 0">
	<a
		class="btn btn-primary btn-federated-login"
		*ngFor="let federatedLogin of federatedLoginUrls"
		[attr.href]="!(showSsoAuthLoading$ | async) ? federatedLogin.url : null"
	>
		<g-icon
			*ngIf="(showSsoAuthLoading$ | async) && (ssoStateIcon$ | async) as icon"
			[icon]="icon"
			size="2x"
			[attr.pulse]="icon === icons.faSpinner"
		/>

		<span *ngIf="!(showSsoAuthLoading$ | async)">{{ federatedLogin.name }}</span>
	</a>
	<div *ngIf="ssoAuthenticationError$ | async">
		<span i18n="@@LoginSsoError">Login failed!</span> ({{ ssoAuthenticationError$ | async }})
	</div>
</div>
<div *ngIf="federatedLoginUrls.length > 0">
	<a
		class="login-form-toggle"
		tabindex="0"
		(click)="toggleLoginForm($event)"
		(keyup.enter)="toggleLoginForm($event)"
	>
		<span i18n="@@LoginUsingMyGranoAccount" *ngIf="!showLoginForm">Login using My Grano account</span>
		<span i18n="@@LoginUsingSso" *ngIf="showLoginForm">Login using single sign-on</span>
	</a>
</div>
<div class="buttons" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center" *ngIf="showLoginForm">
	<button
		type="button"
		class="btn btn-tertiary"
		(click)="forgot()"
		(keyup.enter)="forgot()"
		fxFlex="50"
		i18n="@@LoginForgotPassword"
		>Forgot password?</button
	>
	<button
		type="button"
		class="btn btn-tertiary"
		(click)="register()"
		fxFlex="50"
		i18n="@@LoginCreateAccount"
		*ngIf="isRegistrationAllowed$ | async"
		>Create account</button
	>
</div>
