// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Store */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get Store details from database by given Url
 * 
 * @param {string} baseUrl 
 * @param {object} options Optional options
 * @param {string} [options.locale] Enum: en, fi, sv. 
 * @return {Promise<object>} Details of the requested store
 */
export function baseUrl(baseUrl: string, options?: BaseUrlOptions): Promise<api.Response<{
    id?: number,
    is_public?: boolean,
    base_url?: string,
    pim_encrypted_password?: string,
    paytrail_merchant_secret_encrypted?: string,
    cxml_encrypted_shared_secret?: string,
    google_tag_manager_id?: string,
    images?: any,
    emmi_import?: {
      emmi_imported_at?: string,
      emmi_import_started_at?: string,
      emmi_import_reason?: {
        status?: 'pending'|'error'|'success',
        result?: {
          categories?: {
            id?: number,
            path?: string,
            slug?: string,
            pim_id?: string
          }[],
          products?: {
            id?: number,
            sku?: string,
            pim_id?: string
          }[],
          productProperties?: {
            id?: number,
            key?: string,
            pim_field_id?: string,
            type?: string
          }[],
          invalidFolders?: any[],
          invalidAssets?: any[],
          invalidProperties?: any[],
          esError?: string,
          error?: string
        }
      }
    },
    locale?: {
      name?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      description?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      custom_features?: {
        en?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        },
        fi?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        },
        sv?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        }
      },
      title?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      order_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      purchase_order_number_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      invoice_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      online_payment_description?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      invoice_description?: {
        en?: string,
        fi?: string,
        sv?: string
      }
    },
    customer_id: number,
    storefront?: {
      address_field_schema?: {
        billing?: {
          type?: string,
          properties: any,
          required?: string[]
        },
        shipping?: {
          type?: string,
          properties?: any,
          required?: string[]
        }
      },
      preview_token?: string,
      login_url?: string,
      logout_url?: string,
      primary_color?: string,
      primary_text_color?: string,
      homepage?: string,
      language?: {
        finnish?: boolean,
        swedish?: boolean,
        english?: boolean,
        default?: 'finnish'|'swedish'|'english'
      },
      email_translations?: {
        fi?: any,
        en?: any,
        sv?: any
      },
      enable_custom_email_translations?: boolean,
      custom_user_schema?: {
        '$schema'?: string,
        type?: string,
        properties: any,
        order?: string[],
        required?: string[]
      },
      user_email_regex?: string,
      order_message_pattern?: string,
      reference_code_pattern?: string,
      default_reference_code?: string,
      default_purchase_order_number?: string,
      purchase_order_number_pattern?: string,
      product_menu?: {
        type: 'default'|'mega',
        style?: 'small'|'medium'|'large',
        root_category_amount?: number,
        child_category_amount?: number
      },
      allow_registration?: boolean,
      allow_company_account_registration?: boolean,
      allow_anonymous_browsing?: boolean,
      allow_online_payment?: boolean,
      allow_invoice?: boolean,
      allow_invoice_without_vat_number?: boolean,
      default_billing_address_id?: number,
      default_shipping_address_id?: number,
      default_product_view?: 'thumbnails'|'list',
      shipping_countries?: {
        allowed?: string[],
        allow_all?: boolean
      },
      show_zero_price_lines?: boolean,
      show_billing_methods?: boolean,
      show_shipping_methods?: boolean,
      show_invoice_instructions?: boolean,
      show_purchase_order_number_instructions?: boolean,
      show_order_message?: boolean,
      show_voucher?: boolean,
      show_terms_checkbox?: boolean,
      show_user_profile_terms_checkbox?: boolean,
      enable_cxml_order_request_handling?: boolean,
      enable_cxml_order_shipping_address?: boolean,
      cxml_order_request_reference_code?: string,
      cxml_order_request_purchase_order_number?: string,
      cxml_punchout_fallback_user?: string,
      enable_energy_and_service_fee?: boolean,
      paytrail_integration_version?: number,
      cxml_price_validation_margin?: number,
      enable_cxml_shipping_price_validation?: boolean
    },
    csp_nonce?: string,
    csp?: {
      url: string,
      types: string[],
      isEnabled?: boolean,
      isFixed?: boolean
    }[],
    csp_report_only?: {
      url: string,
      types: string[],
      isEnabled?: boolean,
      isFixed?: boolean
    }[],
    cognito_config?: {
      user_pool_id?: string,
      client_id?: string,
      region?: string,
      fallbacks?: any,
      federation?: {
        userpool_domain: string,
        identity_providers: string[],
        redirect_sign_in: {
          en?: string,
          fi?: string,
          sv?: string
        },
        redirect_sign_out: {
          en?: string,
          fi?: string,
          sv?: string
        },
        login_urls: {
          name: {
            en?: string,
            fi?: string,
            sv?: string
          },
          url: {
            en?: string,
            fi?: string,
            sv?: string
          }
        }[],
        is_default_login_method: boolean
      }
    },
    use_shared_auth?: boolean,
    cas_customer_slug?: string,
    content?: any
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      baseUrl
    },
    query: {
      locale: options.locale
    }
  };
  return gateway.request(baseUrlOperation, parameters);
}

/**
 * Create new Store in database and CAS.
 * 
 * @param {object} options Optional options
 * @param {module:types.Store} [options.body] 
 * @return {Promise<module:types.Store>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.Store>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Get list of Stores from database.
 */
export function all(): Promise<api.Response<api.Store[]>> {
  return gateway.request(allOperation);
}

/**
 * Destroy Storefront from database and CAS.
 * 
 * @param {number} id 
 * @return {Promise<module:types.Store>} OK
 */
export function destroy(id: number): Promise<api.Response<api.Store>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

/**
 * Update Storefront in database and CAS.
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function update(id: number, options?: UpdateOptions): Promise<api.Response<any>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOperation, parameters);
}

/**
 * Fetch Storefront from database and CAS.
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {boolean} [options.withImportDetails] Get emmi import data products if true
 * @return {Promise<object>} Details of the requested store
 */
export function one(id: number, options?: OneOptions): Promise<api.Response<{
    id?: number,
    deleted_at?: string,
    is_public?: boolean,
    base_url?: string,
    pim_api_url?: string,
    pim_folder?: string,
    pim_username?: string,
    pim_encrypted_password?: string,
    paytrail_merchant_id?: string,
    paytrail_merchant_secret_encrypted?: string,
    cxml_identity?: string,
    cxml_encrypted_shared_secret?: string,
    logia_client_id?: string,
    cognito_config?: {
      user_pool_id?: string,
      client_id?: string,
      region?: string,
      fallbacks?: any,
      federation?: {
        userpool_domain: string,
        identity_providers: string[],
        redirect_sign_in: {
          en?: string,
          fi?: string,
          sv?: string
        },
        redirect_sign_out: {
          en?: string,
          fi?: string,
          sv?: string
        },
        login_urls: {
          name: {
            en?: string,
            fi?: string,
            sv?: string
          },
          url: {
            en?: string,
            fi?: string,
            sv?: string
          }
        }[],
        is_default_login_method: boolean
      }
    },
    google_tag_manager_id?: string,
    images?: any,
    storefront?: {
      address_field_schema?: {
        billing?: {
          type?: string,
          properties: any,
          required?: string[]
        },
        shipping?: {
          type?: string,
          properties?: any,
          required?: string[]
        }
      },
      preview_token?: string,
      login_url?: string,
      logout_url?: string,
      logo_key?: string,
      mobile_logo_key?: string,
      default_share_image_key?: string,
      primary_color?: string,
      primary_text_color?: string,
      homepage?: string,
      language?: {
        finnish?: boolean,
        swedish?: boolean,
        english?: boolean,
        default?: 'finnish'|'swedish'|'english'
      },
      email_templates?: {
        locale?: {
          fi?: {
            registration?: {
              subject?: string,
              template_id?: string
            },
            password_reset?: {
              subject?: string,
              template_id?: string
            },
            order_confirmation?: {
              subject?: string,
              template_id?: string
            },
            emailAddress?: string,
            signature?: string
          },
          en?: {
            registration?: {
              subject?: string,
              template_id?: string
            },
            password_reset?: {
              subject?: string,
              template_id?: string
            },
            order_confirmation?: {
              subject?: string,
              template_id?: string
            },
            emailAddress?: string,
            signature?: string
          }
        }
      },
      email_order_confirmation_config?: any,
      email_configurations?: {
        order_confirmation?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_confirmation_with_approval?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        approval_notification?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_notification?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_notification_with_all_products?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        approval_completed?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        form_product_order?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        form_product_quote?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        stock_level_warning?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        sonet_notification?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        sonet_error?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_synchronizer_error?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        }
      },
      email_translations?: {
        fi?: any,
        en?: any,
        sv?: any
      },
      enable_custom_email_translations?: boolean,
      custom_user_schema?: {
        '$schema'?: string,
        type?: string,
        properties: any,
        order?: string[],
        required?: string[]
      },
      user_email_regex?: string,
      order_message_pattern?: string,
      reference_code_pattern?: string,
      default_reference_code?: string,
      default_purchase_order_number?: string,
      purchase_order_number_pattern?: string,
      content_security_policies?: {
        url: string,
        types: string[],
        isEnabled?: boolean,
        isFixed?: boolean
      }[],
      content_security_policies_report_only?: {
        url: string,
        types: string[],
        isEnabled?: boolean,
        isFixed?: boolean
      }[],
      product_menu?: {
        type: 'default'|'mega',
        style?: 'small'|'medium'|'large',
        root_category_amount?: number,
        child_category_amount?: number
      },
      allow_registration?: boolean,
      allow_company_account_registration?: boolean,
      allow_anonymous_browsing?: boolean,
      anonymous_user_group?: string,
      allow_online_payment?: boolean,
      allow_invoice?: boolean,
      allow_invoice_without_vat_number?: boolean,
      default_billing_address_id?: number,
      default_shipping_address_id?: number,
      enable_order_synchronizer?: boolean,
      default_product_view?: 'thumbnails'|'list',
      shipping_countries?: {
        allowed?: string[],
        allow_all?: boolean
      },
      show_zero_price_lines?: boolean,
      show_billing_methods?: boolean,
      show_shipping_methods?: boolean,
      show_invoice_instructions?: boolean,
      show_purchase_order_number_instructions?: boolean,
      show_order_message?: boolean,
      show_voucher?: boolean,
      show_terms_checkbox?: boolean,
      show_user_profile_terms_checkbox?: boolean,
      enable_cxml_punchout?: boolean,
      enable_cxml_order_request_handling?: boolean,
      enable_cxml_order_shipping_address?: boolean,
      cxml_order_request_reference_code?: string,
      cxml_order_request_purchase_order_number?: string,
      cxml_punchout_fallback_user?: string,
      use_default_mylly_payer?: boolean,
      mylly_payer_sonet_number?: string,
      mylly_payer_company_name?: string,
      emmi_non_meta_group_ids?: string[],
      emmi_storage_info_group_ids?: string[],
      emmi_fallback_metadata_group_ids?: string[],
      emmi_value_limit_group_ids?: string[],
      enable_carbon_neutral_compensation?: boolean,
      enable_energy_and_service_fee?: boolean,
      paytrail_integration_version?: number,
      cxml_price_validation_margin?: number,
      enable_cxml_shipping_price_validation?: boolean
    },
    emmi_import?: {
      emmi_imported_at?: string,
      emmi_import_started_at?: string,
      emmi_import_reason?: {
        status?: 'pending'|'error'|'success',
        result?: {
          categories?: {
            id?: number,
            path?: string,
            slug?: string,
            pim_id?: string
          }[],
          products?: {
            id?: number,
            sku?: string,
            pim_id?: string
          }[],
          productProperties?: {
            id?: number,
            key?: string,
            pim_field_id?: string,
            type?: string
          }[],
          invalidFolders?: any[],
          invalidAssets?: any[],
          invalidProperties?: any[],
          esError?: string,
          error?: string
        }
      }
    },
    locale?: {
      name?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      description?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      custom_features?: {
        en?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        },
        fi?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        },
        sv?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        }
      },
      title?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      order_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      purchase_order_number_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      invoice_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      online_payment_description?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      invoice_description?: {
        en?: string,
        fi?: string,
        sv?: string
      }
    },
    customer?: {
      id?: number,
      name: string,
      customer_id: string,
      salesperson_sonet_id?: string,
      sales_specialist_sonet_id?: string,
      contact_sonet_id?: string,
      description?: string,
      contact_person_name?: string,
      contact_person_email?: string,
      contact_person_phone?: string,
      country?: string,
      street_address?: string,
      zip_code?: string,
      city?: string,
      cognito_config?: {
        user_pool_id?: string,
        client_id?: string,
        region?: string,
        fallbacks?: any,
        federation?: {
          userpool_domain: string,
          identity_providers: string[],
          redirect_sign_in: {
            en?: string,
            fi?: string,
            sv?: string
          },
          redirect_sign_out: {
            en?: string,
            fi?: string,
            sv?: string
          },
          login_urls: {
            name: {
              en?: string,
              fi?: string,
              sv?: string
            },
            url: {
              en?: string,
              fi?: string,
              sv?: string
            }
          }[],
          is_default_login_method: boolean
        }
      },
      stores?: api.Store[],
      policies?: {
        Version: string,
        Statement?: {
          Sid: string,
          Effect: 'Allow'|'Deny',
          Action: string[],
          Resource: string[],
          Condition?: any
        }[]
      }[],
      created_at?: string,
      updated_at?: string
    },
    customer_id: number,
    categories?: api.Category[],
    policies?: {
      Version: string,
      Statement: {
        Sid: string,
        Effect: 'Allow'|'Deny',
        Action: string[],
        Resource: string[],
        Condition?: any
      }[]
    }[],
    created_at?: string,
    updated_at?: string,
    csp_nonce?: string,
    csp?: {
      url: string,
      types: string[],
      isEnabled?: boolean,
      isFixed?: boolean
    }[],
    csp_report_only?: {
      url: string,
      types: string[],
      isEnabled?: boolean,
      isFixed?: boolean
    }[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    path: {
      id
    },
    query: {
      withImportDetails: options.withImportDetails
    }
  };
  return gateway.request(oneOperation, parameters);
}

/**
 * Get Store model schema describing the database table.
 */
export function schema(): Promise<api.Response<api.Store>> {
  return gateway.request(schemaOperation);
}

/**
 * Get all stores, supports filtering and pagination
 * 
 * @param {object} options Optional options
 * @param {string} [options.filters] Filter expression for filtering (see https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering)
 * @param {number} [options.page] Page number for pagination. First page is 0.
 * @param {number} [options.pagesize] Page size for pagination. Ignored when used without the page parameter.
 * @param {string} [options.orderby] Sort expression for ordering (see https://js.devexpress.com/Documentation/18_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/sorting/)
 * @return {Promise<module:types.PaginatedStoresDto>} 
 */
export function getMany(options?: GetManyOptions): Promise<api.Response<api.PaginatedStoresDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    query: {
      filters: options.filters,
      page: options.page,
      pagesize: options.pagesize,
      orderby: options.orderby
    }
  };
  return gateway.request(getManyOperation, parameters);
}

export interface BaseUrlOptions {
  locale?: 'en'|'fi'|'sv';
}

export interface CreateOptions {
  body?: api.Store;
}

export interface UpdateOptions {
  body?: {
    id?: number,
    deleted_at?: string,
    is_public?: boolean,
    base_url?: string,
    pim_api_url?: string,
    pim_folder?: string,
    pim_username?: string,
    pim_encrypted_password?: string,
    paytrail_merchant_id?: string,
    paytrail_merchant_secret_encrypted?: string,
    cxml_identity?: string,
    cxml_encrypted_shared_secret?: string,
    logia_client_id?: string,
    cognito_config?: {
      user_pool_id?: string,
      client_id?: string,
      region?: string,
      fallbacks?: any,
      federation?: {
        userpool_domain: string,
        identity_providers: string[],
        redirect_sign_in: {
          en?: string,
          fi?: string,
          sv?: string
        },
        redirect_sign_out: {
          en?: string,
          fi?: string,
          sv?: string
        },
        login_urls: {
          name: {
            en?: string,
            fi?: string,
            sv?: string
          },
          url: {
            en?: string,
            fi?: string,
            sv?: string
          }
        }[],
        is_default_login_method: boolean
      }
    },
    google_tag_manager_id?: string,
    images?: any,
    storefront?: {
      address_field_schema?: {
        billing?: {
          type?: string,
          properties: any,
          required?: string[]
        },
        shipping?: {
          type?: string,
          properties?: any,
          required?: string[]
        }
      },
      preview_token?: string,
      login_url?: string,
      logout_url?: string,
      logo_key?: string,
      mobile_logo_key?: string,
      default_share_image_key?: string,
      primary_color?: string,
      primary_text_color?: string,
      homepage?: string,
      language?: {
        finnish?: boolean,
        swedish?: boolean,
        english?: boolean,
        default?: 'finnish'|'swedish'|'english'
      },
      email_templates?: {
        locale?: {
          fi?: {
            registration?: {
              subject?: string,
              template_id?: string
            },
            password_reset?: {
              subject?: string,
              template_id?: string
            },
            order_confirmation?: {
              subject?: string,
              template_id?: string
            },
            emailAddress?: string,
            signature?: string
          },
          en?: {
            registration?: {
              subject?: string,
              template_id?: string
            },
            password_reset?: {
              subject?: string,
              template_id?: string
            },
            order_confirmation?: {
              subject?: string,
              template_id?: string
            },
            emailAddress?: string,
            signature?: string
          }
        }
      },
      email_order_confirmation_config?: any,
      email_configurations?: {
        order_confirmation?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_confirmation_with_approval?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        approval_notification?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_notification?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_notification_with_all_products?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        approval_completed?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        form_product_order?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        form_product_quote?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        stock_level_warning?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        sonet_notification?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        sonet_error?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        },
        order_synchronizer_error?: {
          template?: string,
          options?: {
            messageBody?: string,
            showPrices?: boolean,
            showAllProducts?: boolean
          }
        }
      },
      email_translations?: {
        fi?: any,
        en?: any,
        sv?: any
      },
      enable_custom_email_translations?: boolean,
      custom_user_schema?: {
        '$schema'?: string,
        type?: string,
        properties: any,
        order?: string[],
        required?: string[]
      },
      user_email_regex?: string,
      order_message_pattern?: string,
      reference_code_pattern?: string,
      default_reference_code?: string,
      default_purchase_order_number?: string,
      purchase_order_number_pattern?: string,
      content_security_policies?: {
        url: string,
        types: string[],
        isEnabled?: boolean,
        isFixed?: boolean
      }[],
      content_security_policies_report_only?: {
        url: string,
        types: string[],
        isEnabled?: boolean,
        isFixed?: boolean
      }[],
      product_menu?: {
        type: 'default'|'mega',
        style?: 'small'|'medium'|'large',
        root_category_amount?: number,
        child_category_amount?: number
      },
      allow_registration?: boolean,
      allow_company_account_registration?: boolean,
      allow_anonymous_browsing?: boolean,
      anonymous_user_group?: string,
      allow_online_payment?: boolean,
      allow_invoice?: boolean,
      allow_invoice_without_vat_number?: boolean,
      default_billing_address_id?: number,
      default_shipping_address_id?: number,
      enable_order_synchronizer?: boolean,
      default_product_view?: 'thumbnails'|'list',
      shipping_countries?: {
        allowed?: string[],
        allow_all?: boolean
      },
      show_zero_price_lines?: boolean,
      show_billing_methods?: boolean,
      show_shipping_methods?: boolean,
      show_invoice_instructions?: boolean,
      show_purchase_order_number_instructions?: boolean,
      show_order_message?: boolean,
      show_voucher?: boolean,
      show_terms_checkbox?: boolean,
      show_user_profile_terms_checkbox?: boolean,
      enable_cxml_punchout?: boolean,
      enable_cxml_order_request_handling?: boolean,
      enable_cxml_order_shipping_address?: boolean,
      cxml_order_request_reference_code?: string,
      cxml_order_request_purchase_order_number?: string,
      cxml_punchout_fallback_user?: string,
      use_default_mylly_payer?: boolean,
      mylly_payer_sonet_number?: string,
      mylly_payer_company_name?: string,
      emmi_non_meta_group_ids?: string[],
      emmi_storage_info_group_ids?: string[],
      emmi_fallback_metadata_group_ids?: string[],
      emmi_value_limit_group_ids?: string[],
      enable_carbon_neutral_compensation?: boolean,
      enable_energy_and_service_fee?: boolean,
      paytrail_integration_version?: number,
      cxml_price_validation_margin?: number,
      enable_cxml_shipping_price_validation?: boolean
    },
    emmi_import?: {
      emmi_imported_at?: string,
      emmi_import_started_at?: string,
      emmi_import_reason?: {
        status?: 'pending'|'error'|'success',
        result?: {
          categories?: {
            id?: number,
            path?: string,
            slug?: string,
            pim_id?: string
          }[],
          products?: {
            id?: number,
            sku?: string,
            pim_id?: string
          }[],
          productProperties?: {
            id?: number,
            key?: string,
            pim_field_id?: string,
            type?: string
          }[],
          invalidFolders?: any[],
          invalidAssets?: any[],
          invalidProperties?: any[],
          esError?: string,
          error?: string
        }
      }
    },
    locale?: {
      name?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      description?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      custom_features?: {
        en?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        },
        fi?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        },
        sv?: {
          show_support_link?: boolean,
          show_material_instructions_link?: boolean,
          support_link?: string,
          material_instructions_link?: string
        }
      },
      title?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      order_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      purchase_order_number_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      invoice_message?: {
        title?: {
          en?: string,
          fi?: string,
          sv?: string
        },
        instructions?: {
          en?: string,
          fi?: string,
          sv?: string
        }
      },
      online_payment_description?: {
        en?: string,
        fi?: string,
        sv?: string
      },
      invoice_description?: {
        en?: string,
        fi?: string,
        sv?: string
      }
    },
    customer?: api.Customer,
    customer_id?: number,
    categories?: api.Category[],
    policies?: {
      Version: string,
      Statement: {
        Sid: string,
        Effect: 'Allow'|'Deny',
        Action: string[],
        Resource: string[],
        Condition?: any
      }[]
    }[],
    created_at?: string,
    updated_at?: string
  };
}

export interface OneOptions {
  /**
   * Get emmi import data products if true
   */
  withImportDetails?: boolean;
}

export interface GetManyOptions {
  /**
   * Filter expression for filtering (see https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering)
   */
  filters?: string;
  /**
   * Page number for pagination. First page is 0.
   */
  page?: number;
  /**
   * Page size for pagination. Ignored when used without the page parameter.
   */
  pagesize?: number;
  /**
   * Sort expression for ordering (see https://js.devexpress.com/Documentation/18_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/sorting/)
   */
  orderby?: string;
}

const baseUrlOperation: api.OperationInfo = {
  path: '/store/details/{baseUrl}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/store',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/store/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOperation: api.OperationInfo = {
  path: '/store/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/store/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const schemaOperation: api.OperationInfo = {
  path: '/store/schema',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getManyOperation: api.OperationInfo = {
  path: '/v2/store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
