import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import type zxcvbn from 'zxcvbn';

@Component({
	selector: 'g-password-strength',
	templateUrl: './password-strength.component.html',
	styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
	@Input() password: string;
	@Input() validatorControl: AbstractControl;
	@Output() readonly change = new EventEmitter<any>();
	score: number;
	private zxcvbn: (password: string, userInputs?: string[]) => zxcvbn.ZXCVBNResult;

	async ngOnInit(): Promise<void> {
		this.score = 0;
		this.password = this.password || '';
		// istanbul ignore next - dynamic import mocked
		if (!this.zxcvbn) this.zxcvbn = (await import('zxcvbn')).default;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (typeof changes.password !== 'object') return;
		if (changes.password.firstChange) return;
		this.updateScore(changes.password.currentValue as string);
	}

	private updateScore(password: string) {
		if (typeof this.zxcvbn !== 'function') return;
		const result = this.zxcvbn(password);
		if (!result || !Number.isFinite(result.score)) throw new Error('zxcvbn failed');
		this.score = result.score;
		this.change.emit(result);
		if (this.validatorControl) this.validatorControl.patchValue(`${this.score}`);
	}
}
