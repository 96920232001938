// @ts-nocheck
/// <reference path="../types.d.ts"/>
/** @module action/Product */
// Auto-generated, edits will be overwritten
import * as Product from '../Product';

export const GET_PRODUCTS_START = 's/Product/GET_PRODUCTS_START';
export const GET_PRODUCTS = 's/Product/GET_PRODUCTS';
export type GET_PRODUCTS = api.ProductDto[];

export function getProducts(options?: Product.GetProductsOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRODUCTS_START, meta: { info, params: { options } } });
    return Product.getProducts(options)
      .then(response => dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRODUCTS_BY_ID_LIST_START = 's/Product/GET_PRODUCTS_BY_ID_LIST_START';
export const GET_PRODUCTS_BY_ID_LIST = 's/Product/GET_PRODUCTS_BY_ID_LIST';
export type GET_PRODUCTS_BY_ID_LIST = {
    models?: api.ProductDto[]
  };

export function getProductsByIdList(productIds: string, options?: Product.GetProductsByIdListOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRODUCTS_BY_ID_LIST_START, meta: { info, params: { productIds, options } } });
    return Product.getProductsByIdList(productIds, options)
      .then(response => dispatch({
        type: GET_PRODUCTS_BY_ID_LIST,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRODUCTS_BY_CATEGORY_START = 's/Product/GET_PRODUCTS_BY_CATEGORY_START';
export const GET_PRODUCTS_BY_CATEGORY = 's/Product/GET_PRODUCTS_BY_CATEGORY';
export type GET_PRODUCTS_BY_CATEGORY = {
    models?: api.ProductDto[]
  };

export function getProductsByCategory(categoryIds: string, options?: Product.GetProductsByCategoryOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRODUCTS_BY_CATEGORY_START, meta: { info, params: { categoryIds, options } } });
    return Product.getProductsByCategory(categoryIds, options)
      .then(response => dispatch({
        type: GET_PRODUCTS_BY_CATEGORY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRODUCT_INVENTORY_BY_ID_LIST_START = 's/Product/GET_PRODUCT_INVENTORY_BY_ID_LIST_START';
export const GET_PRODUCT_INVENTORY_BY_ID_LIST = 's/Product/GET_PRODUCT_INVENTORY_BY_ID_LIST';
export type GET_PRODUCT_INVENTORY_BY_ID_LIST = {
    id?: number,
    product?: api.ProductDtoDto,
    stock_level?: number,
    storage_warning_level?: number,
    storage_type?: string,
    storage_warning_level_emails?: string,
    warning_email_sent_at?: string,
    reserved_quantity?: number
  }[];

export function getProductInventoryByIdList(productIds: string, options?: Product.GetProductInventoryByIdListOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRODUCT_INVENTORY_BY_ID_LIST_START, meta: { info, params: { productIds, options } } });
    return Product.getProductInventoryByIdList(productIds, options)
      .then(response => dispatch({
        type: GET_PRODUCT_INVENTORY_BY_ID_LIST,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_PRODUCT_INVENTORY_START = 's/Product/UPDATE_PRODUCT_INVENTORY_START';
export const UPDATE_PRODUCT_INVENTORY = 's/Product/UPDATE_PRODUCT_INVENTORY';
export type UPDATE_PRODUCT_INVENTORY = {
    success?: boolean
  };

export function updateProductInventory(id: number, options?: Product.UpdateProductInventoryOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_PRODUCT_INVENTORY_START, meta: { info, params: { id, options } } });
    return Product.updateProductInventory(id, options)
      .then(response => dispatch({
        type: UPDATE_PRODUCT_INVENTORY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ONE_START = 's/Product/ONE_START';
export const ONE = 's/Product/ONE';
export type ONE = api.ProductDto;

export function one(id: number, options?: Product.OneOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ONE_START, meta: { info, params: { id, options } } });
    return Product.one(id, options)
      .then(response => dispatch({
        type: ONE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const UPDATE_START = 's/Product/UPDATE_START';
export const UPDATE = 's/Product/UPDATE';
export type UPDATE = api.ProductDto;

export function update(id: number, options?: Product.UpdateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: UPDATE_START, meta: { info, params: { id, options } } });
    return Product.update(id, options)
      .then(response => dispatch({
        type: UPDATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const DESTROY_START = 's/Product/DESTROY_START';
export const DESTROY = 's/Product/DESTROY';
export type DESTROY = undefined;

export function destroy(id: number, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: DESTROY_START, meta: { info, params: { id } } });
    return Product.destroy(id)
      .then(response => dispatch({
        type: DESTROY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_PRODUCT_BY_SKU_START = 's/Product/GET_PRODUCT_BY_SKU_START';
export const GET_PRODUCT_BY_SKU = 's/Product/GET_PRODUCT_BY_SKU';
export type GET_PRODUCT_BY_SKU = api.ProductDto;

export function getProductBySku(sku: string, options?: Product.GetProductBySkuOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_PRODUCT_BY_SKU_START, meta: { info, params: { sku, options } } });
    return Product.getProductBySku(sku, options)
      .then(response => dispatch({
        type: GET_PRODUCT_BY_SKU,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const ALL_START = 's/Product/ALL_START';
export const ALL = 's/Product/ALL';
export type ALL = api.Product[];

export function all(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: ALL_START, meta: { info, params: {  } } });
    return Product.all()
      .then(response => dispatch({
        type: ALL,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const CREATE_START = 's/Product/CREATE_START';
export const CREATE = 's/Product/CREATE';
export type CREATE = api.Product;

export function create(options?: Product.CreateOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: CREATE_START, meta: { info, params: { options } } });
    return Product.create(options)
      .then(response => dispatch({
        type: CREATE,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const SCHEMA_START = 's/Product/SCHEMA_START';
export const SCHEMA = 's/Product/SCHEMA';
export type SCHEMA = api.Product;

export function schema(info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: SCHEMA_START, meta: { info, params: {  } } });
    return Product.schema()
      .then(response => dispatch({
        type: SCHEMA,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}

export const GET_MANY_START = 's/Product/GET_MANY_START';
export const GET_MANY = 's/Product/GET_MANY';
export type GET_MANY = api.PaginatedProductsDto;

export function getMany(options?: Product.GetManyOptions, info?: any): api.AsyncAction {
  return dispatch => {
    dispatch({ type: GET_MANY_START, meta: { info, params: { options } } });
    return Product.getMany(options)
      .then(response => dispatch({
        type: GET_MANY,
        payload: response.data,
        error: response.error,
        meta: {
          res: response.raw,
          info
        }
      }));
  };
}
