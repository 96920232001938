import { Component, Input, HostListener } from '@angular/core';
import { RootReducer, Store } from '@app/app.reducers';
import { Dialog, open } from '@app/dialog/reducers/dialog.reducer';
import { ProductMediaItem } from '@app/product/reducers/product.reducer';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus';

const MODAL_OPTIONS = { scrollable: false, windowClass: 'modal-transparent' };

/** A component that displays an image that can be zoomed in. */
@Component({
	standalone: true,
	selector: 'g-zoomable-image',
	templateUrl: './zoomable-image.component.html',
	styleUrls: ['./zoomable-image.component.scss'],
})
export class ZoomableImageComponent {
	@Input() readonly images?: (string | ProductMediaItem | Record<string, string>)[];
	@Input() readonly key?: string; // Key to use for selecting image URL from an object
	@Input() readonly imageIndex = 0; // Index of image to show when modal is opened
	readonly icons = { faMagnifyingGlassPlus };
	constructor(private readonly store: Store<RootReducer.State>) {}

	@HostListener('click')
	showZoomedImage = (): void => {
		const { store, images, key, imageIndex } = this;
		store.dispatch(open(Dialog.IMAGE_VIEWER, { images, key, imageIndex }, MODAL_OPTIONS));
	};
}
